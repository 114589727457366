import { useMonitorProdutosAcabadosQuery } from '@/queries/useMonitorProdutosAcabadosQuery'
import { format, isBefore } from 'date-fns'
import { useEffect, useMemo, useRef, useState } from 'react'
import { TypographyMutedXs } from '@/components/ui/typography'
import CompanySelect from '../../components/CompanySelect'
import { Maximize, Minimize, RefreshCcw } from 'lucide-react'
import TopMessage from '@/components/TopMessage'
import PageContainer from '@/components/PageContainer'
import ProdutosAcabadosTable from './components/ProdutosAcabadosTable'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
import { isBetween } from '@/utils/date'
import ButtonWithTooltip from '@/components/ButtonWithTooltip'
import { AnimatePresence } from 'framer-motion'
import { useEmpresaQuery } from '@/queries/useEmpresaQuery'
import { MonitorProdutosAcabadosSelect } from '@/api/business/produtosAcabados/type'
import ProdutosSelect from './components/ProdutosSelect'
import LinhasSelect from './components/LinhasSelect'
import { useFullscreen } from '@/hooks/useFullscreen'
import { toast } from 'sonner'
import { useSequenciaEnsaiosQuery } from '@/queries/useSequenciaEnsaiosQuery'
import DateFilter, { OnSearchProps } from '@/components/DateFilter'

const stateSelector = (state: Store) => ({
    selectedCompany: state.monitorProdutosAcabadosSlice.state.selectedCompany,
    date: state.monitorProdutosAcabadosSlice.state.date,
    selectedSala: state.monitorProdutosAcabadosSlice.state.selectedSala,
    selectedLinha: state.monitorProdutosAcabadosSlice.state.selectedLinha,
    selectedProduto: state.monitorProdutosAcabadosSlice.state.selectedProduto,
    setSala: state.monitorProdutosAcabadosSlice.actions.setSala,
    setCompany: state.monitorProdutosAcabadosSlice.actions.setCompany,
    setProduto: state.monitorProdutosAcabadosSlice.actions.setProduto,
    setLinha: state.monitorProdutosAcabadosSlice.actions.setLinha,
    setDateInterval: state.monitorProdutosAcabadosSlice.actions.setDateInterval,
    resetDate: state.monitorProdutosAcabadosSlice.actions.resetDate,
})

const MonitorProdutoAcabado = () => {
    const mainContainerRef = useRef<HTMLDivElement | null>(null)
    const [filteredData, setFilteredData] = useState<
        MonitorProdutosAcabadosSelect[]
    >([])
    const [filteredProdutos, setFilteredProdutos] = useState<
        Pick<MonitorProdutosAcabadosSelect, 'produto' | 'centro_de_custo'>[]
    >([])

    const {
        selectedCompany,
        date: { initial, final },
        selectedLinha,
        selectedProduto,
        setLinha,
        setProduto,
        setDateInterval,
        setCompany,
    } = useBaseStore(stateSelector)

    const { data: empresas = [], isSuccess: isEmpresasSuccess } =
        useEmpresaQuery()

    const { data: order = {}, isSuccess: isOrderSuccess } =
        useSequenciaEnsaiosQuery({
            cod_filial: Number(selectedCompany),
            monitor: 'PRODUTO ACABADO',
            enabled: !!selectedCompany,
        })

    const { data, isPending, isFetching, isError, dataUpdatedAt, refetch } =
        useMonitorProdutosAcabadosQuery({
            cod_filial: selectedCompany!,
            data_inicial: Number(format(new Date(initial), 'yyyyMMdd')),
            data_final: Number(format(new Date(final), 'yyyyMMdd')),
            enabled: empresas.length > 0 && isEmpresasSuccess && isOrderSuccess,
        })

    const filterData = (
        linha: string,
        produto: string,
        data: MonitorProdutosAcabadosSelect[]
    ) => {
        return data.filter(
            (value) =>
                value.centro_de_custo === linha && value.produto === produto
        )
    }

    const linhas = useMemo(() => {
        if (!data) return []

        const obj: Record<
            string,
            Pick<MonitorProdutosAcabadosSelect, 'centro_de_custo'>
        > = {}

        for (const row of data) {
            if (!obj[row.centro_de_custo]) {
                obj[row.centro_de_custo] = {
                    centro_de_custo: row.centro_de_custo,
                }
            }
        }

        return Object.values(obj).sort((a, b) =>
            a.centro_de_custo.localeCompare(b.centro_de_custo)
        )
    }, [data])

    const produtos = useMemo(() => {
        if (!data) return []

        const obj: Record<
            string,
            Pick<MonitorProdutosAcabadosSelect, 'produto' | 'centro_de_custo'>
        > = {}

        for (const row of data) {
            if (!obj[row.produto]) {
                obj[row.produto] = {
                    produto: row.produto,
                    centro_de_custo: row.centro_de_custo,
                }
            }
        }

        return Object.values(obj).sort((a, b) =>
            a.produto.localeCompare(b.produto)
        )
    }, [data])

    const onProdutoChange = (value: string) => {
        if (data) {
            if (selectedLinha)
                setFilteredData(filterData(selectedLinha, value, data))

            setProduto(value)
        }
    }

    const onLinhaChange = (value: string) => {
        setLinha(value)
        if (data) {
            const prods = produtos.filter(
                (prod) => prod.centro_de_custo === value
            )
            setFilteredProdutos(prods)

            if (selectedProduto)
                setFilteredData(filterData(value, selectedProduto, data))
        }
    }

    const { isFullscreen, onToggleFullScreen } = useFullscreen({
        element: mainContainerRef.current,
    })

    const onSearch = ({ from, to }: OnSearchProps) => {
        if (isBefore(to, from)) {
            return toast.error(
                'A data final não pode ser anterior à data inicial'
            )
        }

        if (!isBetween(new Date(to), new Date(from), 60)) {
            return toast.warning(
                'Intervalo de datas deve ser de no máximo 60 dias'
            )
        }

        setDateInterval({
            initial: from.toDateString(),
            final: to.toDateString(),
        })
    }

    useEffect(() => {
        if (!selectedLinha)
            if (linhas[0]) {
                onLinhaChange(linhas[0].centro_de_custo)
            }
    }, [data])

    useEffect(() => {
        if (selectedLinha) onLinhaChange(selectedLinha)
    }, [data])

    return (
        <PageContainer ref={mainContainerRef} className="p-4">
            <div className="flex justify-between pb-2">
                <div className="flex items-center gap-4">
                    <CompanySelect
                        companies={empresas}
                        value={selectedCompany}
                        onChange={setCompany}
                    />
                    <LinhasSelect
                        linhas={linhas.map((l) => ({
                            linha: l.centro_de_custo,
                        }))}
                        value={selectedLinha}
                        onChange={onLinhaChange}
                    />
                    <ProdutosSelect
                        produtos={filteredProdutos}
                        value={selectedProduto}
                        onChange={onProdutoChange}
                    />
                </div>
                <div className="flex items-center gap-2">
                    <DateFilter
                        final={final}
                        initial={initial}
                        onSearch={onSearch}
                    />
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={() => refetch()}
                        tooltipContent={'Atualizar dados'}
                    >
                        <RefreshCcw size={14} />
                    </ButtonWithTooltip>
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={onToggleFullScreen}
                        tooltipContent={
                            isFullscreen ? 'Minimizar' : 'Maximizar'
                        }
                    >
                        {isFullscreen ? (
                            <Minimize size={14} />
                        ) : (
                            <Maximize size={14} />
                        )}
                    </ButtonWithTooltip>
                </div>
            </div>
            <div className="ml-auto">
                {dataUpdatedAt !== 0 && (
                    <TypographyMutedXs>
                        Última atualização:{' '}
                        {format(
                            new Date(dataUpdatedAt),
                            'dd/MM/yyyy, HH:mm:ss'
                        )}
                    </TypographyMutedXs>
                )}
            </div>
            <div className="flex-1 py-2 overflow-hidden">
                <AnimatePresence>
                    {!data && isPending ? (
                        <TopMessage
                            variant="loading"
                            text="Carregando dados..."
                        />
                    ) : null}
                    {!isPending && isFetching ? (
                        <TopMessage
                            variant="success"
                            text="Atualizando dados..."
                        />
                    ) : null}
                    {isError && (
                        <TopMessage
                            variant="error"
                            text="Erro ao buscar dados"
                        />
                    )}
                </AnimatePresence>
                <ProdutosAcabadosTable data={filteredData} order={order} />
            </div>
        </PageContainer>
    )
}

export default MonitorProdutoAcabado
