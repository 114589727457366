import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { ColumnPinningState, createColumnHelper } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import {
    MonitorProdutosAcabadosSelect,
    Resultado,
} from '@/api/business/produtosAcabados/type'
import CellPopover from './components/CellPopover'
import { TypographyP, TypographyXS } from '@/components/ui/typography'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@/consts/routes'
import { DropdownMenuItem } from '@/components/ui/dropdown-menu'
import { Waypoints } from 'lucide-react'
import { sort } from '@/utils/sort'

type ProdutosAcabadosTableProps = {
    data?: MonitorProdutosAcabadosSelect[]
    order: Record<number, number>
}

const ProdutosAcabadosTable = ({
    data = [],
    order,
}: ProdutosAcabadosTableProps) => {
    const navigate = useNavigate()

    const [pinningState, setPinningState] = useState<ColumnPinningState>({
        left: ['ensaio'],
    })

    const tableData = useMemo(() => {
        const ensaios: Record<string, Resultado> = {}

        for (const row of data) {
            for (const result of row.resultados) {
                if (!ensaios[result.ensaio]) {
                    ensaios[result.ensaio] = result
                }
            }
        }

        const newData: (Resultado & {
            data: MonitorProdutosAcabadosSelect[]
        })[] = []

        for (const ensaio of Object.values(ensaios)) {
            newData.push({
                ...ensaio,
                data: data.filter((value) =>
                    value.resultados.some(
                        (result) => result.cod_ensaio === ensaio.cod_ensaio
                    )
                ),
            })
        }

        return sort(newData, 'cod_ensaio', order)
    }, [data])

    const tableColumnHeaders = useMemo(() => {
        const lotes: Record<string, MonitorProdutosAcabadosSelect> = {}

        const sorted = data.sort((a, b) =>
            a.horaminuto < b.horaminuto ? 1 : -1
        )

        for (const row of sorted) {
            if (!lotes[row.lote_de_controle_de_qualidade])
                lotes[row.lote_de_controle_de_qualidade] = row
        }

        return Object.values(lotes)
    }, [data])

    type ProdutosAcabadosTableData = TableData<(typeof tableData)[0]>

    const columnHelper = createColumnHelper<ProdutosAcabadosTableData>()

    const columns = useMemo(
        () => [
            columnHelper.accessor('ensaio', {
                id: 'ensaio',
                header: 'Ensaio',
                size: 200,
                enableHiding: false,
            }),
            ...tableColumnHeaders.map((header) => {
                const lotes = [...new Set(header.lotes_envasamento)]

                return columnHelper.display({
                    id: header.lote_de_controle_de_qualidade,
                    header: () => {
                        return (
                            <div className="flex flex-col gap-2 overflow-hidden">
                                <TypographyXS className="overflow-hidden text-ellipsis">
                                    {header.produto}
                                </TypographyXS>
                                <TypographyXS className="overflow-hidden text-ellipsis">
                                    {header.centro_de_custo}
                                </TypographyXS>
                                <TypographyXS className="overflow-hidden text-ellipsis">
                                    {lotes}
                                </TypographyXS>
                                <TypographyXS className="overflow-hidden text-ellipsis">
                                    {header.origem}
                                </TypographyXS>
                            </div>
                        )
                    },
                    cell: ({ row }) => {
                        const { cod_ensaio, data } = row.original

                        const lote = data.find(
                            (value) =>
                                value.lote_de_controle_de_qualidade ===
                                header.lote_de_controle_de_qualidade
                        )

                        if (!lote) return null

                        const values = lote.resultados.filter(
                            (value) => value.cod_ensaio === cod_ensaio
                        )

                        return values.map((value) => (
                            <CellPopover
                                data={value}
                                lote={header.lote_de_controle_de_qualidade}
                            />
                        ))
                    },
                    size: 300,
                    meta: {
                        header: {
                            className: 'p-2',
                            menu: {
                                renderMenuItem: () => {
                                    const linha =
                                        header.centro_de_custo
                                            .split('-')[0]
                                            .trim()
                                            .split('') || ''

                                    const abreviatura =
                                        linha[0] + linha[linha.length - 1]

                                    return (
                                        <DropdownMenuItem
                                            className="gap-2 focus:cursor-pointer group"
                                            onClick={() =>
                                                navigate(
                                                    ROUTES.RASTREABILIDADE
                                                        .index +
                                                        '/?lote=' +
                                                        `${lotes}` +
                                                        '&empresa=' +
                                                        `${header.cod_filial}` +
                                                        '&horaminuto=' +
                                                        `${header.horaminuto}` +
                                                        '&linha=' +
                                                        `${abreviatura}`,
                                                    {
                                                        state: {
                                                            from: ROUTES
                                                                .PRODUTO_ACABADO
                                                                .index,
                                                        },
                                                    }
                                                )
                                            }
                                        >
                                            <Waypoints
                                                size={14}
                                                className="text-muted-foreground group-hover:text-primary-600"
                                            />
                                            <TypographyP>
                                                Rastreabilidade
                                            </TypographyP>
                                        </DropdownMenuItem>
                                    )
                                },
                            },
                        },
                        cell: {
                            className: 'p-0',
                        },
                    },
                    enableHiding: false,
                })
            }),
        ],
        [tableColumnHeaders]
    )

    return (
        <Table<ProdutosAcabadosTableData>
            data={tableData}
            columns={columns}
            tableState={{
                columnPinning: pinningState,
            }}
            onColumnPinningStateChange={setPinningState}
        />
    )
}

export default ProdutosAcabadosTable
