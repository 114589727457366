import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { ColumnPinningState, createColumnHelper } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { MonitorFiltracao, Resultado } from '@/api/business/filtracao/type'
import CellPopover from './components/CellPopover'
import { TypographyP, TypographyXS } from '@/components/ui/typography'
import { format, isBefore } from 'date-fns'
import { getDate, getDateFromId } from '@/utils/date'
import { sort } from '@/utils/sort'

type FiltracaoTableProps = {
    data?: MonitorFiltracao[]
    order: Record<number, number>
}

const FiltracaoTable = ({ data = [], order }: FiltracaoTableProps) => {
    const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
        left: ['ensaio'],
    })

    const tableData = useMemo(() => {
        const ensaios: Record<
            string,
            { resultados: MonitorFiltracao[] } & Resultado
        > = {}

        for (const row of data) {
            for (const agrupador of row.resultados) {
                for (const resultado of agrupador.resultados) {
                    if (!ensaios[resultado.cod_ensaio]) {
                        ensaios[resultado.cod_ensaio] = {
                            ...resultado,
                            resultados: [row],
                        }
                    }

                    ensaios[resultado.cod_ensaio].resultados.push(row)
                }
            }
        }

        return sort(Object.values(ensaios), 'cod_ensaio', order)
    }, [data])

    type FiltracaoTableData = TableData<(typeof tableData)[0]>

    const columnHelper = createColumnHelper<FiltracaoTableData>()

    const columns = useMemo(
        () => [
            columnHelper.accessor('ensaio', {
                id: 'ensaio',
                header: 'Ensaio',
                size: 200,
                enableHiding: false,
            }),
            ...data
                .sort((a, b) => {
                    const primeiraAmostraA = a.resultados[0]?.resultados[0]
                    const primeiraAmostraB = b.resultados[0]?.resultados[0]

                    if (primeiraAmostraA && primeiraAmostraB) {
                        const dateA = getDate({
                            date: primeiraAmostraA.data_da_coleta,
                            hour: primeiraAmostraA.hora_da_coleta,
                        })
                        const dateB = getDate({
                            date: primeiraAmostraB.data_da_coleta,
                            hour: primeiraAmostraB.hora_da_coleta,
                        })

                        if (isBefore(dateA, dateB)) {
                            return -1
                        } else if (isBefore(dateB, dateA)) {
                            return 1
                        }
                    }

                    return 0
                })
                .map((header) => {
                    return columnHelper.group({
                        id: header.cod_lote_de_controle_de_qualidade.toString(),
                        header: () => {
                            return (
                                <div className="flex flex-col gap-2 overflow-hidden">
                                    <TypographyXS className="overflow-hidden text-ellipsis">
                                        {header.cenario}
                                    </TypographyXS>
                                    <TypographyXS className="overflow-hidden text-ellipsis">
                                        {header.produto}
                                    </TypographyXS>
                                </div>
                            )
                        },
                        columns: header.resultados.flatMap((agrupador, idx) => {
                            return columnHelper.display({
                                id: `${header.lote_de_controle_de_qualidade}${header.cod_lote_de_controle_de_qualidade}${agrupador.key}`,
                                header: () => {
                                    const label = agrupador.key.includes(
                                        'PRÉVIA'
                                    )
                                        ? `PRÉVIA ${(idx + 1)
                                              .toString()
                                              .padStart(2, '0')}`
                                        : 'LIBERAÇÃO'

                                    const primeiraAmostra =
                                        agrupador.resultados[0]

                                    return (
                                        <>
                                            <TypographyP className="text-xs">
                                                {label}
                                            </TypographyP>
                                            <TypographyP className="text-xs font-normal">
                                                {format(
                                                    getDateFromId(header.data),
                                                    'dd/MM/yyyy'
                                                )}
                                            </TypographyP>
                                            {primeiraAmostra && (
                                                <TypographyP className="text-xs font-normal">
                                                    {
                                                        primeiraAmostra.hora_da_coleta
                                                    }
                                                </TypographyP>
                                            )}
                                        </>
                                    )
                                },
                                cell: ({ row }) => {
                                    const { cod_ensaio } = row.original

                                    const found = agrupador.resultados.filter(
                                        (value) =>
                                            value.cod_ensaio === cod_ensaio
                                    )

                                    return found.map((result) => {
                                        const {
                                            cod_amostra_reanalise,
                                            cod_amostra,
                                            cod_ensaio,
                                            chaveagrupadora,
                                        } = result

                                        const id = `${
                                            cod_amostra_reanalise ?? ''
                                        }${cod_amostra}${cod_ensaio}${chaveagrupadora}`

                                        return (
                                            <CellPopover
                                                key={id}
                                                data={result}
                                            />
                                        )
                                    })
                                },
                                size: 120,
                                meta: {
                                    header: {
                                        className: 'p-2',
                                    },
                                    cell: {
                                        className: 'p-0',
                                    },
                                },
                                enableHiding: false,
                            })
                        }),
                    })
                }),
        ],
        [data]
    )

    return (
        <Table<FiltracaoTableData>
            data={tableData}
            columns={columns}
            tableState={{
                columnPinning: columnPinning,
            }}
            onColumnPinningStateChange={setColumnPinning}
        />
    )
}

export default FiltracaoTable
