import { ImmerStateCreator } from '../type'
import {
    MonitorFermentacaoDiarioSlice,
    MonitorFermentacaoDiarioState,
} from './type'
import { getStorageState, persistState } from '@/utils/storage'

const initialState: MonitorFermentacaoDiarioState = {
    selectedCompany: undefined,
    selectedLote: undefined,
    selectedProduto: undefined,
    selectedSala: { bem: undefined },
    date: {
        initial: new Date().toISOString(),
    },
}

const key = 'monitor-fermentacao-diario'

const persist = (state: MonitorFermentacaoDiarioState) => {
    persistState({
        state,
        key,
        omit: ['selectedLote'],
    })
}

export const useMonitorFermentacaoDiarioSlice: ImmerStateCreator<
    MonitorFermentacaoDiarioSlice
> = (set) => ({
    state: getStorageState({
        key,
        state: initialState,
    }),
    actions: {
        setCompany: (company) => {
            set((state) => {
                state.monitorFermentacaoDiarioSlice.state.selectedCompany =
                    company

                persist(state.monitorFermentacaoDiarioSlice.state)
            })
        },
        setDateInterval: ({ initial }) => {
            set((state) => {
                state.monitorFermentacaoDiarioSlice.state.date.initial = initial

                persist(state.monitorFermentacaoDiarioSlice.state)
            })
        },
        setSala: (sala) => {
            set((state) => {
                state.monitorFermentacaoDiarioSlice.state.selectedSala = {
                    bem: sala?.bem,
                }

                persist(state.monitorFermentacaoDiarioSlice.state)
            })
        },
        setLote: (lote) => {
            set((state) => {
                state.monitorFermentacaoDiarioSlice.state.selectedLote = lote
            })
        },
        setProduto: (produto) => {
            set((state) => {
                state.monitorFermentacaoDiarioSlice.state.selectedProduto =
                    produto
            })
        },
        resetDate: () => {
            set((state) => {
                state.monitorFermentacaoDiarioSlice.state.date = {
                    initial: new Date().toString(),
                }

                persist(state.monitorFermentacaoDiarioSlice.state)
            })
        },
        onReset: () => {
            set((state) => {
                state.monitorFermentacaoDiarioSlice.state = initialState
            })
        },
    },
})
