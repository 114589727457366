import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { ColumnPinningState, createColumnHelper } from '@tanstack/react-table'
import { useCallback, useMemo, useState } from 'react'
import {
    MonitorCipEnvasamento,
    Resultado,
} from '@/api/business/cipEnvasamento/type'
import CellPopover from './components/CellPopover'
import { format, isValid } from 'date-fns'
import FacetedFilter from '@/components/FacetedFilter'
import { TypographyMutedXs } from '@/components/ui/typography'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
import { getDate } from '@/utils/date'

type CipEnvasamentoTableProps = {
    data?: MonitorCipEnvasamento[]
    dataUpdatedAt: number
}

type CipEnvasamentoTableData = TableData<MonitorCipEnvasamento>

type Laudo = Resultado['laudo']

const laudos = ['CONFORME', 'NÃO CONFORME', 'NÃO AVALIADO'] satisfies Laudo[]

const stateSelector = (state: Store) => ({
    selectedLaudos: state.monitorCipEnvasamentoSlice.state.selectedLaudos,
    selectedLinhas: state.monitorCipEnvasamentoSlice.state.selectedLinhas,
    onSelectLaudo: state.monitorCipEnvasamentoSlice.actions.onSelectLaudo,
    onSelectLinha: state.monitorCipEnvasamentoSlice.actions.onSelectLinha,
    onClearLinha: state.monitorCipEnvasamentoSlice.actions.onClearLinha,
    onClearLaudo: state.monitorCipEnvasamentoSlice.actions.onClearLaudo,
})

const headerOrder = {
    'ASSEPSIA EXTERNA': 1,
    CIP: 2,
    'ARMAZENAMENTO DE PEÇAS': 3,
}

const subheadersOrder = {
    'CONCENTRAÇÃO DE SODA CÁUSTICA': 1,
    'CONCENTRAÇÃO DE DETERGENTE ÁCIDO': 2,
    'CONCENTRAÇÃO DE DETERGENTE ALCALINO CLORADO': 3,
    'CONCENTRAÇÃO DE DETERGENTE ALCALINO': 4,
    'CONCENTRAÇÃO DE ÁCIDO PERACÉTICO': 5,
    'CONCENTRAÇÃO DE QUATERNÁRIO DE AMÔNIO': 6,
}

const CipEnvasamentoTable = ({
    data = [],
    dataUpdatedAt,
}: CipEnvasamentoTableProps) => {
    const [pinningState, setPinningState] = useState<ColumnPinningState>({
        left: ['ensaio'],
    })

    const { selectedLaudos, selectedLinhas, onClearLaudo, onSelectLaudo } =
        useBaseStore(stateSelector)

    const onFilterData = useCallback(
        (
            data: MonitorCipEnvasamento[],
            linhas: Record<string, boolean>,
            laudos: Record<string, boolean>
        ) => {
            const hasCentroDeCusto = Object.entries(linhas).some(
                ([, value]) => value
            )
            const hasLaudo = Object.entries(laudos).some(([, value]) => value)

            return data.filter((value) => {
                const { resultados } = value

                return resultados.some(
                    (result) =>
                        (!hasCentroDeCusto || linhas[value.centro_de_custo]) &&
                        (!hasLaudo || laudos[result.laudo])
                )
            })
        },
        []
    )

    const tableData = useMemo(() => {
        return onFilterData(data, selectedLinhas, selectedLaudos)
    }, [data, selectedLinhas, selectedLaudos])

    const tableColumnHeaders = useMemo(() => {
        const ensaios: Record<
            string,
            {
                header: string
                subheaders: Record<string, { subheader: string }>
            }
        > = {}

        const hasLaudo = Object.entries(selectedLaudos).some(
            ([, value]) => value
        )

        for (const row of data) {
            if (!ensaios[row.operacao])
                ensaios[row.operacao] = {
                    header: row.operacao,
                    subheaders: {},
                }

            for (const result of row.resultados.filter(
                (result) => selectedLaudos[result.laudo] || !hasLaudo
            )) {
                if (!ensaios[row.operacao].subheaders[result.cod_ensaio])
                    ensaios[row.operacao].subheaders[result.cod_ensaio] = {
                        subheader: result.ensaio,
                    }
            }
        }

        return Object.values(ensaios)
            .map((ensaio) => ({
                ...ensaio,
                subheaders: Object.values(ensaio.subheaders).sort((a, b) =>
                    subheadersOrder[a.subheader] < subheadersOrder[b.subheader]
                        ? 1
                        : -1
                ),
            }))
            .sort((a, b) =>
                headerOrder[a.header] < headerOrder[b.header] ? 1 : -1
            )
    }, [data, selectedLaudos])

    const columnHelper = createColumnHelper<CipEnvasamentoTableData>()

    const columns = useMemo(
        () => [
            columnHelper.accessor(
                ({ data_da_coleta, hora_da_coleta }) => {
                    const date = getDate({
                        date: data_da_coleta,
                        hour: hora_da_coleta + ':00',
                    })

                    if (!isValid(date)) return null

                    return format(date, 'dd/MM/yyyy, hh:mm')
                },
                {
                    id: 'data_da_coleta',
                    header: 'Data e hora',
                    size: 140,
                    enableHiding: false,
                }
            ),
            columnHelper.accessor('centro_de_custo', {
                id: 'centro_de_custo',
                header: 'Centro de custo',
                size: 200,
                enableHiding: false,
            }),
            columnHelper.accessor('produto', {
                id: 'produto',
                header: 'Produto',
                size: 250,
                enableHiding: false,
            }),
            columnHelper.accessor('bem', {
                id: 'bem',
                header: 'Bem',
                size: 300,
                enableHiding: false,
            }),
            ...tableColumnHeaders.map((header) => {
                return columnHelper.group({
                    id: header.header,
                    header: header.header,
                    columns: header.subheaders.map((subheader) => {
                        return {
                            id: header.header + subheader.subheader,
                            header: subheader.subheader,
                            cell: ({ row }) => {
                                const { cod_amostra } = row.original

                                const all = data.filter(
                                    (value) =>
                                        value.cod_amostra === cod_amostra &&
                                        value.operacao === header.header
                                )

                                const values = all
                                    .flatMap((val) => val.resultados)
                                    .filter(
                                        (result) =>
                                            result.ensaio ===
                                            subheader.subheader
                                    )

                                return values.map((result) => (
                                    <CellPopover
                                        key={result.cod_amostra}
                                        data={result}
                                    />
                                ))
                            },
                            size: 310,
                            meta: {
                                cell: {
                                    className: 'p-0',
                                },
                            },
                            enableHiding: false,
                        }
                    }),
                })
            }),
        ],
        [tableColumnHeaders]
    )

    // useEffect(() => {
    //     onSelectLinha(selectedLinhas.filter((linha) => linhas.includes(linha)))
    // }, [linhas])

    return (
        <>
            <div className="flex items-center justify-between py-4">
                <div className="space-x-2">
                    <FacetedFilter
                        title="Laudo"
                        selectedValues={selectedLaudos}
                        options={laudos.map((laudo) => ({
                            label: laudo,
                            value: laudo,
                        }))}
                        onSelectionChange={onSelectLaudo}
                        onClearFilters={onClearLaudo}
                    />
                </div>
                <div className="ml-auto">
                    {dataUpdatedAt !== 0 && (
                        <TypographyMutedXs>
                            Última atualização:{' '}
                            {format(
                                new Date(dataUpdatedAt),
                                'dd/MM/yyyy, HH:mm:ss'
                            )}
                        </TypographyMutedXs>
                    )}
                </div>
            </div>
            <Table<CipEnvasamentoTableData>
                data={tableData}
                columns={columns}
                tableState={{
                    columnPinning: pinningState,
                }}
                onColumnPinningStateChange={setPinningState}
            />
        </>
    )
}

export default CipEnvasamentoTable
