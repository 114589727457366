import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { TypographyXS } from '@/components/ui/typography'

type LinhasSelectProps = {
    linhas: { linha: string }[]
    value?: string
    onChange: (value: string) => void
}

const LinhasSelect = ({ linhas, value, onChange }: LinhasSelectProps) => {
    return (
        <Select onValueChange={onChange} value={value}>
            <SelectTrigger className="hover:bg-muted h-[56px] min-w-[200px]">
                <SelectValue placeholder="Selecione uma linha" />
            </SelectTrigger>
            <SelectContent>
                {linhas?.length === 0 ? (
                    <SelectGroup>
                        <SelectItem value="sem_dados" disabled>
                            <TypographyXS className="text-start">
                                Não há linha disponível
                            </TypographyXS>
                        </SelectItem>
                    </SelectGroup>
                ) : (
                    <SelectGroup>
                        {linhas.map((linha) => {
                            return (
                                <SelectItem
                                    key={linha.linha}
                                    value={linha.linha}
                                >
                                    {linha.linha}
                                </SelectItem>
                            )
                        })}
                    </SelectGroup>
                )}
            </SelectContent>
        </Select>
    )
}

export default LinhasSelect
