import { ImmerStateCreator } from '../type'
import { MonitorProvaHorariaSlice, MonitorProvaHorariaState } from './type'
import { getStorageState, persistState } from '@/utils/storage'

const initialState: MonitorProvaHorariaState = {
    selectedCompany: undefined,
    selectedSala: undefined,
    date: {
        initial: new Date().toISOString(),
        final: new Date().toISOString(),
    },
}

const key = 'monitor-prova-horaria'

const persist = (state: MonitorProvaHorariaState) => {
    persistState({ key, state })
}

export const useMonitorProvaHorariaSlice: ImmerStateCreator<
    MonitorProvaHorariaSlice
> = (set) => ({
    state: getStorageState({
        key,
        state: initialState,
    }),
    actions: {
        setCompany: (company) => {
            set((state) => {
                state.monitorProvaHorariaSlice.state.selectedCompany = company

                persist(state.monitorProvaHorariaSlice.state)
            })
        },
        setDateInterval: ({ final, initial }) => {
            set((state) => {
                state.monitorProvaHorariaSlice.state.date.initial = initial
                state.monitorProvaHorariaSlice.state.date.final = final

                persist(state.monitorProvaHorariaSlice.state)
            })
        },
        setSala: (sala, initial) => {
            set((state) => {
                const prev = state.monitorProvaHorariaSlice.state.selectedSala

                if ((!initial && prev) || (initial && !prev)) {
                    state.monitorProvaHorariaSlice.state.selectedSala = sala
                }

                persist(state.monitorProvaHorariaSlice.state)
            })
        },
        resetDate: () => {
            set((state) => {
                state.monitorProvaHorariaSlice.state.date = {
                    final: new Date().toString(),
                    initial: new Date().toString(),
                }

                persist(state.monitorProvaHorariaSlice.state)
            })
        },
        onReset: () => {
            set((state) => {
                state.monitorProvaHorariaSlice.state = initialState
            })
        },
    },
})
