// import ExpiredToken from '@/components/ExpiredToken'
import Header from '@/components/Header'
import ChangePasswordDialog from '@/components/ChangePasswordDialog'
// import { useBaseStore } from '@/store'
import { Outlet } from 'react-router-dom'
import { ReactNode } from 'react'

// import { Store } from '@/store/type'
// import AsidePortalContainer from '@/components/AsidePortalContainer'
// import { AsideContextProvider } from '@/context/AsideContext'
// import { ResizablePanel, ResizablePanelGroup } from '@/components/ui/resizable'
interface MainLayoutProps {
    children?: ReactNode
}

// const stateSelector = (state: Store) => ({
//     isSidenavOpen: state.appConfigSlice.state.isSidenavOpen,
//     tokenExpired: state.authSlice.state.tokenExpired,
// })

const MainLayout = ({ children }: MainLayoutProps) => {
    // const { tokenExpired } = useBaseStore(stateSelector)

    return (
        <>
            <div className="flex flex-col h-screen overflow-hidden bg-background">
                <Header />
                {children || <Outlet />}
            </div>
            <ChangePasswordDialog />
        </>
    )
}

export default MainLayout
