import { ImmerStateCreator } from '../type'
import { MonitorFermentacaoSlice, MonitorFermentacaoState } from './type'
import { getStorageState, persistState } from '@/utils/storage'

const initialState: MonitorFermentacaoState = {
    selectedCompany: undefined,
    selectedLote: undefined,
    selectedProduto: undefined,
    selectedSala: undefined,
    date: {
        initial: new Date().toISOString(),
        final: new Date().toISOString(),
    },
}

const persist = (state: MonitorFermentacaoState) => {
    persistState({
        key: 'monitor-fermentacao',
        state,
        omit: ['selectedLote'],
    })
}

export const useMonitorFermentacaoSlice: ImmerStateCreator<
    MonitorFermentacaoSlice
> = (set) => ({
    state: getStorageState({
        key: 'monitor-fermentacao',
        state: initialState,
    }),
    actions: {
        setCompany: (company) => {
            set((state) => {
                state.monitorFermentacaoSlice.state.selectedCompany = company

                persist(state.monitorFermentacaoSlice.state)
            })
        },
        setDateInterval: ({ final, initial }) => {
            set((state) => {
                state.monitorFermentacaoSlice.state.date.initial = initial
                state.monitorFermentacaoSlice.state.date.final = final

                persist(state.monitorFermentacaoSlice.state)
            })
        },
        setSala: (sala) => {
            set((state) => {
                state.monitorFermentacaoSlice.state.selectedSala = sala
                state.monitorFermentacaoSlice.state.selectedLote = undefined

                persist(state.monitorFermentacaoSlice.state)
            })
        },
        setLote: (lote) => {
            set((state) => {
                state.monitorFermentacaoSlice.state.selectedLote = lote
            })
        },
        setProduto: (produto) => {
            set((state) => {
                state.monitorFermentacaoSlice.state.selectedProduto = produto
                state.monitorFermentacaoSlice.state.selectedLote = undefined
                state.monitorFermentacaoSlice.state.selectedSala = undefined
            })
        },
        resetDate: () => {
            set((state) => {
                state.monitorFermentacaoSlice.state.date = {
                    final: new Date().toString(),
                    initial: new Date().toString(),
                }

                persist(state.monitorFermentacaoSlice.state)
            })
        },
        onReset: () => {
            set((state) => {
                state.monitorFermentacaoSlice.state = initialState
            })
        },
    },
})
