import { useMonitorFermentacaoQuery } from '@/queries/useMonitorFermentacaoQuery'
import { format, isBefore } from 'date-fns'
import { useMemo, useRef } from 'react'
import { TypographyMutedXs, TypographyP } from '@/components/ui/typography'
import CompanySelect from '../../components/CompanySelect'
import { Maximize, Minimize, RefreshCcw } from 'lucide-react'
import TopMessage from '@/components/TopMessage'
import PageContainer from '@/components/PageContainer'
import FermentacaoTable from './components/FermentacaoTable'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
import ButtonWithTooltip from '@/components/ButtonWithTooltip'
import LinhasSelect from './components/LinhasSelect'
import { AnimatePresence } from 'framer-motion'
import LotesSelect from './components/LotesSelect'
import AcompanhamentoTable from './components/AcompanhamentoTable'
import { useEmpresaQuery } from '@/queries/useEmpresaQuery'
import { useFullscreen } from '@/hooks/useFullscreen'
import { isBetween } from '@/utils/date'
import { toast } from 'sonner'
import { useSequenciaEnsaiosQuery } from '@/queries/useSequenciaEnsaiosQuery'
import DateFilter, { OnSearchProps } from '@/components/DateFilter'
import { MonitorFermentacao as MonitorFermentacaoType } from '@/api/business/fermentacao/type'
import ProdutosSelect from './components/ProdutosSelect'

const stateSelector = (state: Store) => ({
    selectedCompany: state.monitorFermentacaoSlice.state.selectedCompany,
    date: state.monitorFermentacaoSlice.state.date,
    selectedSala: state.monitorFermentacaoSlice.state.selectedSala,
    selectedLote: state.monitorFermentacaoSlice.state.selectedLote,
    selectedProduto: state.monitorFermentacaoSlice.state.selectedProduto,
    setLote: state.monitorFermentacaoSlice.actions.setLote,
    setSala: state.monitorFermentacaoSlice.actions.setSala,
    setProduto: state.monitorFermentacaoSlice.actions.setProduto,
    setCompany: state.monitorFermentacaoSlice.actions.setCompany,
    setDateInterval: state.monitorFermentacaoSlice.actions.setDateInterval,
    resetDate: state.monitorFermentacaoSlice.actions.resetDate,
})

const MonitorFermentacao = () => {
    const mainContainerRef = useRef<HTMLDivElement | null>(null)

    const {
        selectedCompany,
        date: { initial, final },
        selectedSala,
        selectedLote,
        selectedProduto,
        setProduto,
        setLote,
        setDateInterval,
        setSala,
        setCompany,
    } = useBaseStore(stateSelector)

    const { data: order = {}, isSuccess: isOrderSuccess } =
        useSequenciaEnsaiosQuery({
            cod_filial: Number(selectedCompany),
            monitor: 'FERMENTAÇÃO',
            enabled: !!selectedCompany,
        })

    const { data: empresas = [], isSuccess: isEmpresasSuccess } =
        useEmpresaQuery()

    const {
        data = [],
        isPending,
        isFetching,
        isError,
        dataUpdatedAt,
        refetch,
    } = useMonitorFermentacaoQuery({
        cod_filial: selectedCompany!,
        data_inicial: Number(format(new Date(initial), 'yyyyMMdd')),
        data_final: Number(format(new Date(final), 'yyyyMMdd')),
        fermentador: [],
        enabled: isEmpresasSuccess && isOrderSuccess,
    })

    const tanques = useMemo(() => {
        const tanques: Record<string, MonitorFermentacaoType> = {}

        for (const row of data.filter(
            (val) => val.produto === selectedProduto
        )) {
            if (!tanques[row.bem_abreviatura])
                tanques[row.bem_abreviatura] = row
        }

        return Object.values(tanques).sort((a, b) =>
            a.bem_abreviatura.localeCompare(b.bem_abreviatura)
        )
    }, [data, selectedProduto])

    const lotes = useMemo(() => {
        const lotes: Record<string, MonitorFermentacaoType> = {}

        for (const row of data.filter(
            (val) => val.bem_abreviatura === selectedSala
        )) {
            if (!lotes[row.lote_de_controle_de_qualidade])
                lotes[row.lote_de_controle_de_qualidade] = row
        }

        return Object.values(lotes)
    }, [data, selectedSala])

    const produtos = useMemo(() => {
        const produtos: Record<string, MonitorFermentacaoType> = {}

        for (const row of data) {
            if (!produtos[row.produto]) produtos[row.produto] = row
        }

        return Object.values(produtos)
    }, [data])

    const tableData = useMemo(
        () =>
            data.filter(
                (value) => value.lote_de_controle_de_qualidade === selectedLote
            ),
        [selectedLote]
    )

    const { isFullscreen, onToggleFullScreen } = useFullscreen({
        element: mainContainerRef.current,
    })

    const onSearch = ({ from, to }: OnSearchProps) => {
        if (isBefore(to, from)) {
            return toast.error(
                'A data final não pode ser anterior à data inicial'
            )
        }

        if (!isBetween(new Date(to), new Date(from), 60)) {
            return toast.warning(
                'Intervalo de datas deve ser de no máximo 60 dias'
            )
        }

        setDateInterval({
            initial: from.toDateString(),
            final: to.toDateString(),
        })
    }

    const selected = useMemo(
        () =>
            data.find(
                (product) =>
                    product.produto === selectedProduto &&
                    product.bem_abreviatura === selectedSala &&
                    product.lote_de_controle_de_qualidade === selectedLote
            ),
        [data, selectedProduto, selectedSala, selectedLote]
    )

    return (
        <PageContainer ref={mainContainerRef} className="flex-1 p-4">
            <div className="flex justify-between py-4">
                <div className="flex items-center gap-2">
                    <CompanySelect
                        companies={empresas}
                        value={selectedCompany?.toString()}
                        onChange={(value) => setCompany(Number(value))}
                    />
                    <ProdutosSelect
                        onChange={(value) => setProduto(value)}
                        data={produtos}
                        selectedId={selectedProduto}
                    />
                    <LinhasSelect
                        data={tanques}
                        onChange={setSala}
                        selectedId={selectedSala}
                    />
                    <LotesSelect
                        onChange={(value) => setLote(value)}
                        data={lotes}
                        selectedId={selectedLote}
                    />
                </div>
                <div className="flex items-center gap-2">
                    <DateFilter
                        final={final}
                        initial={initial}
                        onSearch={onSearch}
                    />
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={() => refetch()}
                        tooltipContent={'Atualizar dados'}
                    >
                        <RefreshCcw size={14} />
                    </ButtonWithTooltip>
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={onToggleFullScreen}
                        tooltipContent={
                            isFullscreen ? 'Minimizar' : 'Maximizar'
                        }
                    >
                        {isFullscreen ? (
                            <Minimize size={14} />
                        ) : (
                            <Maximize size={14} />
                        )}
                    </ButtonWithTooltip>
                </div>
            </div>
            <div className="ml-auto">
                {dataUpdatedAt !== 0 && (
                    <TypographyMutedXs>
                        Última atualização:{' '}
                        {format(
                            new Date(dataUpdatedAt),
                            'dd/MM/yyyy, HH:mm:ss'
                        )}
                    </TypographyMutedXs>
                )}
            </div>
            <div className="flex flex-col py-2 overflow-hidden">
                <AnimatePresence>
                    {isPending ? (
                        <TopMessage
                            variant="loading"
                            text="Carregando dados..."
                        />
                    ) : null}
                    {!isPending && isFetching ? (
                        <TopMessage
                            variant="success"
                            text="Atualizando dados..."
                        />
                    ) : null}
                    {isError && (
                        <TopMessage
                            variant="error"
                            text="Erro ao buscar dados"
                        />
                    )}
                </AnimatePresence>
                <div className="flex flex-col flex-1 space-y-4 overflow-hidden">
                    {selectedProduto && (
                        <div>
                            <TypographyP className="text-lg font-semibold text-primary">
                                {selectedProduto}
                            </TypographyP>
                            <TypographyP className="text-muted-foreground">
                                {selected?.cenario}
                            </TypographyP>
                        </div>
                    )}
                    <div className="flex flex-1 gap-6 overflow-hidden">
                        <div className="flex-1">
                            <FermentacaoTable
                                data={tableData}
                                lote={selectedLote}
                                order={order}
                            />
                        </div>
                        <AcompanhamentoTable
                            data={tableData}
                            lote={selectedLote}
                        />
                    </div>
                </div>
            </div>
        </PageContainer>
    )
}

export default MonitorFermentacao
