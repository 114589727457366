import { ImmerStateCreator } from '../type'
import { MonitorArrolhamentoSlice, MonitorArrolhamentoState } from './type'
import { getStorageState } from '@/utils/storage'

const initialState: MonitorArrolhamentoState = {
    selectedCompany: undefined,
    selectedSala: { bem: undefined, sala: undefined },
    date: {
        initial: new Date().toISOString(),
        final: new Date().toISOString(),
    },
}

const persist = (state: MonitorArrolhamentoState) => {
    localStorage.setItem('monitor-arrolhamento', JSON.stringify(state))
}

export const useMonitorArrolhamentoSlice: ImmerStateCreator<
    MonitorArrolhamentoSlice
> = (set) => ({
    state: getStorageState({
        key: 'monitor-arrolhamento',
        state: initialState,
    }),
    actions: {
        setCompany: (company) => {
            set((state) => {
                state.monitorArrolhamentoSlice.state.selectedCompany = company

                persist(state.monitorArrolhamentoSlice.state)
            })
        },
        setDateInterval: ({ final, initial }) => {
            set((state) => {
                state.monitorArrolhamentoSlice.state.date.initial = initial
                state.monitorArrolhamentoSlice.state.date.final = final

                persist(state.monitorArrolhamentoSlice.state)
            })
        },
        setSala: (sala) => {
            set((state) => {
                state.monitorArrolhamentoSlice.state.selectedSala = sala

                persist(state.monitorArrolhamentoSlice.state)
            })
        },
        resetDate: () => {
            set((state) => {
                state.monitorArrolhamentoSlice.state.date = {
                    final: new Date().toString(),
                    initial: new Date().toString(),
                }

                persist(state.monitorArrolhamentoSlice.state)
            })
        },
        onReset: () => {
            set((state) => {
                state.monitorArrolhamentoSlice.state = initialState
            })
        },
    },
})
