import {
    fetchMonitorArrolhamento,
    fetchMonitorArrolhamentoFiltros,
} from '@/api/business/arrolhamento'
import {
    FetchMonitorArrolhamentoFiltrosProps,
    FetchMonitorArrolhamentoProps,
    MonitorArrolhamentoFlat,
} from '@/api/business/arrolhamento/type'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export const arrolhamentoKeys = {
    all: ['arrolhamento'] as const,
    lists: () => [...arrolhamentoKeys.all, 'list'] as const,
    list: (props: FetchMonitorArrolhamentoProps) =>
        [...arrolhamentoKeys.lists(), { ...props }] as const,
    details: () => [...arrolhamentoKeys.all, 'detail'] as const,
    detail: (params: string) =>
        [...arrolhamentoKeys.details(), params] as const,
    filters: ({ cod_filial }: FetchMonitorArrolhamentoFiltrosProps) =>
        [...arrolhamentoKeys.lists(), 'filters', cod_filial] as const,
}

export type UseArrolhamentoQueryProps = FetchMonitorArrolhamentoProps & {
    enabled: boolean
}

export type UseArrolhamentoFiltroQueryProps =
    FetchMonitorArrolhamentoFiltrosProps & {
        enabled: boolean
    }

export const useMonitorArrolhamentoFiltrosQuery = ({
    enabled,
    ...props
}: UseArrolhamentoFiltroQueryProps) => {
    return useQuery({
        queryKey: arrolhamentoKeys.filters({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        enabled,
        queryFn: () => fetchMonitorArrolhamentoFiltros({ ...props }),
        placeholderData: keepPreviousData,
        select: (data) =>
            data.sort((a, b) =>
                a.centro_de_custo.localeCompare(b.centro_de_custo)
            ),
    })
}

export const useMonitorArrolhamentoQuery = (
    props: UseArrolhamentoQueryProps
) => {
    return useQuery({
        queryKey: arrolhamentoKeys.list({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        queryFn: () => fetchMonitorArrolhamento({ ...props }),
        placeholderData: keepPreviousData,
        select: (data): MonitorArrolhamentoFlat[] => {
            const flatData: MonitorArrolhamentoFlat[] = []

            data.map((monitor) =>
                monitor.turnos.forEach((turno) => {
                    turno.abreviatura_bem.forEach((abreviaturaBem) => {
                        abreviaturaBem.abreviatura_arrolhador.forEach(
                            (arrolhador) => {
                                arrolhador.cod_amostra.forEach((amostra) => {
                                    amostra.resultados_amostra.forEach(
                                        (resultado) => {
                                            flatData.push({
                                                turno: turno.turno,
                                                abreviatura_bem:
                                                    abreviaturaBem.abreviatura_bem,
                                                abreviatura_arrolhador:
                                                    arrolhador.abreviatura_arrolhador,
                                                cod_amostra:
                                                    amostra.cod_amostra,
                                                data_da_coleta:
                                                    resultado.data_da_coleta,
                                                hora_da_coleta:
                                                    resultado.hora_da_coleta,
                                                observacao_amostra:
                                                    resultado.observacao_amostra,
                                                cod_amostra_reanalise:
                                                    resultado.cod_amostra_reanalise,
                                                skip_lote: resultado.skip_lote,
                                                alteracao: resultado.alteracao,
                                                valor: resultado.valor,
                                                observacao_resultado:
                                                    resultado.observacao_resultado,
                                                status_correcao:
                                                    resultado.status_correcao,
                                                observacao_correcao:
                                                    resultado.observacao_correcao,
                                                nome: resultado.nome,
                                                data_resultado:
                                                    resultado.data_resultado,
                                                hora_resultado:
                                                    resultado.hora_resultado,
                                                ensaio: resultado.ensaio,
                                                lie: resultado.lie,
                                                lse: resultado.lse,
                                                abreviatura_um:
                                                    resultado.abreviatura_um,
                                                cod_especificacao:
                                                    resultado.cod_especificacao,
                                                laudo: resultado.laudo,
                                                cod_ensaio:
                                                    resultado.cod_ensaio,
                                            })
                                        }
                                    )
                                })
                            }
                        )
                    })
                })
            )

            return flatData
        },
    })
}
