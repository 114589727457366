import { ROUTES } from '@/consts/routes'
import { cn } from '@/lib/utils'
import { NavLink, Outlet } from 'react-router-dom'

const navigation = [
    {
        label: ROUTES.FERMENTACAO.label,
        link: ROUTES.FERMENTACAO.getRoutePath(),
    },
    {
        label: ROUTES.FERMENTACAO_DIARIO.label,
        link: ROUTES.FERMENTACAO_DIARIO.getRoutePath(),
    },
    {
        label: ROUTES.FERMENTACAO_PROPAGACAO.label,
        link: ROUTES.FERMENTACAO_PROPAGACAO.getRoutePath(),
    },
    {
        label: ROUTES.FERMENTACAO_PROPAGACAO_DIARIO.label,
        link: ROUTES.FERMENTACAO_PROPAGACAO_DIARIO.getRoutePath(),
    },
]

const MonitorFermentacaoLayout = () => {
    return (
        <>
            <div className="flex items-center justify-center gap-2 p-4 border-b">
                {navigation.map((route) => {
                    return (
                        <NavLink
                            end
                            key={route.link}
                            to={route.link}
                            className={({ isActive }) =>
                                cn(
                                    'text-foreground hover:bg-accent rounded-md p-2 text-sm',
                                    isActive && 'text-primary'
                                )
                            }
                        >
                            {route.label}
                        </NavLink>
                    )
                })}
            </div>
            <Outlet />
        </>
    )
}

export default MonitorFermentacaoLayout
