import { ROUTES } from './consts/routes'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import Login from '@/pages/Login'
import NotFound from '@/pages/NotFound'
import PublicLayout from '@/pages/Layouts/PublicLayout'
import ResetPassword from '@/pages/ResetPassword'
import RequireAuth from '@/middlewares/RequireAuth'
import MainLayout from './pages/Layouts/MainLayout'
import MonitorBrassagem from './pages/MonitorBrassagem'
import MonitorProdutoAcabado from './pages/MonitorProdutoAcabado'
import MonitorFiltracao from './pages/MonitorFiltracao'
import MonitorProvaHoraria from './pages/MonitorProvaHoraria'
import MonitorRastreabilidade from './pages/MonitorRastreabilidade'
import MonitorRecravacao from './pages/MonitorRecravacao'
import MonitorPasteurizacao from './pages/MonitorPasteurizacao'
import MonitorArrolhamento from './pages/MonitorArrolhamento'
import MonitorLubrificanteDeEsteira from './pages/MonitorLubrificanteDeEsteira'
import MonitorMaturacao from './pages/MonitorMaturacao'
import MonitorFermentacao from './pages/MonitorFermentacao'
import MonitorFermentacaoLayout from './pages/MonitorFermentacaoLayout'
import MonitorFermentacaoDiario from './pages/MonitorFermentacaoDiario'
import MonitorFermentacaoPropagacao from './pages/MonitorFermentacaoPropagacao'
import MonitorFermentacaoPropagacaoDiario from './pages/MonitorFermentacaoPropagacaoDiario'
import MonitorEstabilidadeBiologica from './pages/MonitorEstabilidadeBiologica'
import MonitorCipEnvasamento from './pages/MonitorCipEnvasamento'

const router = createBrowserRouter([
    {
        path: ROUTES.HOME.index,
        element: <MainLayout />,
        children: [
            {
                index: true,
                element: <Navigate to={ROUTES.BRASSAGEM.getRoutePath()} />,
            },
            {
                path: ROUTES.PROCESSO.index,
                children: [
                    {
                        path: ROUTES.BRASSAGEM.index,
                        element: (
                            <RequireAuth isAllowed>
                                <MonitorBrassagem />
                            </RequireAuth>
                        ),
                    },
                    {
                        index: true,
                        element: (
                            <RequireAuth isAllowed>
                                <MonitorBrassagem />
                            </RequireAuth>
                        ),
                    },
                    {
                        path: ROUTES.FILTRACAO.index,
                        element: (
                            <RequireAuth isAllowed>
                                <MonitorFiltracao />
                            </RequireAuth>
                        ),
                    },
                    {
                        path: ROUTES.MATURACAO.index,
                        element: (
                            <RequireAuth isAllowed>
                                <MonitorMaturacao />
                            </RequireAuth>
                        ),
                    },
                    {
                        path: ROUTES.FERMENTACAO.index,
                        element: (
                            <RequireAuth isAllowed>
                                <MonitorFermentacaoLayout />
                            </RequireAuth>
                        ),
                        children: [
                            {
                                index: true,
                                element: <MonitorFermentacao />,
                            },
                            {
                                path: ROUTES.FERMENTACAO_DIARIO.index,
                                element: <MonitorFermentacaoDiario />,
                            },
                            {
                                path: ROUTES.FERMENTACAO_PROPAGACAO.index,
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <MonitorFermentacaoPropagacao />
                                        ),
                                    },
                                    {
                                        path: ROUTES
                                            .FERMENTACAO_PROPAGACAO_DIARIO
                                            .index,
                                        element: (
                                            <MonitorFermentacaoPropagacaoDiario />
                                        ),
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                path: ROUTES.ENVASE.index,
                children: [
                    {
                        path: ROUTES.PRODUTO_ACABADO.index,
                        element: (
                            <RequireAuth isAllowed>
                                <MonitorProdutoAcabado />
                            </RequireAuth>
                        ),
                    },
                    {
                        path: ROUTES.ENVASAMENTO.index,
                        element: (
                            <RequireAuth isAllowed>
                                <MonitorProvaHoraria />
                            </RequireAuth>
                        ),
                    },
                    {
                        path: ROUTES.RECRAVACAO.index,
                        element: (
                            <RequireAuth isAllowed>
                                <MonitorRecravacao />
                            </RequireAuth>
                        ),
                    },
                    {
                        path: ROUTES.PASTEURIZACAO.index,
                        element: (
                            <RequireAuth isAllowed>
                                <MonitorPasteurizacao />
                            </RequireAuth>
                        ),
                    },
                    {
                        path: ROUTES.ARROLHAMENTO.index,
                        element: (
                            <RequireAuth isAllowed>
                                <MonitorArrolhamento />
                            </RequireAuth>
                        ),
                    },
                    {
                        path: ROUTES.LUBRIFICANTE_DE_ESTEIRA.index,
                        element: (
                            <RequireAuth isAllowed>
                                <MonitorLubrificanteDeEsteira />
                            </RequireAuth>
                        ),
                    },
                ],
            },
            {
                path: ROUTES.MICROBIOLOGIA.index,
                children: [
                    {
                        path: ROUTES.ESTABILIDADE_BIOLOGICA.index,
                        element: (
                            <RequireAuth isAllowed>
                                <MonitorEstabilidadeBiologica />
                            </RequireAuth>
                        ),
                    },
                ],
            },
            {
                path: ROUTES.CIP.index,
                children: [
                    {
                        path: ROUTES.CIP_ENVASAMENTO.index,
                        element: (
                            <RequireAuth isAllowed>
                                <MonitorCipEnvasamento />
                            </RequireAuth>
                        ),
                    },
                ],
            },
            {
                path: ROUTES.RASTREABILIDADE.index,
                element: (
                    <RequireAuth isAllowed>
                        <MonitorRastreabilidade />
                    </RequireAuth>
                ),
            },
        ],
    },
    {
        element: <PublicLayout />,
        children: [
            {
                path: ROUTES.NOT_FOUND.index,
                element: <NotFound />,
            },
            {
                path: ROUTES.LOGIN.index,
                element: <Login />,
            },
            {
                path: ROUTES.RECOVER_PASSWORD.index,
                element: <ResetPassword />,
            },
        ],
    },
])

const Router = () => {
    return <RouterProvider router={router} />
}

export default Router
