import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { ColumnPinningState, createColumnHelper } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { MonitorMaturacao } from '@/api/business/maturacao/type'
import CellPopover from './components/CellPopover'
import { sort } from '@/utils/sort'
import { TypographyP } from '@/components/ui/typography'

type MaturacaoTableProps = {
    data?: MonitorMaturacao[]
    lote?: string
    bem?: string
    order: Record<number, number>
}

const MaturacaoTable = ({
    data = [],
    lote,
    bem,
    order,
}: MaturacaoTableProps) => {
    const [pinningState, setPinningState] = useState<ColumnPinningState>({
        left: ['ensaio'],
    })

    const filteredData = useMemo(() => {
        return data.filter(
            (value) =>
                value.lote_de_controle_de_qualidade === lote &&
                value.bem_abreviatura === bem
        )
    }, [bem, lote, data])

    const tableData = useMemo(() => {
        const ensaios: Record<
            string,
            {
                cod_ensaio: number
                ensaio: string
                data: MonitorMaturacao[]
            }
        > = {}

        for (const row of filteredData) {
            for (const resultado of row.resultados) {
                if (!ensaios[resultado.cod_ensaio]) {
                    ensaios[resultado.cod_ensaio] = {
                        cod_ensaio: resultado.cod_ensaio,
                        ensaio: resultado.ensaio,
                        data: [],
                    }
                }

                ensaios[resultado.cod_ensaio].data.push(row)
            }
        }

        return sort(Object.values(ensaios), 'cod_ensaio', order)
    }, [filteredData])

    const tableColumnHeaders = useMemo(() => {
        const produtos: Record<string, MonitorMaturacao> = {}

        for (const row of filteredData) {
            if (row.produto && !produtos[row.produto])
                produtos[row.produto] = row
        }

        return Object.values(produtos)
    }, [filteredData])

    type MaturacaoTableData = TableData<(typeof tableData)[0]>

    const columnHelper = createColumnHelper<MaturacaoTableData>()

    const columns = useMemo(
        () => [
            columnHelper.accessor('ensaio', {
                id: 'ensaio',
                header: 'Ensaio',
                size: 200,
                enableHiding: false,
            }),
            ...tableColumnHeaders.map((header) => {
                return columnHelper.display({
                    id: header.produto,
                    header: () => {
                        return (
                            <div>
                                <TypographyP className="text-xs">
                                    {header.produto}
                                </TypographyP>
                            </div>
                        )
                    },
                    cell: ({ row }) => {
                        const { ensaio, data } = row.original

                        const value = data.find(
                            (value) => value.produto === header.produto
                        )

                        if (!value) return null

                        return value.resultados
                            .filter((value) => value.ensaio === ensaio)
                            .map((value) => (
                                <CellPopover
                                    key={value.cod_amostra}
                                    data={value}
                                />
                            ))
                    },
                    size: 400,
                    meta: {
                        header: {
                            className: 'p-2',
                        },
                        cell: {
                            className: 'p-0',
                        },
                    },
                    enableHiding: false,
                })
            }),
        ],
        [tableColumnHeaders]
    )

    return (
        <Table<MaturacaoTableData>
            data={tableData}
            columns={columns}
            tableState={{
                columnPinning: pinningState,
            }}
            onColumnPinningStateChange={setPinningState}
            rowStimateSize={40}
        />
    )
}

export default MaturacaoTable
