import { TypographyP } from '@/components/ui/typography'
import { Route } from '@/consts/routes'
import SidenavItem from '../SidenavItem'
import { ChevronDown } from 'lucide-react'
import { cn } from '@/lib/utils'
import { AnimatePresence, motion } from 'framer-motion'
import { useMemo, useRef } from 'react'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'

type LinkSectionProps = {
    title: string
    index: string
    routes: Route[]
    filterValue: string
    shouldAnimate: boolean
    setShouldAnimate: (shouldAnimate: boolean) => void
    onOpenChange: (isOpen: boolean) => void
}

const stateSelector = (key: string) => (state: Store) => ({
    isOpen: state.appConfigSlice.state.sidenavItemsToggleState[key],
    onToggle: state.appConfigSlice.actions.onToggleSidenavItemState,
})

const LinkSection = ({
    routes,
    title,
    index,
    shouldAnimate,
    filterValue,
    setShouldAnimate,
    onOpenChange,
}: LinkSectionProps) => {
    const contentRef = useRef<HTMLDivElement>(null)

    const { isOpen, onToggle } = useBaseStore(stateSelector(title))

    const children = useMemo(
        () =>
            routes.filter((child) => {
                return (
                    child.getParentRoute() === index &&
                    child.label
                        .toLowerCase()
                        .trim()
                        .includes(filterValue?.toLowerCase().trim())
                )
            }),
        [routes, index, filterValue]
    )

    const transition = {
        duration: 0.5,
        ease: [0.25, 0.8, 0.5, 1],
    }

    if (children.length === 0) return null

    return (
        <motion.div
            className="space-y-1 overflow-hidden bg-background"
            id={index}
        >
            <div
                className="z-50 flex items-center gap-2 p-1 rounded-md cursor-pointer shrink-0 hover:bg-accent"
                onClick={() => {
                    onToggle(title)
                    setShouldAnimate(true)
                }}
            >
                <ChevronDown
                    className={cn(
                        'text-foreground transition-all ease-in duration-150',
                        isOpen ? 'rotate-0' : 'rotate-180'
                    )}
                    size={14}
                />
                <TypographyP className="font-semibold">{title}</TypographyP>
            </div>
            <div className="overflow-hidden">
                <AnimatePresence initial={false}>
                    {isOpen && (
                        <motion.div
                            ref={contentRef}
                            className={cn('z-0 bg-background')}
                            initial={{ height: 0 }}
                            animate={{
                                height: 'auto',
                            }}
                            exit={{ height: 0 }}
                            transition={
                                shouldAnimate ? transition : { duration: 0 }
                            }
                        >
                            {children.map((child) => (
                                <SidenavItem
                                    key={child.getRoutePath()}
                                    {...child}
                                    onOpenChange={onOpenChange}
                                />
                            ))}
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </motion.div>
    )
}

export default LinkSection
