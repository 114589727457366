import {
    ChartConfig,
    ChartContainer,
    ChartLegend,
    ChartLegendContent,
    ChartTooltip,
    ChartTooltipContent,
} from '@/components/ui/chart'
import { CartesianGrid, Dot, Line, LineChart, YAxis } from 'recharts'
import { FermentacaoData } from '../AcompanhamentoTable'
import { useMemo } from 'react'

import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from '@/components/ui/card'
import { TypographyP } from '@/components/ui/typography'

type AcompanhamentoSheetProps = {
    data: FermentacaoData[]
}

const AcompanhamentoChart = ({ data }: AcompanhamentoSheetProps) => {
    const chartData = useMemo(
        () =>
            data.map((value) => {
                const { date, ensaios } = value

                const ens = Object.values(ensaios)

                return ens.reduce((acc, curr) => {
                    const ensaio = curr.ensaio
                        .split(' ')
                        .join('-')
                        .toLowerCase()

                    if (curr.ensaio === 'PH') return acc

                    return {
                        ...acc,
                        date,
                        [ensaio]: Number(
                            curr.resultado.valor.replace(',', '.')
                        ),
                        fill: `var(--color-${ensaio})`,
                    }
                }, {} as Record<string, string | number>)
            }),
        [data]
    )

    const chartConfig = useMemo(() => {
        const config = {}

        if (!chartData.length) return config

        let count = 1

        for (const key of Object.keys(chartData[0])) {
            if (key === 'extrato-aparente') {
                config[key] = {
                    label: 'Extrato aparente',
                    color: `hsl(var(--chart-${count}))`,
                }

                count++
            }
        }

        return config
    }, [chartData]) satisfies ChartConfig

    return (
        <Card>
            <CardHeader>
                <CardTitle>Gráfico de Fermentação</CardTitle>
                <CardDescription></CardDescription>
            </CardHeader>
            <CardContent className="flex items-center">
                <div>
                    <TypographyP className="text-md [writing-mode:vertical-lr] rotate-180">
                        Extrato (ºP)
                    </TypographyP>
                </div>
                <ChartContainer config={chartConfig} className="min-h-[300px]">
                    <LineChart
                        data={chartData}
                        margin={{
                            left: 12,
                            right: 12,
                        }}
                    >
                        <CartesianGrid />
                        <YAxis axisLine={false} tickMargin={22} />
                        <ChartTooltip
                            cursor={false}
                            content={<ChartTooltipContent />}
                        />
                        <Line
                            dataKey="extrato-aparente"
                            stroke="var(--color-extrato-aparente)"
                            type="natural"
                            strokeWidth={2}
                            activeDot={{
                                r: 6,
                            }}
                            dot={({ payload, ...props }) => {
                                return (
                                    <Dot
                                        key={payload.browser}
                                        r={5}
                                        cx={props.cx}
                                        cy={props.cy}
                                        fill={payload.fill}
                                        stroke={payload.fill}
                                    />
                                )
                            }}
                        />
                        <ChartLegend content={<ChartLegendContent />} />
                    </LineChart>
                </ChartContainer>
            </CardContent>
        </Card>
    )
}

export default AcompanhamentoChart
