import { EMPRESAS } from '@/consts/companies'
import { ImmerStateCreator } from '../type'
import { MonitorBrassagemSlice, MonitorBrassagemState } from './type'
import { SALAS } from '@/pages/MonitorBrassagem/consts'
import { getStorageState, persistState } from '@/utils/storage'

const initialState: MonitorBrassagemState = {
    selectedCompany: EMPRESAS[0].value,
    selectedSala: SALAS[0].value,
    date: {
        initial: new Date().toISOString(),
        final: new Date().toISOString(),
    },
    tableLayoutState: {},
}

const key = 'monitor-brassagem'

const persist = (state: MonitorBrassagemState) => {
    persistState({ key, state })
}

export const useMonitorBrassagemSlice: ImmerStateCreator<
    MonitorBrassagemSlice
> = (set) => ({
    state: getStorageState({
        key,
        state: initialState,
    }),
    actions: {
        setCompany: (company) => {
            set((state) => {
                state.monitorBrassagemSlice.state.selectedCompany = company

                persist(state.monitorBrassagemSlice.state)
            })
        },
        setDateInterval: ({ final, initial }) => {
            set((state) => {
                state.monitorBrassagemSlice.state.date.initial = initial
                state.monitorBrassagemSlice.state.date.final = final

                persist(state.monitorBrassagemSlice.state)
            })
        },
        setSala: (sala) => {
            set((state) => {
                state.monitorBrassagemSlice.state.selectedSala = sala

                persist(state.monitorBrassagemSlice.state)
            })
        },
        setTableLayout: (tableLayout) => {
            set((state) => {
                const stateLayout =
                    state.monitorBrassagemSlice.state.tableLayoutState

                Object.entries(tableLayout).forEach(([key, value]) => {
                    if (!stateLayout[key]) {
                        stateLayout[key] = { ...value }
                    } else {
                        Object.entries(value).forEach(([key2, value2]) => {
                            if (!(key2 in stateLayout[key])) {
                                stateLayout[key][key2] = value2
                            }
                        })
                    }
                })
            })
        },
        toggleTableLayout: ({ operacao, ensaio, value }) => {
            set((state) => {
                if (ensaio) {
                    const prevOperacao =
                        state.monitorBrassagemSlice.state.tableLayoutState[
                            operacao
                        ]

                    if (prevOperacao) {
                        prevOperacao[ensaio] = value
                    }
                } else {
                    state.monitorBrassagemSlice.state.tableLayoutState[
                        operacao
                    ] = Object.keys(
                        state.monitorBrassagemSlice.state.tableLayoutState[
                            operacao
                        ]
                    ).reduce((acc, curr) => {
                        acc[curr] = value
                        return acc
                    }, {})
                }

                persist(state.monitorBrassagemSlice.state)
            })
        },
        resetDate: () => {
            set((state) => {
                state.monitorBrassagemSlice.state.date = {
                    final: new Date().toString(),
                    initial: new Date().toString(),
                }
            })
        },
        onReset: () => {
            set((state) => {
                state.monitorBrassagemSlice.state = initialState
            })
        },
    },
})
