import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { ColumnPinningState, createColumnHelper } from '@tanstack/react-table'
import { useCallback, useMemo, useState } from 'react'
import {
    MonitorProvaHoraria,
    Resultado,
} from '@/api/business/provaHoraria/type'
import CellPopover from './components/CellPopover'
import {
    TypographyMutedXs,
    TypographyP,
    TypographyXS,
} from '@/components/ui/typography'
import { cn } from '@/lib/utils'
import { sort } from '@/utils/sort'

type ProvaHorariaTableProps = {
    data?: MonitorProvaHoraria[]
    order: Record<number, number>
}

const ProvaHorariaTable = ({ data = [], order }: ProvaHorariaTableProps) => {
    const getId = useCallback(
        (
            data_da_coleta: string,
            hora_da_coleta: string,
            cod_skip_lote: number
        ) => `${data_da_coleta}${hora_da_coleta}${cod_skip_lote}`,
        []
    )

    const tableData = useMemo(() => {
        const ensaios: Record<
            string,
            Resultado & { data: MonitorProvaHoraria[] }
        > = {}

        for (const row of data) {
            for (const result of row.resultados) {
                if (!ensaios[result.cod_ensaio])
                    ensaios[result.cod_ensaio] = { ...result, data: [] }

                ensaios[result.cod_ensaio].data.push(row)
            }
        }

        return sort(Object.values(ensaios), 'cod_ensaio', order)
    }, [data])

    const tableColumnHeaders = useMemo(() => {
        const lotes: Record<string, MonitorProvaHoraria[]> = {}

        for (const row of data) {
            const id = getId(
                row.data_da_coleta,
                row.hora_da_coleta,
                row.cod_skip_lote
            )

            if (!lotes[id]) {
                lotes[id] = []
            }

            lotes[id].push(row)
        }

        return lotes
    }, [data])

    const [pinningState, setPinningState] = useState<ColumnPinningState>({
        left: ['ensaio'],
    })

    type ProvaHorariaTableData = TableData<(typeof tableData)[0]>

    const columnHelper = createColumnHelper<ProvaHorariaTableData>()

    const columns = useMemo(
        () => [
            columnHelper.accessor('ensaio', {
                id: 'ensaio',
                header: 'Ensaio',
                size: 200,
                enableHiding: false,
                cell: ({ row, getValue }) => {
                    const { cod_especificacao } = row.original

                    const isExtra = !cod_especificacao

                    return (
                        <div className="flex gap-1 overflow-hidden text-ellipsis whitespace-nowrap">
                            <TypographyP className="flex-1 overflow-hidden text-xs text-ellipsis whitespace-nowrap">
                                {getValue()}
                            </TypographyP>
                            {isExtra && (
                                <span className="text-md text-amber-600">
                                    *
                                </span>
                            )}
                        </div>
                    )
                },
            }),
            ...Object.keys(tableColumnHeaders).map((header) => {
                const headerValue = data.find(
                    (value) =>
                        getId(
                            value.data_da_coleta,
                            value.hora_da_coleta,
                            value.cod_skip_lote
                        ) === header
                )

                const isEnchedora = headerValue?.resultados.some((result) =>
                    result.skip_lote?.includes('ENCHEDORA')
                )
                const isLinha = headerValue?.resultados.some((result) =>
                    result.skip_lote?.includes('LINHA')
                )

                return columnHelper.display({
                    id: header.toString(),
                    header: () => {
                        const commonClassName = cn(
                            'overflow-hidden text-ellipsis font-normal',
                            isEnchedora || isLinha ? 'text-white' : ''
                        )

                        return (
                            <div className="flex flex-col space-y-0.5 overflow-hidden">
                                <TypographyXS className={cn(commonClassName)}>
                                    {headerValue?.abreviatura_produto}
                                </TypographyXS>
                                <TypographyXS className={cn(commonClassName)}>
                                    {headerValue?.numero_de_controle}
                                </TypographyXS>
                                <TypographyXS
                                    className={cn(
                                        isEnchedora || isLinha
                                            ? 'text-white'
                                            : ''
                                    )}
                                >
                                    {headerValue?.data_da_coleta
                                        .split('-')
                                        .reverse()
                                        .join('/')}
                                </TypographyXS>
                                <TypographyMutedXs
                                    className={cn(commonClassName)}
                                >
                                    {headerValue?.hora_da_coleta}
                                </TypographyMutedXs>
                            </div>
                        )
                    },
                    cell: ({ row }) => {
                        const { ensaio } = row.original

                        return tableColumnHeaders[header].map((result) => {
                            return result.resultados
                                .filter((res) => res.ensaio === ensaio)
                                .map((res) => {
                                    return (
                                        <CellPopover
                                            header={result}
                                            data={res}
                                        />
                                    )
                                })
                        })
                    },
                    size: 175,
                    meta: {
                        header: {
                            className: cn(
                                isEnchedora &&
                                    'bg-[#cd9b29f1] dark:bg-[#a08310f2]',
                                isLinha && 'bg-sky-600 dark:bg-sky-700'
                            ),
                            menu: {
                                className: cn(
                                    isEnchedora &&
                                        'bg-transparent hover:bg-[#b48822f1] dark:hover:bg-[#856e13ef]',
                                    isLinha &&
                                        'bg-xadrez dark:bg-sky-700 hover:bg-sky-700 dark:hover:bg-sky-800'
                                ),
                            },
                        },
                        cell: {
                            className: 'p-0',
                        },
                    },
                    enableHiding: false,
                })
            }),
        ],
        [tableColumnHeaders]
    )

    return (
        <Table<ProvaHorariaTableData>
            data={tableData}
            columns={columns}
            tableState={{
                columnPinning: pinningState,
            }}
            onColumnPinningStateChange={setPinningState}
        />
    )
}

export default ProvaHorariaTable
