import { Button } from '@/components/ui/button'
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet'
import { LineChart as LineChartIcon } from 'lucide-react'
import { FermentacaoData } from '../AcompanhamentoTable'
import AcompanhamentoChart from '../AcompanhamentoChart'

type AcompanhamentoSheetProps = {
    data: FermentacaoData[]
}

const AcompanhamentoSheet = ({ data }: AcompanhamentoSheetProps) => {
    return (
        <Sheet>
            <SheetTrigger>
                <Button size="icon" variant="ghost">
                    <LineChartIcon size={14} />
                </Button>
            </SheetTrigger>
            <SheetContent className="sm:max-w-[650px] ">
                <div className="p-4" />
                <AcompanhamentoChart data={data} />
            </SheetContent>
        </Sheet>
    )
}

export default AcompanhamentoSheet
