import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { ColumnPinningState, createColumnHelper } from '@tanstack/react-table'
import { useCallback, useMemo, useState } from 'react'
import {
    MonitorEstabilidadeBiologica,
    Resultado,
} from '@/api/business/estabilidadeBiologica/type'
import CellPopover from './components/CellPopover'
import { getDate } from '@/utils/date'
import {
    differenceInWeeks,
    format,
    intlFormatDistance,
    isValid,
} from 'date-fns'
import FacetedFilter from '@/components/FacetedFilter'
import { TypographyMutedXs } from '@/components/ui/typography'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'

type EstabilidadeBiologicaTableProps = {
    data?: MonitorEstabilidadeBiologica[]
    dataUpdatedAt: number
}

type EstabilidadeBiologicaTableData = TableData<MonitorEstabilidadeBiologica>

type Laudo = Resultado['laudo']

const laudos = ['CONFORME', 'NÃO CONFORME', 'NÃO AVALIADO'] satisfies Laudo[]

const stateSelector = (state: Store) => ({
    selectedLaudos:
        state.monitorEstabilidadeBiologicaSlice.state.selectedLaudos,
    selectedLinhas:
        state.monitorEstabilidadeBiologicaSlice.state.selectedLinhas,
    onSelectLaudo:
        state.monitorEstabilidadeBiologicaSlice.actions.onSelectLaudo,
    onSelectLinha:
        state.monitorEstabilidadeBiologicaSlice.actions.onSelectLinha,
    onClearLinha: state.monitorEstabilidadeBiologicaSlice.actions.onClearLinha,
    onClearLaudo: state.monitorEstabilidadeBiologicaSlice.actions.onClearLaudo,
})

const EstabilidadeBiologicaTable = ({
    data = [],
    dataUpdatedAt,
}: EstabilidadeBiologicaTableProps) => {
    const [pinningState, setPinningState] = useState<ColumnPinningState>({
        left: ['ensaio'],
    })

    const { selectedLaudos, selectedLinhas, onClearLaudo, onSelectLaudo } =
        useBaseStore(stateSelector)

    const onFilterData = useCallback(
        (
            data: MonitorEstabilidadeBiologica[],
            linhas: Record<string, boolean>,
            laudos: Record<Laudo, boolean>
        ) => {
            const hasCentroDeCusto = Object.entries(linhas).some(
                ([, value]) => value
            )

            const passesCentroDeCusto = (centroDeCusto: string) =>
                !hasCentroDeCusto || linhas[centroDeCusto]

            const laudosSet = new Set(
                Object.entries(laudos)
                    .filter(([, val]) => val)
                    .map(([laudo]) => laudo)
            )

            const hasLaudos = laudosSet.size > 0
            const includesConforme = laudosSet.has('CONFORME')
            const includesNaoConforme = laudosSet.has('NÃO CONFORME')

            return data.filter((value) => {
                if (!passesCentroDeCusto(value.centro_de_custo)) {
                    return false
                }

                if (!hasLaudos) {
                    return true
                }

                const { resultados } = value

                const laudosDoItem = new Set(
                    resultados.map((result) => result.laudo)
                )

                if (laudosSet.size > 1) {
                    return Array.from(laudosSet).every((laudo) =>
                        laudosDoItem.has(laudo as Laudo)
                    )
                }

                if (includesConforme) {
                    return resultados.every(
                        (result) => result.laudo === 'CONFORME'
                    )
                }

                if (includesNaoConforme) {
                    return resultados.some(
                        (result) => result.laudo === 'NÃO CONFORME'
                    )
                }

                return resultados.some((result) => laudosSet.has(result.laudo))
            })
        },
        []
    )

    const tableData = useMemo(() => {
        return onFilterData(data, selectedLinhas, selectedLaudos)
    }, [data, selectedLinhas, selectedLaudos])

    const tableColumnHeaders = useMemo(() => {
        const ensaios: Record<string, { header: string }> = {}

        const hasLaudos = Object.entries(selectedLaudos).some(
            ([, value]) => value
        )

        for (const row of data) {
            for (const result of row.resultados.filter(
                (result) => selectedLaudos[result.laudo] || !hasLaudos
            )) {
                if (!ensaios[result.cod_ensaio])
                    ensaios[result.cod_ensaio] = {
                        header: result.ensaio,
                        // data: data.filter((value) =>
                        //     value.resultados.some(
                        //         (res) => res.cod_ensaio === result.cod_ensaio
                        //     )
                        // ),
                    }
            }
        }

        return Object.values(ensaios).sort((a, b) =>
            a.header.localeCompare(b.header)
        )
    }, [data, selectedLaudos])

    const columnHelper = createColumnHelper<EstabilidadeBiologicaTableData>()

    const columns = useMemo(
        () => [
            columnHelper.accessor('produto', {
                id: 'produto',
                header: 'Produto',
                size: 250,
            }),
            columnHelper.accessor('cod_amostra', {
                id: 'cod_amostra',
                header: 'Cod amostra',
                size: 90,
            }),
            columnHelper.accessor('numero_de_controle', {
                id: 'numero_de_controle',
                header: 'Controle',
                size: 200,
            }),
            columnHelper.accessor('lote_de_controle_de_qualidade', {
                id: 'LCQ',
                header: 'LCQ',
                size: 170,
            }),
            columnHelper.accessor('centro_de_custo', {
                id: 'centro_de_custo',
                header: 'Centro de custo',
                size: 170,
            }),
            columnHelper.accessor('abreviatura_bem', {
                id: 'abreviatura_bem',
                header: 'Bem',
                size: 170,
            }),
            columnHelper.accessor(
                ({ data_da_coleta, hora_da_coleta }) => {
                    const date = getDate({
                        date: data_da_coleta,
                        hour: hora_da_coleta + ':00',
                    })

                    if (!isValid(date)) return null

                    return format(date, 'dd/MM/yyyy, hh:mm')
                },
                {
                    id: 'data_da_coleta',
                    header: 'Data e hora',
                    size: 140,
                }
            ),
            columnHelper.accessor(
                ({ data_da_coleta, hora_da_coleta }) => {
                    const date = getDate({
                        date: data_da_coleta,
                        hour: hora_da_coleta + ':00',
                    })

                    if (!isValid(date)) return null

                    const weeks = differenceInWeeks(new Date(), date)

                    if (weeks === 0 || weeks >= 8)
                        return intlFormatDistance(date, new Date())

                    return `${weeks} semanas atrás`
                },
                {
                    id: 'semanas',
                    header: 'Intervalo',
                    size: 140,
                    enableHiding: false,
                }
            ),
            ...tableColumnHeaders.map((header) => {
                return columnHelper.display({
                    id: header.header,
                    header: header.header,
                    cell: ({ row }) => {
                        const { resultados } = row.original

                        const values = resultados.filter(
                            (result) => result.ensaio === header.header
                        )

                        return values.map((result) => (
                            <CellPopover
                                key={result.cod_amostra}
                                data={result}
                            />
                        ))
                    },
                    enableColumnFilter: true,
                    size: 310,
                    meta: {
                        cell: {
                            className: 'p-0',
                        },
                    },

                    enableHiding: false,
                })
            }),
        ],
        [tableColumnHeaders]
    )

    // useEffect(() => {
    //     onSelectLinha(selectedLinhas.filter((linha) => linhas.includes(linha)))
    // }, [linhas])

    return (
        <>
            <div className="flex items-center justify-between py-4">
                <div className="space-x-2">
                    <FacetedFilter
                        title="Laudo"
                        selectedValues={selectedLaudos}
                        options={laudos.map((laudo) => ({
                            label: laudo,
                            value: laudo,
                        }))}
                        onSelectionChange={(value) =>
                            onSelectLaudo(value as Laudo)
                        }
                        onClearFilters={onClearLaudo}
                    />
                </div>
                <div className="ml-auto">
                    {dataUpdatedAt !== 0 && (
                        <TypographyMutedXs>
                            Última atualização:{' '}
                            {format(
                                new Date(dataUpdatedAt),
                                'dd/MM/yyyy, HH:mm:ss'
                            )}
                        </TypographyMutedXs>
                    )}
                </div>
            </div>
            <div className="flex-1 overflow-hidden">
                <Table<EstabilidadeBiologicaTableData>
                    data={tableData}
                    columns={columns}
                    tableState={{
                        columnPinning: pinningState,
                    }}
                    onColumnPinningStateChange={setPinningState}
                />
            </div>
        </>
    )
}

export default EstabilidadeBiologicaTable
