const sortByField = <T, K extends keyof T>(
    array: T[],
    field: K,
    order: 'asc' | 'desc' = 'asc'
): T[] =>
    array.sort((a, b) => {
        let valueA: any = a[field]
        let valueB: any = b[field]

        if (typeof valueA === 'string') {
            valueA = valueA.toUpperCase()
        }

        if (typeof valueB === 'string') {
            valueB = valueB.toUpperCase()
        }

        let comparison = 0

        if (valueA > valueB) {
            comparison = 1
        } else if (valueA < valueB) {
            comparison = -1
        }

        return order === 'desc' ? comparison * -1 : comparison
    })

const sort = <T, K extends keyof T>(
    array: T[],
    field: K,
    order: Record<string, number>
): T[] =>
    array.sort((a, b) => {
        const fieldA = a[field] as string
        const fieldB = b[field] as string

        const orderA = order[fieldA]
        const orderB = order[fieldB]

        if (orderA === undefined && orderB === undefined) {
            return 0
        } else if (orderA === undefined) {
            return 1
        } else if (orderB === undefined) {
            return -1
        }

        return orderA > orderB ? 1 : -1
    })

export { sortByField, sort }
