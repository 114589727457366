import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { ColumnPinningState, createColumnHelper } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { MonitorArrolhamentoFlat } from '@/api/business/arrolhamento/type'
import CellPopover from './components/CellPopover'
import { sort } from '@/utils/sort'

type ArrolhamentoTableProps = {
    turno: string
    data: MonitorArrolhamentoFlat[]
    order: Record<number, number>
}

const ArrolhamentoTable = ({ data, turno, order }: ArrolhamentoTableProps) => {
    const [pinningState, setPinningState] = useState<ColumnPinningState>({
        left: ['ensaio'],
    })

    const tableData = useMemo(() => {
        const ensaios: Record<
            number,
            {
                cod_ensaio: number
                ensaio: string
                data: Record<string, MonitorArrolhamentoFlat>
            }
        > = {}

        for (const row of data) {
            const id = `${row.turno}${row.abreviatura_arrolhador}${row.cod_amostra}`

            if (!ensaios[row.ensaio]) {
                ensaios[row.ensaio] = {
                    cod_ensaio: row.cod_ensaio,
                    ensaio: row.ensaio,
                    data: {},
                }
            }

            if (!ensaios[row.ensaio].data[id]) {
                ensaios[row.ensaio].data = {
                    ...ensaios[row.ensaio].data,
                    [id]: row,
                }
            }
        }

        return sort(Object.values(ensaios), 'cod_ensaio', order).map(
            (ensaio) => ({
                ...ensaio,
                data: Object.values(ensaio.data),
            })
        )
    }, [data])

    const tableColumnHeaders = useMemo(() => {
        const headers: Record<string, string> = {}

        for (const row of data) {
            headers[row.abreviatura_arrolhador] = row.abreviatura_arrolhador
        }

        return Object.values(headers)
    }, [data])

    type ArrolhamentoTableData = TableData<(typeof tableData)[0]>

    const columnHelper = createColumnHelper<ArrolhamentoTableData>()

    const columns = useMemo(
        () => [
            columnHelper.accessor('ensaio', {
                id: 'ensaio',
                header: 'Ensaio',
                size: 220,
                enableHiding: false,
                meta: {
                    cell: { className: 'p-3' },
                },
            }),
            columnHelper.group({
                id: 'data',
                header: () => {
                    return <div className="text-primary">Turno {turno}</div>
                },
                columns: tableColumnHeaders.map((header) => {
                    return columnHelper.display({
                        id: `data.${header}`,
                        header: header,
                        size: 100,
                        enableHiding: false,
                        meta: {
                            cell: {
                                className: 'p-0',
                            },
                        },
                        cell: ({ row }) => {
                            const { data, ensaio } = row.original

                            const resultados = data.filter(
                                (value) =>
                                    value.ensaio === ensaio &&
                                    value.abreviatura_arrolhador === header
                            )

                            return resultados.map((result) => (
                                <CellPopover data={result} />
                            ))
                        },
                    })
                }),
            }),
        ],
        [tableColumnHeaders]
    )

    return (
        <Table<ArrolhamentoTableData>
            data={tableData}
            columns={columns}
            tableState={{
                columnPinning: pinningState,
            }}
            onColumnPinningStateChange={setPinningState}
            className="h-auto overflow-visible"
        />
    )
}

export default ArrolhamentoTable
