import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/components/ui/popover'
import { Button } from '@/components/ui/button'
import { PlusCircle } from 'lucide-react'
import { TypographyXS } from '@/components/ui/typography'
import { Separator } from '@/components/ui/separator'
import VirtualizedCommand from '../VirtualizedCommand'

export type FacetedFilterOption = {
    label: string
    value: string
}

type FacetedFilterProps = {
    title: string
    limit?: number
    renderIcon?: boolean
    placeholder?: string
    options: FacetedFilterOption[]
    selectedValues: Record<string, boolean>
    onSelectionChange: (value: string) => void
    onClearFilters?: () => void
}

const FacetedFilter = ({
    title,
    limit = 2,
    renderIcon = true,
    placeholder = 'Selecione um valor',
    selectedValues,
    options,
    onSelectionChange,
    onClearFilters,
}: FacetedFilterProps) => {
    const selectedArray = Object.entries(selectedValues)
        .filter(([_, isSelected]) => isSelected)
        .map(([value]) => value)

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    className="gap-2 text-popover-foreground"
                    variant="outline"
                >
                    {renderIcon && <PlusCircle size={14} />}
                    {title}
                    {selectedArray.length > 0 && (
                        <>
                            <Separator orientation="vertical" />
                            <div className="flex items-center gap-1.5">
                                {selectedArray.length > limit ? (
                                    <div className="px-2 rounded-md bg-accent">
                                        <TypographyXS>
                                            {selectedArray.length} selecionados
                                        </TypographyXS>
                                    </div>
                                ) : (
                                    selectedArray.map((value) => (
                                        <div
                                            key={value}
                                            className="px-2 rounded-md bg-accent"
                                        >
                                            <TypographyXS>{value}</TypographyXS>
                                        </div>
                                    ))
                                )}
                            </div>
                        </>
                    )}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="p-0" align="start">
                <VirtualizedCommand
                    placeholder={placeholder}
                    selectedValues={selectedValues}
                    options={options}
                    onSelectionChange={onSelectionChange}
                    onClearFilters={onClearFilters}
                />
            </PopoverContent>
        </Popover>
    )
}

export default FacetedFilter
