import { useMonitorMaturacaoQuery } from '@/queries/useMonitorMaturacaoQuery'
import { format, isBefore } from 'date-fns'
import { useEffect, useMemo, useRef } from 'react'
import { TypographyMutedXs } from '@/components/ui/typography'
import CompanySelect from '../../components/CompanySelect'
import { Maximize, Minimize, RefreshCcw } from 'lucide-react'
import TopMessage from '@/components/TopMessage'
import PageContainer from '@/components/PageContainer'
import MaturacaoTable from './components/MaturacaoTable'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
import ButtonWithTooltip from '@/components/ButtonWithTooltip'
import LinhasSelect from './components/LinhasSelect'
import { AnimatePresence } from 'framer-motion'
import LotesSelect from './components/LotesSelect'
import { useEmpresaQuery } from '@/queries/useEmpresaQuery'
import { useFullscreen } from '@/hooks/useFullscreen'
import { toast } from 'sonner'
import { isBetween } from '@/utils/date'
import { useSequenciaEnsaiosQuery } from '@/queries/useSequenciaEnsaiosQuery'
import { MonitorMaturacao as MonitorMaturacaoType } from '@/api/business/maturacao/type'
import DateFilter, { OnSearchProps } from '@/components/DateFilter'

const stateSelector = (state: Store) => ({
    selectedCompany: state.monitorMaturacaoSlice.state.selectedCompany,
    date: state.monitorMaturacaoSlice.state.date,
    selectedBem: state.monitorMaturacaoSlice.state.selectedBem,
    selectedLote: state.monitorMaturacaoSlice.state.selectedLote,
    setLote: state.monitorMaturacaoSlice.actions.setLote,
    setBem: state.monitorMaturacaoSlice.actions.setBem,
    setCompany: state.monitorMaturacaoSlice.actions.setCompany,
    setDateInterval: state.monitorMaturacaoSlice.actions.setDateInterval,
    resetDate: state.monitorMaturacaoSlice.actions.resetDate,
    resetFilters: state.monitorMaturacaoSlice.actions.resetFilters,
})

const MonitorMaturacao = () => {
    const mainContainerRef = useRef<HTMLDivElement | null>(null)

    const {
        selectedCompany,
        date: { initial, final },
        selectedBem,
        selectedLote,
        setLote,
        setDateInterval,
        setBem,
        setCompany,
        // resetFilters,
    } = useBaseStore(stateSelector)

    const { data: order = {}, isSuccess: isOrderSuccess } =
        useSequenciaEnsaiosQuery({
            cod_filial: Number(selectedCompany),
            monitor: 'MATURAÇÃO',
            enabled: !!selectedCompany,
        })

    const { data: empresas = [], isSuccess: isEmpresasSuccess } =
        useEmpresaQuery()

    const {
        data = [],
        isPending,
        isFetching,
        isError,
        dataUpdatedAt,
        refetch,
    } = useMonitorMaturacaoQuery({
        cod_filial: selectedCompany!,
        data_inicial: Number(format(new Date(initial), 'yyyyMMdd')),
        data_final: Number(format(new Date(final), 'yyyyMMdd')),
        maturador: [],
        enabled: !!selectedBem && isEmpresasSuccess && isOrderSuccess,
    })

    const lotes = useMemo(() => {
        const lotes: Record<string, MonitorMaturacaoType> = {}

        for (const row of data.filter(
            (val) => val.bem_abreviatura === selectedBem
        )) {
            if (!lotes[row.cod_lote_de_controle_de_qualidade]) {
                lotes[row.cod_lote_de_controle_de_qualidade] = row
            }
        }
        return Object.values(lotes)
    }, [data, selectedBem])

    const bens = useMemo(() => {
        const bens: Record<string, MonitorMaturacaoType> = {}

        for (const row of data) {
            if (!bens[row.bem_abreviatura]) {
                bens[row.bem_abreviatura] = row
            }
        }

        return Object.values(bens).sort((a, b) =>
            a.bem_abreviatura.localeCompare(b.bem_abreviatura)
        )
    }, [data])

    useEffect(() => {
        setLote(undefined)
    }, [selectedBem])

    const { isFullscreen, onToggleFullScreen } = useFullscreen({
        element: mainContainerRef.current,
    })

    const onSearch = ({ from, to }: OnSearchProps) => {
        if (isBefore(to, from)) {
            return toast.error(
                'A data final não pode ser anterior à data inicial'
            )
        }

        if (!isBetween(new Date(to), new Date(from), 60)) {
            return toast.warning(
                'Intervalo de datas deve ser de no máximo 60 dias'
            )
        }

        setDateInterval({
            initial: from.toDateString(),
            final: to.toDateString(),
        })
    }

    return (
        <PageContainer ref={mainContainerRef} className="p-4 overflow-auto">
            <div className="flex justify-between pb-2">
                <div className="flex items-center gap-2">
                    <CompanySelect
                        companies={empresas}
                        value={selectedCompany?.toString()}
                        onChange={(value) => setCompany(Number(value))}
                    />
                    <LinhasSelect
                        data={bens}
                        onChange={setBem}
                        selectedId={selectedBem}
                    />
                    <LotesSelect
                        onChange={setLote}
                        data={lotes}
                        selectedId={selectedLote}
                    />
                </div>
                <div className="flex items-center gap-2">
                    <DateFilter
                        final={final}
                        initial={initial}
                        onSearch={onSearch}
                    />
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={() => refetch()}
                        tooltipContent={'Atualizar dados'}
                    >
                        <RefreshCcw size={14} />
                    </ButtonWithTooltip>
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={onToggleFullScreen}
                        tooltipContent={
                            isFullscreen ? 'Minimizar' : 'Maximizar'
                        }
                    >
                        {isFullscreen ? (
                            <Minimize size={14} />
                        ) : (
                            <Maximize size={14} />
                        )}
                    </ButtonWithTooltip>
                </div>
            </div>
            <div className="ml-auto">
                {dataUpdatedAt !== 0 && (
                    <TypographyMutedXs>
                        Última atualização:{' '}
                        {format(
                            new Date(dataUpdatedAt),
                            'dd/MM/yyyy, HH:mm:ss'
                        )}
                    </TypographyMutedXs>
                )}
            </div>
            <div className="flex-1 py-2 overflow-hidden">
                <AnimatePresence>
                    {isPending ? (
                        <TopMessage
                            variant="loading"
                            text="Carregando dados..."
                        />
                    ) : null}
                    {!isPending && isFetching ? (
                        <TopMessage
                            variant="success"
                            text="Atualizando dados..."
                        />
                    ) : null}
                    {isError && (
                        <TopMessage
                            variant="error"
                            text="Erro ao buscar dados"
                        />
                    )}
                </AnimatePresence>
                <MaturacaoTable
                    data={data}
                    lote={selectedLote}
                    bem={selectedBem}
                    order={order}
                />
            </div>
        </PageContainer>
    )
}

export default MonitorMaturacao
