import { useMonitorFiltracaoQuery } from '@/queries/useMonitorFiltracaoQuery'
import { format, isBefore } from 'date-fns'
import { useRef } from 'react'
import { TypographyMutedXs } from '@/components/ui/typography'
import CompanySelect from '../../components/CompanySelect'
import { Maximize, Minimize, RefreshCcw } from 'lucide-react'
import TopMessage from '@/components/TopMessage'
import PageContainer from '@/components/PageContainer'
import FiltracaoTable from './components/FiltracaoTable'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
import ButtonWithTooltip from '@/components/ButtonWithTooltip'
import { AnimatePresence } from 'framer-motion'
import { useEmpresaQuery } from '@/queries/useEmpresaQuery'
import { useFullscreen } from '@/hooks/useFullscreen'
import { toast } from 'sonner'
import { isBetween } from '@/utils/date'
import { useSequenciaEnsaiosQuery } from '@/queries/useSequenciaEnsaiosQuery'
import DateFilter, { OnSearchProps } from '@/components/DateFilter'

const stateSelector = (state: Store) => ({
    selectedCompany: state.monitorFiltracaoSlice.state.selectedCompany,
    date: state.monitorFiltracaoSlice.state.date,
    selectedSala: state.monitorFiltracaoSlice.state.selectedSala,
    setSala: state.monitorFiltracaoSlice.actions.setSala,
    setCompany: state.monitorFiltracaoSlice.actions.setCompany,
    setDateInterval: state.monitorFiltracaoSlice.actions.setDateInterval,
    resetDate: state.monitorFiltracaoSlice.actions.resetDate,
})

const MonitorFiltracao = () => {
    const mainContainerRef = useRef<HTMLDivElement | null>(null)

    const {
        selectedCompany,
        date: { initial, final },
        setDateInterval,
        setCompany,
    } = useBaseStore(stateSelector)

    const { data: empresas = [], isSuccess: isEmpresasSuccess } =
        useEmpresaQuery()

    const { data: order = {}, isSuccess: isOrderSuccess } =
        useSequenciaEnsaiosQuery({
            cod_filial: Number(selectedCompany),
            monitor: 'FILTRAÇÃO',
            enabled: !!selectedCompany,
        })

    const { data, isPending, isFetching, isError, dataUpdatedAt, refetch } =
        useMonitorFiltracaoQuery({
            cod_filial: selectedCompany!,
            data_inicial: Number(format(new Date(initial), 'yyyyMMdd')),
            data_final: Number(format(new Date(final), 'yyyyMMdd')),
            enabled: empresas.length > 0 && isEmpresasSuccess && isOrderSuccess,
        })

    const { isFullscreen, onToggleFullScreen } = useFullscreen({
        element: mainContainerRef.current,
    })

    const onSearch = ({ from, to }: OnSearchProps) => {
        if (isBefore(to, from)) {
            return toast.error(
                'A data final não pode ser anterior à data inicial'
            )
        }

        if (!isBetween(new Date(to), new Date(from), 60)) {
            return toast.warning(
                'Intervalo de datas deve ser de no máximo 60 dias'
            )
        }

        setDateInterval({
            initial: from.toDateString(),
            final: to.toDateString(),
        })
    }

    return (
        <PageContainer ref={mainContainerRef} className="p-4 overflow-auto">
            <div className="flex justify-between pb-2">
                <CompanySelect
                    companies={empresas}
                    value={selectedCompany}
                    onChange={setCompany}
                />
                <div className="flex items-center gap-2">
                    <DateFilter
                        final={final}
                        initial={initial}
                        onSearch={onSearch}
                    />
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={() => refetch()}
                        tooltipContent={'Atualizar dados'}
                    >
                        <RefreshCcw size={14} />
                    </ButtonWithTooltip>
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={onToggleFullScreen}
                        tooltipContent={
                            isFullscreen ? 'Minimizar' : 'Maximizar'
                        }
                    >
                        {isFullscreen ? (
                            <Minimize size={14} />
                        ) : (
                            <Maximize size={14} />
                        )}
                    </ButtonWithTooltip>
                </div>
            </div>
            <div className="ml-auto">
                {dataUpdatedAt !== 0 && (
                    <TypographyMutedXs>
                        Última atualização:{' '}
                        {format(
                            new Date(dataUpdatedAt),
                            'dd/MM/yyyy, HH:mm:ss'
                        )}
                    </TypographyMutedXs>
                )}
            </div>
            <div className="flex-1 py-2 overflow-hidden">
                <AnimatePresence>
                    {!data && isPending ? (
                        <TopMessage
                            variant="loading"
                            text="Carregando dados..."
                        />
                    ) : null}
                    {!isPending && isFetching ? (
                        <TopMessage
                            variant="success"
                            text="Atualizando dados..."
                        />
                    ) : null}
                    {isError && (
                        <TopMessage
                            variant="error"
                            text="Erro ao buscar dados"
                        />
                    )}
                </AnimatePresence>
                <FiltracaoTable data={data} order={order} />
            </div>
        </PageContainer>
    )
}

export default MonitorFiltracao
