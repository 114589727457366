import { useState } from 'react'
import { DatePicker } from '../DatePicker'
import { Button } from '../ui/button'
import { CalendarX2, Search } from 'lucide-react'
import ButtonWithTooltip from '../ButtonWithTooltip'

export type OnSearchProps = {
    from: Date
    to: Date
}

type DateFilterProps = {
    onSearch: ({ from, to }: OnSearchProps) => void
    initial: string
    final?: string
}

const DateFilter = ({ final, initial, onSearch }: DateFilterProps) => {
    const [{ from, to }, setDate] = useState({
        from: new Date(initial) || new Date(),
        to: new Date(final ?? initial) || new Date(),
    })

    const onChangeDate = (date: Date, type: 'from' | 'to') => {
        setDate((prev) => ({
            ...prev,
            [type]: date,
        }))
    }

    const onResetDate = () => {
        setDate({
            from: new Date(),
            to: new Date(),
        })
    }

    return (
        <>
            <div className="flex items-center gap-2">
                <DatePicker
                    className="w-[160px]"
                    date={from}
                    setDate={(date) => {
                        if (date) {
                            onChangeDate(date, 'from')
                        }
                    }}
                />
                {final && (
                    <DatePicker
                        className="w-[160px]"
                        date={to}
                        setDate={(date) => {
                            if (date) {
                                onChangeDate(date, 'to')
                            }
                        }}
                    />
                )}
                <Button
                    variant="outline"
                    size="icon"
                    onClick={() => onSearch({ from, to })}
                >
                    <Search
                        size={16}
                        className="text-primary"
                        strokeWidth={3}
                    />
                </Button>
            </div>
            <ButtonWithTooltip
                variant="ghost"
                size="icon"
                onClick={() => onResetDate()}
                tooltipContent={'Resetar data'}
            >
                <CalendarX2 size={14} />
            </ButtonWithTooltip>
        </>
    )
}

export default DateFilter
