import instance from '..'
import { FetchSequenciaMonitorProps, SequenciaMonitor } from './type'

export const fetchSequenciaMonitor = async (
    props: FetchSequenciaMonitorProps
) => {
    const response = await instance.post<SequenciaMonitor[]>(
        '/monitor/sequenciaensaios',
        {
            ...props,
        }
    )

    return response.data
}
