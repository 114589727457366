import { ImmerStateCreator } from '../type'
import { MonitorCipEnvasamentoSlice, MonitorCipEnvasamentoState } from './type'
import { getStorageState, persistState } from '@/utils/storage'

const initialState: MonitorCipEnvasamentoState = {
    selectedCompany: undefined,
    selectedLab: undefined,
    selectedLaudos: {},
    selectedLinhas: {},
    date: {
        initial: new Date().toISOString(),
        final: new Date().toISOString(),
    },
}

const key = 'monitor-cip-envasamento'

const persist = (state: MonitorCipEnvasamentoState) => {
    persistState({ key, state })
}

export const useMonitorCipEnvasamentoSlice: ImmerStateCreator<
    MonitorCipEnvasamentoSlice
> = (set) => ({
    state: getStorageState({
        key,
        state: initialState,
    }),
    actions: {
        setCompany: (company) => {
            set((state) => {
                state.monitorCipEnvasamentoSlice.state.selectedCompany = company

                persist(state.monitorCipEnvasamentoSlice.state)
            })
        },
        setDateInterval: ({ final, initial }) => {
            set((state) => {
                state.monitorCipEnvasamentoSlice.state.date.initial = initial
                state.monitorCipEnvasamentoSlice.state.date.final = final

                persist(state.monitorCipEnvasamentoSlice.state)
            })
        },
        setLab: (lab) => {
            set((state) => {
                state.monitorCipEnvasamentoSlice.state.selectedLab = lab

                persist(state.monitorCipEnvasamentoSlice.state)
            })
        },
        onSelectLaudo: (value) => {
            set((state) => {
                state.monitorCipEnvasamentoSlice.state.selectedLaudos[value] =
                    !state.monitorCipEnvasamentoSlice.state.selectedLaudos[
                        value
                    ]

                persist(state.monitorCipEnvasamentoSlice.state)
            })
        },
        onClearLaudo: () => {
            set((state) => {
                state.monitorCipEnvasamentoSlice.state.selectedLaudos = {}

                persist(state.monitorCipEnvasamentoSlice.state)
            })
        },
        onSelectLinha: (value) => {
            set((state) => {
                state.monitorCipEnvasamentoSlice.state.selectedLinhas[value] =
                    !state.monitorCipEnvasamentoSlice.state.selectedLinhas[
                        value
                    ]

                persist(state.monitorCipEnvasamentoSlice.state)
            })
        },
        onClearLinha: () => {
            set((state) => {
                state.monitorCipEnvasamentoSlice.state.selectedLinhas = {}

                persist(state.monitorCipEnvasamentoSlice.state)
            })
        },
        resetDate: () => {
            set((state) => {
                state.monitorCipEnvasamentoSlice.state.date = {
                    final: new Date().toString(),
                    initial: new Date().toString(),
                }
            })
        },
        onReset: () => {
            set((state) => {
                state.monitorCipEnvasamentoSlice.state = initialState
            })
        },
    },
})
