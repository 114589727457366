import { MonitorFermentacao } from '@/api/business/fermentacao/type'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { TypographyXS } from '@/components/ui/typography'

type LinhasSelectProps = {
    data: MonitorFermentacao[]
    selectedId?: string
    onChange: (value: string) => void
}

const LinhasSelect = ({ data, selectedId, onChange }: LinhasSelectProps) => {
    return (
        <Select value={selectedId} onValueChange={onChange}>
            <SelectTrigger className="hover:bg-muted h-[56px] min-w-[200px]">
                <SelectValue
                    className="text-start"
                    placeholder="Selecione um tanque"
                />
            </SelectTrigger>
            <SelectContent>
                {data?.length === 0 ? (
                    <SelectGroup>
                        <SelectItem value="sem_dados" disabled>
                            <TypographyXS className="text-start">
                                Não há linha disponível
                            </TypographyXS>
                        </SelectItem>
                    </SelectGroup>
                ) : (
                    <SelectGroup>
                        {data.map((value) => {
                            return (
                                <SelectItem
                                    key={value.bem_abreviatura}
                                    value={value.bem_abreviatura.toString()}
                                >
                                    <div className="flex flex-col p-1 space-y-1">
                                        <TypographyXS className="text-start">
                                            {value.bem_abreviatura}
                                        </TypographyXS>
                                    </div>
                                </SelectItem>
                            )
                        })}
                    </SelectGroup>
                )}
            </SelectContent>
        </Select>
    )
}

export default LinhasSelect
