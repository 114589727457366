import {
    Beer,
    Biohazard,
    Clock4,
    CookingPot,
    Droplet,
    Factory,
    Filter,
    Lock,
    LogIn,
    LucideIcon,
    PocketKnife,
    ShieldBan,
    ThermometerSun,
    Waypoints,
} from 'lucide-react'
import { IconType } from 'react-icons/lib'
import { TbChartBubble } from 'react-icons/tb'
import { PiBeerBottle } from 'react-icons/pi'
import { MdCleanHands } from 'react-icons/md'

const routes = {
    PROCESSO: 'Processo',
    FILTRACAO: 'Filtração',
    LOGIN: 'Login',
    PRODUTO_ACABADO: 'Produto acabado',
    ENVASAMENTO: 'Envasamento',
    RASTREABILIDADE: 'Rastreabilidade',
    RASTREABILIDADE_PAI: 'Rastreabilidade pai',
    RECRAVACAO: 'Recravação',
    ENVASE: 'Envase',
    PASTEURIZACAO: 'Pasteurizador',
    LUBRIFICANTE_DE_ESTEIRA: 'Lubrificante de esteira',
    NOT_FOUND: 'Not Found',
    ARROLHAMENTO: 'Arrolhamento',
    MATURACAO: 'Maturação',
    FERMENTACAO: 'Fermentação',
    FERMENTACAO_DIARIO: 'Fermentação diário',
    FERMENTACAO_PROPAGACAO: 'Propagação',
    FERMENTACAO_PROPAGACAO_DIARIO: 'Propagação diário',
    HOME: 'Home',
    BRASSAGEM: 'Brassagem',
    RECOVER_PASSWORD: 'Recuperar senha',
    MICROBIOLOGIA: 'Microbiologia',
    ESTABILIDADE_BIOLOGICA: 'Estabilidade biológica',
    CIP: 'CIP',
    CIP_ENVASAMENTO: 'CIP_ENVASAMENTO',
} as const

export type Route = {
    index: string
    label: (typeof routes)[keyof typeof routes]
    icon: LucideIcon | IconType | null
    shouldShowOnSidenav: boolean
    getParentRoute: () => string
    getRoutePath: () => string
}

export const ROUTES: Record<keyof typeof routes, Route> = {
    HOME: {
        index: '/',
        label: 'Home',
        icon: Factory,
        shouldShowOnSidenav: false,
        getParentRoute: () => ROUTES.PROCESSO.index,
        getRoutePath: () => ROUTES.PROCESSO.index,
    },
    BRASSAGEM: {
        index: 'brassagem',
        label: 'Brassagem',
        icon: Factory,
        shouldShowOnSidenav: true,
        getParentRoute: () => ROUTES.PROCESSO.index,
        getRoutePath: () =>
            ROUTES.BRASSAGEM.getParentRoute() + '/' + ROUTES.BRASSAGEM.index,
    },
    FILTRACAO: {
        index: 'filtracao',
        label: 'Filtração',
        icon: Filter,
        shouldShowOnSidenav: true,
        getParentRoute: () => ROUTES.PROCESSO.index,
        getRoutePath: () =>
            ROUTES.FILTRACAO.getParentRoute() + '/' + ROUTES.FILTRACAO.index,
    },
    PRODUTO_ACABADO: {
        index: 'produto-acabado',
        label: 'Produto acabado',
        icon: Beer,
        shouldShowOnSidenav: true,
        getParentRoute: () => ROUTES.ENVASE.index,
        getRoutePath: () =>
            ROUTES.PRODUTO_ACABADO.getParentRoute() +
            '/' +
            ROUTES.PRODUTO_ACABADO.index,
    },
    ENVASAMENTO: {
        index: 'envasamento',
        label: 'Envasamento',
        icon: PiBeerBottle,
        shouldShowOnSidenav: true,
        getParentRoute: () => ROUTES.ENVASE.index,
        getRoutePath: () =>
            ROUTES.ENVASAMENTO.getParentRoute() +
            '/' +
            ROUTES.ENVASAMENTO.index,
    },
    RASTREABILIDADE_PAI: {
        index: '/',
        label: 'Rastreabilidade',
        icon: null,
        shouldShowOnSidenav: true,
        getParentRoute: () => '',
        getRoutePath: () => ROUTES.RASTREABILIDADE.index,
    },
    RASTREABILIDADE: {
        index: '/rastreabilidade',
        label: 'Rastreabilidade',
        icon: Waypoints,
        shouldShowOnSidenav: true,
        getParentRoute: () => ROUTES.RASTREABILIDADE_PAI.index,
        getRoutePath: () => ROUTES.RASTREABILIDADE.index,
    },
    RECRAVACAO: {
        index: 'recravacao',
        label: 'Recravação',
        icon: CookingPot,
        shouldShowOnSidenav: true,
        getParentRoute: () => ROUTES.ENVASE.index,
        getRoutePath: () =>
            ROUTES.RECRAVACAO.getParentRoute() + '/' + ROUTES.RECRAVACAO.index,
    },
    PASTEURIZACAO: {
        index: 'pasteurizacao',
        label: 'Pasteurizador',
        icon: ThermometerSun,
        shouldShowOnSidenav: true,
        getParentRoute: () => ROUTES.ENVASE.index,
        getRoutePath: () =>
            ROUTES.PASTEURIZACAO.getParentRoute() +
            '/' +
            ROUTES.PASTEURIZACAO.index,
    },
    ARROLHAMENTO: {
        index: 'arrolhamento',
        label: 'Arrolhamento',
        icon: PocketKnife,
        shouldShowOnSidenav: true,
        getParentRoute: () => ROUTES.ENVASE.index,
        getRoutePath: () =>
            ROUTES.ARROLHAMENTO.getParentRoute() +
            '/' +
            ROUTES.ARROLHAMENTO.index,
    },
    LUBRIFICANTE_DE_ESTEIRA: {
        index: 'lubrificante-de-esteira',
        label: 'Lubrificante de esteira',
        icon: Droplet,
        shouldShowOnSidenav: true,
        getParentRoute: () => ROUTES.ENVASE.index,
        getRoutePath: () =>
            ROUTES.LUBRIFICANTE_DE_ESTEIRA.getParentRoute() +
            '/' +
            ROUTES.LUBRIFICANTE_DE_ESTEIRA.index,
    },
    MATURACAO: {
        index: 'maturacao',
        label: 'Maturação',
        icon: Clock4,
        shouldShowOnSidenav: true,
        getParentRoute: () => ROUTES.PROCESSO.index,
        getRoutePath: () =>
            ROUTES.MATURACAO.getParentRoute() + '/' + ROUTES.MATURACAO.index,
    },
    FERMENTACAO: {
        index: 'fermentacao',
        label: 'Fermentação',
        icon: TbChartBubble,
        shouldShowOnSidenav: true,
        getParentRoute: () => ROUTES.PROCESSO.index,
        getRoutePath: () =>
            ROUTES.FERMENTACAO.getParentRoute() +
            '/' +
            ROUTES.FERMENTACAO.index,
    },
    FERMENTACAO_DIARIO: {
        index: 'diario',
        label: 'Fermentação diário',
        icon: TbChartBubble,
        shouldShowOnSidenav: false,
        getParentRoute: () => ROUTES.FERMENTACAO.getRoutePath(),
        getRoutePath: () =>
            ROUTES.FERMENTACAO_DIARIO.getParentRoute() +
            '/' +
            ROUTES.FERMENTACAO_DIARIO.index,
    },
    FERMENTACAO_PROPAGACAO: {
        index: 'propagacao',
        label: 'Propagação',
        icon: TbChartBubble,
        shouldShowOnSidenav: false,
        getParentRoute: () => ROUTES.FERMENTACAO.getRoutePath(),
        getRoutePath: () =>
            ROUTES.FERMENTACAO_PROPAGACAO.getParentRoute() +
            '/' +
            ROUTES.FERMENTACAO_PROPAGACAO.index,
    },
    FERMENTACAO_PROPAGACAO_DIARIO: {
        index: 'diario',
        label: 'Propagação diário',
        icon: TbChartBubble,
        shouldShowOnSidenav: false,
        getParentRoute: () => ROUTES.FERMENTACAO_PROPAGACAO.getRoutePath(),
        getRoutePath: () =>
            ROUTES.FERMENTACAO_PROPAGACAO_DIARIO.getParentRoute() +
            '/' +
            ROUTES.FERMENTACAO_PROPAGACAO_DIARIO.index,
    },
    ENVASE: {
        index: '/envase',
        label: 'Envase',
        icon: null,
        shouldShowOnSidenav: true,
        getParentRoute: () => '',
        getRoutePath: () => ROUTES.ENVASE.index,
    },
    PROCESSO: {
        index: '/processo',
        label: 'Processo',
        icon: null,
        shouldShowOnSidenav: true,
        getParentRoute: () => '',
        getRoutePath: () => ROUTES.PROCESSO.index,
    },
    MICROBIOLOGIA: {
        index: '/microbiologia',
        label: 'Microbiologia',
        icon: null,
        shouldShowOnSidenav: true,
        getParentRoute: () => '',
        getRoutePath: () => ROUTES.MICROBIOLOGIA.index,
    },
    ESTABILIDADE_BIOLOGICA: {
        index: 'estabilidade-biologica',
        label: 'Estabilidade biológica',
        icon: Biohazard,
        shouldShowOnSidenav: true,
        getParentRoute: () => ROUTES.MICROBIOLOGIA.getRoutePath(),
        getRoutePath: () =>
            ROUTES.ESTABILIDADE_BIOLOGICA.getParentRoute() +
            '/' +
            ROUTES.ESTABILIDADE_BIOLOGICA.index,
    },
    CIP: {
        index: '/cip',
        label: 'CIP',
        icon: null,
        shouldShowOnSidenav: true,
        getParentRoute: () => '',
        getRoutePath: () => ROUTES.CIP.index,
    },
    CIP_ENVASAMENTO: {
        index: 'envasamento',
        label: 'Envasamento',
        icon: MdCleanHands,
        shouldShowOnSidenav: true,
        getParentRoute: () => ROUTES.CIP.getRoutePath(),
        getRoutePath: () =>
            ROUTES.CIP_ENVASAMENTO.getParentRoute() +
            '/' +
            ROUTES.CIP_ENVASAMENTO.index,
    },
    LOGIN: {
        index: 'login',
        label: 'Login',
        icon: LogIn,
        shouldShowOnSidenav: false,
        getParentRoute: () => '',
        getRoutePath: () => ROUTES.LOGIN.index,
    },
    RECOVER_PASSWORD: {
        index: 'recuperar-senha',
        label: 'Recuperar senha',
        icon: Lock,
        shouldShowOnSidenav: false,
        getParentRoute: () => '',
        getRoutePath: () => ROUTES.RECOVER_PASSWORD.index,
    },
    NOT_FOUND: {
        index: '*',
        label: 'Not Found',
        icon: ShieldBan,
        shouldShowOnSidenav: false,
        getParentRoute: () => '',
        getRoutePath: () => ROUTES.NOT_FOUND.index,
    },
}
