import {
    fetchMonitorEstabilidadeBiologica,
    fetchMonitorEstabilidadeBiologicaFiltros,
} from '@/api/business/estabilidadeBiologica'
import {
    FetchMonitorEstabilidadeBiologicaFiltrosProps,
    FetchMonitorEstabilidadeBiologicaProps,
} from '@/api/business/estabilidadeBiologica/type'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export const monitorEstabilidadeBiologicaKeys = {
    all: ['monitorEstabilidadeBiologica'] as const,
    lists: () => [...monitorEstabilidadeBiologicaKeys.all, 'list'] as const,
    list: ({
        data_final,
        data_inicial,
        cod_laboratorio,
    }: FetchMonitorEstabilidadeBiologicaProps) =>
        [
            ...monitorEstabilidadeBiologicaKeys.lists(),
            data_final,
            data_inicial,
            cod_laboratorio,
        ] as const,
    filtros: ({ cod_filial }: FetchMonitorEstabilidadeBiologicaFiltrosProps) =>
        [
            ...monitorEstabilidadeBiologicaKeys.all,
            'filtros',
            cod_filial,
        ] as const,
}

export type UseMonitorEstabilidadeBiologicaQueryProps =
    FetchMonitorEstabilidadeBiologicaProps & {
        enabled: boolean
    }

export type UseMonitorEstabilidadeBiologicaFiltrosQueryProps =
    FetchMonitorEstabilidadeBiologicaFiltrosProps & {
        enabled: boolean
    }

export const useMonitorEstabilidadeBiologicaQuery = ({
    enabled,
    ...props
}: UseMonitorEstabilidadeBiologicaQueryProps) => {
    return useQuery({
        queryKey: monitorEstabilidadeBiologicaKeys.list({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        queryFn: () => fetchMonitorEstabilidadeBiologica({ ...props }),
        placeholderData: keepPreviousData,
        select: (data) => data.filter((value) => value.resultados.length > 0),
    })
}

export const useMonitorEstabilidadeBiologicaFiltrosQuery = ({
    enabled,
    ...props
}: UseMonitorEstabilidadeBiologicaFiltrosQueryProps) => {
    return useQuery({
        queryKey: monitorEstabilidadeBiologicaKeys.filtros({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        queryFn: () => fetchMonitorEstabilidadeBiologicaFiltros({ ...props }),
        placeholderData: keepPreviousData,
    })
}
