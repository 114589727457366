import { fetchSequenciaMonitor } from '@/api/business/sequenciamento'
import { FetchSequenciaMonitorProps } from '@/api/business/sequenciamento/type'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export const sequenciaEnsaiosKeys = {
    all: ['sequenciaEnsaios'] as const,
    list: (monitor: string) =>
        [...sequenciaEnsaiosKeys.all, monitor, 'list'] as const,
}

export type UseSequenciaEnsaiosQueryProps = FetchSequenciaMonitorProps & {
    enabled?: boolean
}

export const useSequenciaEnsaiosQuery = ({
    cod_filial,
    monitor,
    enabled = true,
}: UseSequenciaEnsaiosQueryProps) => {
    return useQuery({
        queryKey: sequenciaEnsaiosKeys.list(monitor),
        enabled,
        queryFn: () =>
            fetchSequenciaMonitor({
                cod_filial,
                monitor,
            }),
        placeholderData: keepPreviousData,
        select: (data) => {
            return data.reduce((acc, curr) => {
                return {
                    ...acc,
                    [curr.cod_ensaio]: curr.ordenacao,
                }
            }, {} as Record<number, number>)
        },
    })
}
