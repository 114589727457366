import {
    useMonitorPasteurizacaoFiltrosQuery,
    useMonitorPasteurizacaoQuery,
} from '@/queries/useMonitorPasteurizacaoQuery'
import { format, isBefore } from 'date-fns'
import { useRef } from 'react'
import { TypographyMutedXs } from '@/components/ui/typography'
import CompanySelect from '../../components/CompanySelect'
import { Maximize, Minimize, RefreshCcw } from 'lucide-react'
import TopMessage from '@/components/TopMessage'
import PageContainer from '@/components/PageContainer'
import PasteurizacaoTable from './components/PasteurizacaoTable'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
import ButtonWithTooltip from '@/components/ButtonWithTooltip'
import LinhasSelect from './components/LinhasSelect'
import { AnimatePresence } from 'framer-motion'
import { useEmpresaQuery } from '@/queries/useEmpresaQuery'
import { useFullscreen } from '@/hooks/useFullscreen'
import { toast } from 'sonner'
import { isBetween } from '@/utils/date'
import DateFilter, { OnSearchProps } from '@/components/DateFilter'

const stateSelector = (state: Store) => ({
    selectedCompany: state.monitorPasteurizacaoSlice.state.selectedCompany,
    date: state.monitorPasteurizacaoSlice.state.date,
    selectedSala: state.monitorPasteurizacaoSlice.state.selectedSala,
    setSala: state.monitorPasteurizacaoSlice.actions.setSala,
    setCompany: state.monitorPasteurizacaoSlice.actions.setCompany,
    setDateInterval: state.monitorPasteurizacaoSlice.actions.setDateInterval,
    resetDate: state.monitorPasteurizacaoSlice.actions.resetDate,
})

const MonitorPasteurizacao = () => {
    const mainContainerRef = useRef<HTMLDivElement | null>(null)

    const {
        selectedCompany,
        date: { initial, final },
        selectedSala,
        setDateInterval,
        setSala,
        setCompany,
    } = useBaseStore(stateSelector)

    const { data: empresas = [], isSuccess: isEmpresasSuccess } =
        useEmpresaQuery()

    const { data: filters, isSuccess: isFiltersSuccess } =
        useMonitorPasteurizacaoFiltrosQuery({
            cod_filial: selectedCompany!,
            enabled: empresas.length > 0 && isEmpresasSuccess,
        })

    const { data, isPending, isFetching, isError, dataUpdatedAt, refetch } =
        useMonitorPasteurizacaoQuery({
            cod_filial: selectedCompany!,
            data_inicial: Number(format(new Date(initial), 'yyyyMMdd')),
            data_final: Number(format(new Date(final), 'yyyyMMdd')),
            cod_bem: selectedSala.bem || 0,
            cod_centro_de_custo: selectedSala.sala || 0,
            enabled: isFiltersSuccess && filters.length > 0,
        })

    const { isFullscreen, onToggleFullScreen } = useFullscreen({
        element: mainContainerRef.current,
    })

    const onSearch = ({ from, to }: OnSearchProps) => {
        if (isBefore(to, from)) {
            return toast.error(
                'A data final não pode ser anterior à data inicial'
            )
        }

        if (!isBetween(new Date(to), new Date(from), 60)) {
            return toast.warning(
                'Intervalo de datas deve ser de no máximo 60 dias'
            )
        }

        setDateInterval({
            initial: from.toDateString(),
            final: to.toDateString(),
        })
    }

    return (
        <PageContainer ref={mainContainerRef} className="p-4 overflow-auto">
            <div className="flex justify-between pb-2">
                <div className="flex items-center gap-2">
                    <CompanySelect
                        companies={empresas}
                        value={selectedCompany}
                        onChange={setCompany}
                    />
                    <LinhasSelect
                        data={filters}
                        onChange={(value) => {
                            const found = filters?.find(
                                (filter) =>
                                    `${filter.cod_centro_de_custo}${filter.cod_bem}` ===
                                    value
                            )
                            if (found)
                                setSala({
                                    bem: found.cod_bem,
                                    sala: found.cod_centro_de_custo,
                                })
                        }}
                        selectedId={
                            selectedSala.bem && selectedSala.sala
                                ? `${selectedSala.sala}${selectedSala.bem}`
                                : ''
                        }
                    />
                </div>
                <div className="flex items-center gap-2">
                    <DateFilter
                        final={initial}
                        initial={final}
                        onSearch={onSearch}
                    />
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={() => refetch()}
                        tooltipContent={'Atualizar dados'}
                    >
                        <RefreshCcw size={14} />
                    </ButtonWithTooltip>
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={onToggleFullScreen}
                        tooltipContent={
                            isFullscreen ? 'Minimizar' : 'Maximizar'
                        }
                    >
                        {isFullscreen ? (
                            <Minimize size={14} />
                        ) : (
                            <Maximize size={14} />
                        )}
                    </ButtonWithTooltip>
                </div>
            </div>
            <div className="ml-auto">
                {dataUpdatedAt !== 0 && (
                    <TypographyMutedXs>
                        Última atualização:{' '}
                        {format(
                            new Date(dataUpdatedAt),
                            'dd/MM/yyyy, HH:mm:ss'
                        )}
                    </TypographyMutedXs>
                )}
            </div>
            <div className="flex-1 py-2 overflow-hidden">
                <AnimatePresence>
                    {!data && isPending ? (
                        <TopMessage
                            variant="loading"
                            text="Carregando dados..."
                        />
                    ) : null}
                    {!isPending && isFetching ? (
                        <TopMessage
                            variant="success"
                            text="Atualizando dados..."
                        />
                    ) : null}
                    {isError && (
                        <TopMessage
                            variant="error"
                            text="Erro ao buscar dados"
                        />
                    )}
                </AnimatePresence>
                <PasteurizacaoTable data={data} />
            </div>
        </PageContainer>
    )
}

export default MonitorPasteurizacao
