import { ImmerStateCreator, Store } from '@/store/type'
import { AppConfigSlice, AppConfigState } from '@/store/appConfigSlice/type'
import { getStorageState, persistState } from '@/utils/storage'

const initialState: AppConfigState = {
    isSidenavOpen: false,
    isMobile: window.innerWidth < 640,
    isChangePasswordDialogOpen: false,
    sidenavItemsToggleState: {},
}

const key = 'app-state'

const persist = (state: AppConfigState) => {
    persistState({
        key,
        state,
        omit: ['isChangePasswordDialogOpen', 'isMobile', 'isSidenavOpen'],
    })
}

export const useAppConfigSlice: ImmerStateCreator<AppConfigSlice> = (set) => ({
    state: getStorageState({ state: initialState, key }),
    actions: {
        setSidenavOpen: (isOpen) =>
            set((state) => {
                state.appConfigSlice.state.isSidenavOpen = isOpen
            }),
        setInitialSidenavState: (newState, replace = false) => {
            set((state) => {
                if (
                    replace ||
                    Object.keys(
                        state.appConfigSlice.state.sidenavItemsToggleState
                    ).length === 0
                ) {
                    state.appConfigSlice.state.sidenavItemsToggleState =
                        newState
                }
            })
        },
        setIsMoble: (isMobile) =>
            set((state) => {
                state.appConfigSlice.state.isMobile = isMobile
            }),
        onChangePasswordDialogClose: () =>
            set((state) => {
                state.appConfigSlice.state.isChangePasswordDialogOpen = false
            }),
        onChangePasswordDialogOpen: () =>
            set((state) => {
                state.appConfigSlice.state.isChangePasswordDialogOpen = true
            }),
        onToggleSidenavItemState: (key) =>
            set((state) => {
                state.appConfigSlice.state.sidenavItemsToggleState[key] =
                    !state.appConfigSlice.state.sidenavItemsToggleState[key]

                persist(state.appConfigSlice.state)
            }),
        onReset: () =>
            set((state) => {
                state.appConfigSlice.state = initialState
            }),
    },
})

export const appConfigStateSelector = (state: Store) =>
    state.appConfigSlice.state

export const appConfigActionsSelector = (state: Store) =>
    state.appConfigSlice.actions
