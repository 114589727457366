import { ImmerStateCreator } from '../type'
import { MonitorRecravacaoSlice, MonitorRecravacaoState } from './type'
import { getStorageState, persistState } from '@/utils/storage'

const initialState: MonitorRecravacaoState = {
    selectedCompany: undefined,
    selectedSala: { bem: undefined, sala: undefined },
    date: {
        initial: new Date().toISOString(),
        final: new Date().toISOString(),
    },
}

const key = 'monitor-recravacao'

const persist = (state: MonitorRecravacaoState) => {
    persistState({ key, state })
}

export const useMonitorRecravacaoSlice: ImmerStateCreator<
    MonitorRecravacaoSlice
> = (set) => ({
    state: getStorageState({
        key,
        state: initialState,
    }),
    actions: {
        setCompany: (company) => {
            set((state) => {
                state.monitorRecravacaoSlice.state.selectedCompany = company

                persist(state.monitorRecravacaoSlice.state)
            })
        },
        setDateInterval: ({ final, initial }) => {
            set((state) => {
                state.monitorRecravacaoSlice.state.date.initial = initial
                state.monitorRecravacaoSlice.state.date.final = final

                persist(state.monitorRecravacaoSlice.state)
            })
        },
        setSala: (sala) => {
            set((state) => {
                state.monitorRecravacaoSlice.state.selectedSala = sala

                persist(state.monitorRecravacaoSlice.state)
            })
        },
        resetDate: () => {
            set((state) => {
                state.monitorRecravacaoSlice.state.date = {
                    final: new Date().toString(),
                    initial: new Date().toString(),
                }

                persist(state.monitorRecravacaoSlice.state)
            })
        },
        onReset: () => {
            set((state) => {
                state.monitorRecravacaoSlice.state = initialState
            })
        },
    },
})
