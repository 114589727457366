import { ImmerStateCreator } from '../type'
import {
    MonitorFermentacaoPropagacaoDiarioSlice,
    MonitorFermentacaoPropagacaoDiarioState,
} from './type'
import { getStorageState, persistState } from '@/utils/storage'

const initialState: MonitorFermentacaoPropagacaoDiarioState = {
    selectedCompany: undefined,
    selectedLote: undefined,
    selectedProduto: undefined,
    selectedSala: { bem: undefined },
    date: {
        initial: new Date().toISOString(),
        final: new Date().toISOString(),
    },
}

const key = 'monitor-fermentacao-propagacao-diario'

const persist = (state: MonitorFermentacaoPropagacaoDiarioState) => {
    persistState({
        key,
        state,
        omit: ['selectedLote'],
    })
}

export const useMonitorFermentacaoPropagacaoDiarioSlice: ImmerStateCreator<
    MonitorFermentacaoPropagacaoDiarioSlice
> = (set) => ({
    state: getStorageState({
        key,
        state: initialState,
    }),
    actions: {
        setCompany: (company) => {
            set((state) => {
                state.monitorFermentacaoPropagacaoDiarioSlice.state.selectedCompany =
                    company

                persist(state.monitorFermentacaoPropagacaoDiarioSlice.state)
            })
        },
        setDateInterval: ({ final, initial }) => {
            set((state) => {
                state.monitorFermentacaoPropagacaoDiarioSlice.state.date.initial =
                    initial
                state.monitorFermentacaoPropagacaoDiarioSlice.state.date.final =
                    final

                persist(state.monitorFermentacaoPropagacaoDiarioSlice.state)
            })
        },
        setSala: (sala) => {
            set((state) => {
                state.monitorFermentacaoPropagacaoDiarioSlice.state.selectedSala =
                    sala

                persist(state.monitorFermentacaoPropagacaoDiarioSlice.state)
            })
        },
        setLote: (lote) => {
            set((state) => {
                state.monitorFermentacaoPropagacaoDiarioSlice.state.selectedLote =
                    lote
            })
        },
        setProduto: (produto) => {
            set((state) => {
                state.monitorFermentacaoPropagacaoDiarioSlice.state.selectedProduto =
                    produto
            })
        },
        resetDate: () => {
            set((state) => {
                state.monitorFermentacaoPropagacaoDiarioSlice.state.date = {
                    final: new Date().toString(),
                    initial: new Date().toString(),
                }

                persist(state.monitorFermentacaoPropagacaoDiarioSlice.state)
            })
        },
        onReset: () => {
            set((state) => {
                state.monitorFermentacaoPropagacaoDiarioSlice.state =
                    initialState
            })
        },
    },
})
