import { cn } from '@/lib/utils'
import { HTMLAttributes, forwardRef } from 'react'

type PageContainerProps = HTMLAttributes<HTMLDivElement>

const PageContainer = forwardRef<HTMLDivElement, PageContainerProps>(
    ({ children, className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                {...props}
                className={cn(
                    'flex flex-col bg-background flex-1 overflow-auto relative',
                    className
                )}
            >
                {children}
            </div>
        )
    }
)

export default PageContainer
