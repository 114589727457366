import { Menu, Search, X } from 'lucide-react'
import { Sheet, SheetContent, SheetHeader, SheetTrigger } from '../ui/sheet'
import Icon from '@/assets/icons/icon.png'
import { TypographyH3, TypographyMutedXs, TypographyP } from '../ui/typography'
import { ROUTES } from '@/consts/routes'
import { useEffect, useMemo, useState } from 'react'
import useDisclosure from '@/hooks/useDisclosure'
import { ScrollArea } from '../ui/scroll-area'
import { DebouncedInput } from '../DebouncedInput'
import LinkSection from './components/LinkSection'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'

const stateSelector = (state: Store) => ({
    setInitial: state.appConfigSlice.actions.setInitialSidenavState,
})

const Sidenav = () => {
    const { onOpenChange, isOpen } = useDisclosure()
    const { setInitial } = useBaseStore(stateSelector)

    const [inputValue, setInputValue] = useState('')
    const [shouldAnimate, setShouldAnimate] = useState(false)

    const routes = useMemo(
        () =>
            Object.values(ROUTES)
                .filter((route) => route.shouldShowOnSidenav)
                .sort((a, b) => a.label.localeCompare(b.label)),
        []
    )

    const rootRoutes = routes.filter((route) => !route.getParentRoute())

    useEffect(() => {
        if (!isOpen) {
            setShouldAnimate(false)
        }
    }, [isOpen])

    useEffect(() => {
        setInitial(
            rootRoutes.reduce((acc, curr) => {
                acc[curr.label] = true
                return acc
            }, {} as Record<string, boolean>)
        )
    }, [])

    const isHomol = import.meta.env.MODE === 'qa'

    return (
        <Sheet onOpenChange={onOpenChange} open={isOpen}>
            <SheetTrigger>
                <Menu size={14} className="text-foreground" />
            </SheetTrigger>
            <SheetContent side="left">
                <SheetHeader className="flex flex-row items-center gap-3 px-3.5 space-y-0">
                    <img src={Icon} alt="Logo" className="w-6 h-6" />
                    <TypographyH3>Monitor de Qualidade</TypographyH3>
                    {isHomol && (
                        <div className="px-2 py-1 rounded-full bg-amber-500">
                            <TypographyP className="text-xs font-semibold text-amber-900">
                                Homol
                            </TypographyP>
                        </div>
                    )}
                </SheetHeader>
                <div className="flex flex-col h-full gap-4 py-4">
                    <div className="flex items-center gap-2 px-4 border rounded-full focus-within:ring-1 focus-within:ring-ring text-foreground border-input">
                        <Search size={14} className="text-foreground" />
                        <DebouncedInput
                            className="border-none focus-visible:ring-0"
                            placeholder="Buscar monitor"
                            value={inputValue}
                            onChange={(value) => setInputValue(value as string)}
                        />
                        <button onClick={() => setInputValue('')}>
                            <X size={14} />
                        </button>
                    </div>
                    <ScrollArea className="flex-1">
                        {rootRoutes.map((route) => {
                            const { label, index } = route

                            return (
                                <LinkSection
                                    filterValue={inputValue}
                                    key={index}
                                    index={index}
                                    routes={routes}
                                    title={label}
                                    onOpenChange={onOpenChange}
                                    shouldAnimate={shouldAnimate}
                                    setShouldAnimate={setShouldAnimate}
                                />
                            )
                        })}
                    </ScrollArea>
                    <div className="p-2">
                        <TypographyMutedXs className="block text-center">
                            V{import.meta.env.VITE_VERSION}
                        </TypographyMutedXs>
                    </div>
                </div>
            </SheetContent>
        </Sheet>
    )
}

export default Sidenav
