import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { ColumnPinningState, createColumnHelper } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { MonitorFermentacao, Resultado } from '@/api/business/fermentacao/type'
import CellPopover from './components/CellPopover'
import { sort } from '@/utils/sort'

type FermentacaoTableProps = {
    data?: MonitorFermentacao[]
    lote?: string
    order: Record<number, number>
}

const FermentacaoTable = ({ data = [], order }: FermentacaoTableProps) => {
    const [pinningState, setPinningState] = useState<ColumnPinningState>({
        left: ['ensaio'],
    })

    const filteredData = data.map((value) => ({
        ...value,
        resultados: value.resultados.filter(
            (result) => result.skip_lote !== 'ACOMPANHAMENTO DA FERMENTAÇÃO'
        ),
    }))

    const tableData = useMemo(() => {
        const ensaios: Record<
            string,
            {
                cod_ensaio: number
                ensaio: string
                data: Resultado[]
            }
        > = {}

        for (const row of filteredData) {
            for (const result of row.resultados) {
                const { ensaio } = result

                if (!ensaios[ensaio]) {
                    ensaios[ensaio] = {
                        cod_ensaio: order[ensaio],
                        ensaio,
                        data: row.resultados.filter(
                            (val) => val.ensaio === ensaio
                        ),
                    }
                }
            }
        }

        return sort(Object.values(ensaios), 'cod_ensaio', order)
    }, [filteredData])

    const tableColumnHeaders = useMemo(() => {
        const lotes: Record<string, string> = {}

        for (const row of filteredData) {
            for (const result of row.resultados) {
                lotes[result.skip_lote] = result.skip_lote
            }
        }

        return Object.values(lotes)
    }, [filteredData])

    type FermentacaoTableData = TableData<(typeof tableData)[0]>

    const columnHelper = createColumnHelper<FermentacaoTableData>()

    const columns = useMemo(
        () => [
            columnHelper.accessor('ensaio', {
                id: 'ensaio',
                header: 'Ensaio',
                size: 200,
                enableHiding: false,
            }),
            ...tableColumnHeaders.map((header) => {
                return columnHelper.display({
                    id: header,
                    header: () => {
                        switch (header) {
                            case 'A CADA COLETA DE GRAU FINAL':
                                return 'GRAU FINAL'
                            case 'A CADA FINAL DE FERMENTAÇÃO':
                                return 'FINAL DE FERMENTAÇÃO'
                            case 'A CADA PRIMEIRA COLETA DA FERMENTAÇÃO':
                                return 'PRIMEIRA COLETA'
                            default:
                                return header
                        }
                    },
                    cell: ({ row }) => {
                        const { ensaio, data } = row.original

                        const results = data.filter(
                            (result) =>
                                result.skip_lote === header &&
                                result.ensaio === ensaio
                        )

                        return results.map((value) => (
                            <CellPopover data={value} />
                        ))
                    },
                    size: 200,
                    meta: {
                        header: {
                            className: 'p-2',
                        },
                        cell: {
                            className: 'p-0',
                        },
                    },
                    enableHiding: false,
                })
            }),
        ],
        [tableColumnHeaders]
    )

    return (
        <Table<FermentacaoTableData>
            data={tableData}
            columns={columns}
            tableState={{
                columnPinning: pinningState,
            }}
            onColumnPinningStateChange={setPinningState}
            rowStimateSize={40}
            meta={{
                layout: 'stretch',
            }}
        />
    )
}

export default FermentacaoTable
