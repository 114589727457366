import {
    fetchMonitorFermentacao,
    fetchMonitorFermentacaoDiario,
    fetchMonitorFermentacaoFiltros,
    fetchMonitorFermentacaoPropagacao,
    fetchMonitorFermentacaoPropagacaoDiario,
} from '@/api/business/fermentacao'
import {
    FetchMonitorFermentacaoDiarioProps,
    FetchMonitorFermentacaoPropagacaoProps,
    FetchMonitorFermentacaoPropagacaoDiarioProps,
    FetchMonitorFermentacaoFiltrosProps,
    FetchMonitorFermentacaoProps,
} from '@/api/business/fermentacao/type'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export const fermentacaoKeys = {
    all: ['fermentacao'] as const,
    lists: () => [...fermentacaoKeys.all, 'list'] as const,
    list: (props: FetchMonitorFermentacaoProps) =>
        [...fermentacaoKeys.lists(), { ...props }] as const,
    listDiario: (props: FetchMonitorFermentacaoDiarioProps) =>
        [...fermentacaoKeys.lists(), 'diario', { ...props }] as const,
    listPropagacao: (props: FetchMonitorFermentacaoPropagacaoProps) =>
        [...fermentacaoKeys.lists(), 'propagacao', { ...props }] as const,
    listPropagacaoDiario: (
        props: FetchMonitorFermentacaoPropagacaoDiarioProps
    ) =>
        [
            ...fermentacaoKeys.lists(),
            'propagacao',
            'diario',
            { ...props },
        ] as const,
    filters: ({ cod_filial }: FetchMonitorFermentacaoFiltrosProps) =>
        [...fermentacaoKeys.lists(), 'filters', cod_filial] as const,
}

export type UseFermentacaoQueryProps = FetchMonitorFermentacaoProps & {
    enabled: boolean
}

export type UseFermentacaoPropagacaoQueryProps =
    FetchMonitorFermentacaoPropagacaoProps & {
        enabled: boolean
    }

export type UseFermentacaoPropagacaoDiarioQueryProps =
    FetchMonitorFermentacaoDiarioProps & {
        enabled: boolean
    }

export type UseFermentacaoDiarioQueryProps =
    FetchMonitorFermentacaoDiarioProps & {
        enabled: boolean
    }

export type UseFermentacaoFiltroQueryProps =
    FetchMonitorFermentacaoFiltrosProps & {
        enabled: boolean
    }

export const useMonitorFermentacaoFiltrosQuery = ({
    enabled,
    ...props
}: UseFermentacaoFiltroQueryProps) => {
    return useQuery({
        queryKey: fermentacaoKeys.filters({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        enabled,
        queryFn: () => fetchMonitorFermentacaoFiltros({ ...props }),
        placeholderData: keepPreviousData,
        select: (data) =>
            data.sort((a, b) => a.abreviatura.localeCompare(b.abreviatura)),
    })
}

export const useMonitorFermentacaoQuery = (props: UseFermentacaoQueryProps) => {
    return useQuery({
        queryKey: fermentacaoKeys.list({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        queryFn: () => fetchMonitorFermentacao({ ...props }),
        placeholderData: keepPreviousData,
    })
}

export const useMonitorFermentacaoPropagacaoQuery = (
    props: UseFermentacaoPropagacaoQueryProps
) => {
    return useQuery({
        queryKey: fermentacaoKeys.listPropagacao({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        queryFn: () => fetchMonitorFermentacaoPropagacao({ ...props }),
        placeholderData: keepPreviousData,
    })
}

export const useMonitorFermentacaoPropagacaoDiarioQuery = (
    props: UseFermentacaoPropagacaoDiarioQueryProps
) => {
    return useQuery({
        queryKey: fermentacaoKeys.listPropagacaoDiario({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        queryFn: () => fetchMonitorFermentacaoPropagacaoDiario({ ...props }),
        placeholderData: keepPreviousData,
        select: (data) => {
            return data
                .sort((a, b) => a.turno.localeCompare(b.turno))
                .map((value) => {
                    return {
                        ...value,
                        amostras: value.amostras.sort((a, b) =>
                            a.abreviatura_bem.localeCompare(b.abreviatura_bem)
                        ),
                    }
                })
        },
    })
}

export const useMonitorFermentacaoDiarioQuery = (
    props: UseFermentacaoDiarioQueryProps
) => {
    return useQuery({
        queryKey: fermentacaoKeys.listDiario({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        queryFn: () => fetchMonitorFermentacaoDiario({ ...props }),
        placeholderData: keepPreviousData,
        select: (data) => {
            return data
                .sort((a, b) => a.turno.localeCompare(b.turno))
                .map((value) => {
                    return {
                        ...value,
                        amostras: value.amostras.sort((a, b) =>
                            a.abreviatura_bem.localeCompare(b.abreviatura_bem)
                        ),
                    }
                })
        },
    })
}
