import { useMonitorBrassagemQuery } from '@/queries/useMonitorBrassagemQuery'
import { format, isBefore } from 'date-fns'
import { useEffect, useMemo, useRef } from 'react'
import { SALAS } from './consts'
import { TypographyLarge, TypographyMutedXs } from '@/components/ui/typography'
import { cn } from '@/lib/utils'
import CompanySelect from '../../components/CompanySelect'
import BrassagemTable from './components/BrassagemTable'
import { Maximize, Minimize, RefreshCcw } from 'lucide-react'
import TopMessage from '@/components/TopMessage'
import PageContainer from '@/components/PageContainer'
import LayoutSheet from './components/LayoutSheet'
import { CheckState } from '@/store/monitorBrassagemSlice/type'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
import ButtonWithTooltip from '@/components/ButtonWithTooltip'
import { AnimatePresence } from 'framer-motion'
import { useEmpresaQuery } from '@/queries/useEmpresaQuery'
import { useFullscreen } from '@/hooks/useFullscreen'
import { toast } from 'sonner'
import { isBetween } from '@/utils/date'
import { useSequenciaEnsaiosQuery } from '@/queries/useSequenciaEnsaiosQuery'
import { sort } from '@/utils/sort'
import { Resultado } from '@/api/business/brassagem/type'
import DateFilter, { OnSearchProps } from '@/components/DateFilter'

const stateSelector = (state: Store) => ({
    selectedCompany: state.monitorBrassagemSlice.state.selectedCompany,
    date: state.monitorBrassagemSlice.state.date,
    selectedSala: state.monitorBrassagemSlice.state.selectedSala,
    tableLayoutState: state.monitorBrassagemSlice.state.tableLayoutState,
    setSala: state.monitorBrassagemSlice.actions.setSala,
    setCompany: state.monitorBrassagemSlice.actions.setCompany,
    setDateInterval: state.monitorBrassagemSlice.actions.setDateInterval,
    setTableLayout: state.monitorBrassagemSlice.actions.setTableLayout,
    resetDate: state.monitorBrassagemSlice.actions.resetDate,
})

type Subheader = {
    subheader: string
    cod_ensaio: number
}

export type OperacoesHeader = {
    header: string
    subheaders: Subheader[]
}

export type OperacoesHeaderRecord = Record<
    string,
    Omit<OperacoesHeader, 'subheaders'> & {
        subheaders: Record<string, Subheader>
    }
>

const ORDER: Record<Resultado['operacao'], number> = {
    MOSTURAÇÃO: 1,
    SACARIFICAÇÃO: 2,
    FILTRAÇÃO: 3,
    'RINSAGEM DE TINA': 4,
    'FINAL DE FILTRAÇÃO': 5,
    'INICIO DE FERVURA': 6,
    'FINAL DE FERVURA': 7,
    'RESFRIAMENTO DO MOSTO': 8,
}

const MonitorBrassagem = () => {
    const {
        selectedCompany,
        date: { initial, final },
        tableLayoutState,
        selectedSala,
        setDateInterval,
        setSala,
        setCompany,
        setTableLayout,
    } = useBaseStore(stateSelector)

    const mainContainerRef = useRef<HTMLDivElement | null>(null)

    const { isFullscreen, onToggleFullScreen } = useFullscreen({
        element: mainContainerRef.current,
    })

    const { data: order, isSuccess: isOrderSuccess } = useSequenciaEnsaiosQuery(
        {
            cod_filial: Number(selectedCompany),
            monitor: 'BRASSAGEM',
            enabled: !!selectedCompany,
        }
    )

    const { data: empresas = [], isSuccess: isEmpresasSuccess } =
        useEmpresaQuery()

    const {
        data = [],
        isPending,
        isFetching,
        isError,
        dataUpdatedAt,
        refetch,
    } = useMonitorBrassagemQuery({
        cod_filial: selectedCompany!,
        data_inicial: Number(format(new Date(initial), 'yyyyMMdd')),
        data_final: Number(format(new Date(final), 'yyyyMMdd')),
        sala_de_brassagem: selectedSala!,
        enabled:
            isEmpresasSuccess &&
            empresas.length > 0 &&
            isOrderSuccess &&
            !!selectedSala,
    })

    const onSearch = ({ from, to }: OnSearchProps) => {
        if (isBefore(to, from)) {
            return toast.error(
                'A data final não pode ser anterior à data inicial'
            )
        }

        if (!isBetween(new Date(to), new Date(from), 60)) {
            return toast.warning(
                'Intervalo de datas deve ser de no máximo 60 dias'
            )
        }

        setDateInterval({
            initial: from.toDateString(),
            final: to.toDateString(),
        })
    }

    const operacoes = useMemo(() => {
        const headers: OperacoesHeaderRecord = {}

        for (const row of data) {
            for (const result of row.resultados) {
                const op = result.operacao

                if (!headers[op]) {
                    headers[op] = {
                        header: op,
                        subheaders: {},
                    }
                }

                if (!headers[op].subheaders[result.cod_ensaio]) {
                    headers[op].subheaders = {
                        ...headers[op].subheaders,
                        [result.cod_ensaio]: {
                            subheader: result.ensaio,
                            cod_ensaio: result.cod_ensaio,
                        },
                    }
                }
            }
        }

        return sort(Object.values(headers), 'header', ORDER).map((header) => ({
            ...header,
            subheaders: sort(
                Object.values(header.subheaders),
                'cod_ensaio',
                order || {}
            ),
        }))
    }, [data])

    useEffect(() => {
        setTableLayout(
            operacoes.reduce((acc, curr) => {
                return {
                    ...acc,
                    [curr.header]: curr.subheaders.reduce((acc, curr) => {
                        return {
                            ...acc,
                            [curr.cod_ensaio]: true,
                        }
                    }, {}),
                }
            }, {} as CheckState)
        )
    }, [operacoes])

    return (
        <PageContainer ref={mainContainerRef}>
            <div className="absolute top-4 right-4">
                {dataUpdatedAt !== 0 && (
                    <TypographyMutedXs>
                        Última atualização:{' '}
                        {format(
                            new Date(dataUpdatedAt),
                            'dd/MM/yyyy, HH:mm:ss'
                        )}
                    </TypographyMutedXs>
                )}
            </div>
            <div className="flex items-center justify-center gap-8 p-8">
                {SALAS.map((sala) => {
                    const isSelected = selectedSala === sala.value

                    return (
                        <div key={sala.value}>
                            <input
                                type="radio"
                                name="sala"
                                value={sala.value}
                                id={sala.value}
                                className="hidden"
                                onChange={(e) => {
                                    setSala(e.target.value)
                                }}
                            />
                            <TypographyLarge asChild>
                                <label
                                    htmlFor={sala.value}
                                    className={cn(
                                        'cursor-pointer',
                                        isSelected
                                            ? 'text-primary'
                                            : 'text-accent-foreground'
                                    )}
                                >
                                    {sala.label}
                                </label>
                            </TypographyLarge>
                        </div>
                    )
                })}
            </div>
            <div className="flex justify-between px-4 pb-2">
                <div>
                    <CompanySelect
                        companies={empresas}
                        value={selectedCompany}
                        onChange={setCompany}
                    />
                </div>
                <div className="flex items-center gap-2">
                    <DateFilter
                        final={final}
                        initial={initial}
                        onSearch={onSearch}
                    />
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={() => refetch()}
                        tooltipContent={'Atualizar dados'}
                    >
                        <RefreshCcw size={14} />
                    </ButtonWithTooltip>
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={onToggleFullScreen}
                        tooltipContent={
                            isFullscreen ? 'Minimizar' : 'Maximizar'
                        }
                    >
                        {isFullscreen ? (
                            <Minimize size={14} />
                        ) : (
                            <Maximize size={14} />
                        )}
                    </ButtonWithTooltip>
                    <LayoutSheet
                        columns={operacoes}
                        checkState={tableLayoutState}
                    />
                </div>
            </div>
            <AnimatePresence>
                {!data && isPending ? (
                    <TopMessage variant="loading" text="Carregando dados..." />
                ) : null}
                {!isPending && isFetching ? (
                    <TopMessage variant="success" text="Atualizando dados..." />
                ) : null}
                {isError && (
                    <TopMessage variant="error" text="Erro ao buscar dados" />
                )}
            </AnimatePresence>
            <div className="flex-1 px-4 py-2 overflow-hidden">
                <BrassagemTable
                    data={data}
                    operacoes={operacoes}
                    checkState={tableLayoutState}
                />
            </div>
        </PageContainer>
    )
}

export default MonitorBrassagem
