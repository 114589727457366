import Table from '@/components/Table'
import {
    MonitorFermentacaoPropagacao,
    Resultado,
} from '@/api/business/fermentacao/type'
import { TableData } from '@/components/Table/type'
import { getDate } from '@/utils/date'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'
import { format, isValid } from 'date-fns'
import CellPopover from './components/CellPopover'

type AcompanhamentoTableProps = {
    data?: MonitorFermentacaoPropagacao[]
    lote?: string
}

type FermentacaoTableData = TableData<{ date: string }>

export type FermentacaoData = {
    date: string
    ensaios: Record<
        string,
        {
            ensaio: string
            resultado: Resultado
        }
    >
}

const columnHelper = createColumnHelper<FermentacaoTableData>()

const order = {
    PH: 2,
    'EXTRATO APARENTE': 1,
}

const AcompanhamentoTable = ({ data = [] }: AcompanhamentoTableProps) => {
    const results = data.flatMap((value) => value.resultados)

    const tableColumnHeaders = useMemo(() => {
        const headers: Record<string, Resultado> = {}

        for (const row of results) {
            headers[row.ensaio] = row
        }

        return Object.values(headers).sort((a, b) =>
            order[a.ensaio] > order[b.ensaio] ? 1 : -1
        )
    }, [data])

    const tableData: FermentacaoData[] = useMemo(() => {
        const newData: Record<
            string,
            {
                date: string
                ensaios: Record<
                    string,
                    { ensaio: string; resultado: Resultado }
                >
            }
        > = {}

        for (const row of results) {
            const date = getDate({
                date: row.data_da_coleta,
                hour: row.hora_da_coleta,
            }).toISOString()

            if (!newData[date]) {
                newData[date] = {
                    date,
                    ensaios: {},
                }
            }

            if (!newData[date].ensaios[row.ensaio]) {
                newData[date].ensaios[row.ensaio] = {
                    ensaio: row.ensaio,
                    resultado: row,
                }
            }
        }

        return Object.values(newData)
    }, [data])

    const columns = useMemo(
        () => [
            columnHelper.accessor(
                ({ date }) => {
                    const isDateValid = isValid(new Date(date))
                        ? new Date(date)
                        : null

                    if (!isDateValid) return null

                    return format(isDateValid, 'dd/MM/yyyy, HH:mm:ss')
                },
                {
                    id: 'date',
                    header: 'Data',
                    size: 200,
                    enableHiding: false,
                }
            ),
            ...tableColumnHeaders.map((header) =>
                columnHelper.display({
                    id: header.ensaio,
                    header: header.ensaio,
                    cell: ({ row }) => {
                        const { date } = row.original

                        const data = tableData.find(
                            (value) => value.date === date
                        )

                        if (!data) return null
                        if (!data.ensaios[header.ensaio]) return null

                        return (
                            <CellPopover
                                data={data.ensaios[header.ensaio].resultado}
                            />
                        )
                    },
                })
            ),
        ],
        [tableColumnHeaders]
    )

    return (
        <div className="px-4 overflow-hidden">
            <Table<FermentacaoTableData>
                data={tableData}
                columns={columns}
                rowStimateSize={40}
            />
        </div>
    )
}

export default AcompanhamentoTable
