import { Empresa } from '@/api/business/empresa/type'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { useEffect } from 'react'

type CompanySelectProps = {
    companies: Empresa[]
    value?: string
    onChange: (value?: string) => void
}

const CompanySelect = ({ companies, value, onChange }: CompanySelectProps) => {
    useEffect(() => {
        if (!value && companies.length > 0) {
            onChange(companies[0].cod_filial.toString())
        }
    }, [companies])

    return (
        <div>
            <Select onValueChange={onChange} value={value?.toString()}>
                <SelectTrigger className="gap-4 p-4 text-lg border-none shadow-none hover:bg-accent">
                    <SelectValue placeholder="Selecione uma empresa" />
                </SelectTrigger>
                <SelectContent>
                    {companies.map((company) => {
                        return (
                            <SelectItem
                                key={company.cod_filial}
                                value={company.cod_filial.toString()}
                            >
                                {company.filial}
                            </SelectItem>
                        )
                    })}
                </SelectContent>
            </Select>
        </div>
    )
}

export default CompanySelect
