import { MonitorEstabilidadeBiologicaFiltros } from '@/api/business/estabilidadeBiologica/type'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { TypographyXS } from '@/components/ui/typography'
import { cn } from '@/lib/utils'

type LabsSelectProps = {
    labs: MonitorEstabilidadeBiologicaFiltros[]
    value?: number
    onChange: (value: string) => void
}

const LabsSelect = ({ labs, value, onChange }: LabsSelectProps) => {
    return (
        <Select onValueChange={onChange} value={value?.toString()}>
            <SelectTrigger
                className={cn('hover:bg-muted h-[56px] min-w-[200px]')}
            >
                <SelectValue placeholder="Selecione um laboratório" />
            </SelectTrigger>
            <SelectContent>
                {labs?.length === 0 ? (
                    <SelectGroup>
                        <SelectItem value="sem_dados" disabled>
                            <TypographyXS className="text-start">
                                Não há laboratório disponível
                            </TypographyXS>
                        </SelectItem>
                    </SelectGroup>
                ) : (
                    <SelectGroup>
                        {labs.map((laboratório) => {
                            return (
                                <SelectItem
                                    key={laboratório.cod_laboratorio}
                                    value={laboratório.cod_laboratorio.toString()}
                                >
                                    {laboratório.laboratorio}
                                </SelectItem>
                            )
                        })}
                    </SelectGroup>
                )}
            </SelectContent>
        </Select>
    )
}

export default LabsSelect
