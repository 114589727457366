import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { ColumnPinningState, createColumnHelper } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { Amostra } from '@/api/business/fermentacao/type'
import CellPopover from './components/CellPopover'

type FermentacaoTableProps = {
    data?: Amostra[]
}

const headerOrder = {
    'A CADA COLETA DE GRAU FINAL': 1,
    'A CADA PRIMEIRA COLETA DA FERMENTAÇÃO': 2,
    'ACOMPANHAMENTO DA FERMENTAÇÃO': 3,
    'A CADA FINAL DE FERMENTAÇÃO': 4,
}

const order = {
    'EXTRATO APARENTE': 1,
    PH: 2,
    'EXTRATO ORIGINAL': 3,
    'DICETONAS VICINAIS': 4,
    COR: 5,
    ÁLCOOL: 6,
}

const FermentacaoTable = ({ data = [] }: FermentacaoTableProps) => {
    const [pinningState, setPinningState] = useState<ColumnPinningState>({
        left: ['ensaio'],
    })

    const tableColumnHeaders = useMemo(() => {
        const headers: Record<
            string,
            {
                header: string
                subheaders: Record<
                    string,
                    { subheader: string; data: Amostra[] }
                >
            }
        > = {}

        for (const row of data) {
            for (const result of row.resultados) {
                if (!headers[row.skip_lote]) {
                    headers[row.skip_lote] = {
                        header: row.skip_lote,
                        subheaders: {},
                    }
                }

                if (!headers[row.skip_lote].subheaders[result.ensaio]) {
                    headers[row.skip_lote].subheaders[result.ensaio] = {
                        subheader: result.ensaio,
                        data: [],
                    }
                }
            }
        }

        return Object.values(headers)
            .sort((a, b) =>
                headerOrder[a.header] > headerOrder[b.header] ? 1 : -1
            )
            .map((value) => ({
                ...value,
                subheaders: Object.values(value.subheaders).sort((a, b) =>
                    order[a.subheader] > order[b.subheader] ? 1 : -1
                ),
            }))
    }, [data])

    const columnHelper = createColumnHelper<TableData<Amostra>>()

    const columns = useMemo(
        () => [
            columnHelper.accessor('abreviatura_bem', {
                id: 'fmt',
                header: 'FMT',
                size: 150,
                enableHiding: false,
            }),
            columnHelper.accessor('lote_de_controle_de_qualidade', {
                id: 'lcq',
                header: 'LCQ',
                size: 200,
                enableHiding: false,
            }),
            columnHelper.accessor('hora_da_coleta', {
                id: 'hora_da_coleta',
                header: 'Hora',
                size: 100,
                enableHiding: false,
            }),
            ...tableColumnHeaders.map((header) => {
                return columnHelper.group({
                    header: header.header,
                    columns: header.subheaders.map((subheader) => {
                        const size = subheader.subheader.length * 10 + 32

                        return columnHelper.display({
                            id: `${header.header}-${subheader.subheader}`,
                            header: subheader.subheader,
                            cell: ({ row }) => {
                                const {
                                    hora_da_coleta,
                                    abreviatura_bem,
                                    skip_lote,
                                } = row.original

                                const values = data.filter(
                                    (value) =>
                                        value.skip_lote === header.header &&
                                        value.abreviatura_bem ===
                                            abreviatura_bem &&
                                        value.skip_lote === skip_lote &&
                                        value.hora_da_coleta === hora_da_coleta
                                )

                                const amostras = values.filter((val) =>
                                    val.resultados.some(
                                        (result) =>
                                            result.ensaio ===
                                            subheader.subheader
                                    )
                                )

                                return amostras
                                    .flatMap((amostra) => amostra.resultados)
                                    .filter(
                                        (result) =>
                                            result.ensaio ===
                                            subheader.subheader
                                    )
                                    .map((resultado) => {
                                        return (
                                            <CellPopover
                                                key={resultado.cod_amostra}
                                                data={resultado}
                                            />
                                        )
                                    })
                            },
                            size: size < 80 ? 120 : size,
                            enableHiding: false,
                            meta: {
                                cell: { className: 'p-0' },
                            },
                        })
                    }),
                })
            }),
        ],
        [tableColumnHeaders]
    )

    return (
        <Table<TableData<Amostra>>
            data={data}
            columns={columns}
            tableState={{
                columnPinning: pinningState,
            }}
            onColumnPinningStateChange={setPinningState}
            rowStimateSize={40}
            meta={{
                layout: 'stretch',
            }}
        />
    )
}

export default FermentacaoTable
