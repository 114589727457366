import {
    fetchMonitorCipEnvasamento,
    fetchMonitorCipEnvasamentoFiltros,
} from '@/api/business/cipEnvasamento'
import {
    FetchMonitorCipEnvasamentoFiltrosProps,
    FetchMonitorCipEnvasamentoProps,
} from '@/api/business/cipEnvasamento/type'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export const monitorCipEnvasamentoKeys = {
    all: ['monitorCipEnvasamento'] as const,
    lists: () => [...monitorCipEnvasamentoKeys.all, 'list'] as const,
    list: ({
        data_final,
        data_inicial,
        cod_laboratorio,
    }: FetchMonitorCipEnvasamentoProps) =>
        [
            ...monitorCipEnvasamentoKeys.lists(),
            data_final,
            data_inicial,
            cod_laboratorio,
        ] as const,
    filtros: ({ cod_filial }: FetchMonitorCipEnvasamentoFiltrosProps) =>
        [...monitorCipEnvasamentoKeys.all, 'filtros', cod_filial] as const,
}

export type UseMonitorCipEnvasamentoQueryProps =
    FetchMonitorCipEnvasamentoProps & {
        enabled: boolean
    }

export type UseMonitorCipEnvasamentoFiltrosQueryProps =
    FetchMonitorCipEnvasamentoFiltrosProps & {
        enabled: boolean
    }

export const useMonitorCipEnvasamentoQuery = ({
    enabled,
    ...props
}: UseMonitorCipEnvasamentoQueryProps) => {
    return useQuery({
        queryKey: monitorCipEnvasamentoKeys.list({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        queryFn: () => fetchMonitorCipEnvasamento({ ...props }),
        placeholderData: keepPreviousData,
        select: (data) => data.filter((value) => value.resultados.length > 0),
    })
}

export const useMonitorCipEnvasamentoFiltrosQuery = ({
    enabled,
    ...props
}: UseMonitorCipEnvasamentoFiltrosQueryProps) => {
    return useQuery({
        queryKey: monitorCipEnvasamentoKeys.filtros({ ...props }),
        refetchInterval: 60 * 1000 * 5,
        queryFn: () => fetchMonitorCipEnvasamentoFiltros({ ...props }),
        placeholderData: keepPreviousData,
    })
}
