import { MonitorBrassagem } from '@/api/business/brassagem/type'
import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { createColumnHelper } from '@tanstack/react-table'
import CellPopover from './components/CellPopover'
import { CheckState } from '@/store/monitorBrassagemSlice/type'
import { OperacoesHeader } from '../..'
import { useMemo } from 'react'

type BrassagemTableProps = {
    data?: MonitorBrassagem[]
    operacoes: OperacoesHeader[]
    checkState: CheckState
}

type BrassagemTableData = TableData<MonitorBrassagem>
const columnHelper = createColumnHelper<MonitorBrassagem>()

const BrassagemTable = ({
    data = [],
    operacoes,
    checkState,
}: BrassagemTableProps) => {
    const tableData = useMemo(
        () =>
            data.sort((a, b) => {
                return b.cod_fabrico.localeCompare(a.cod_fabrico)
            }),
        [data]
    )

    const columns = useMemo(
        () => [
            columnHelper.accessor('cod_fabrico', {
                id: 'cod_fabrico',
                header: 'Fabrico',
                cell: ({ getValue }) => {
                    const first = getValue().slice(0, 3)
                    const middle = getValue().slice(3, 7)
                    const last = getValue().slice(-2)

                    return (
                        <span className="overflow-hidden whitespace-nowrap text-ellipsis">
                            <span>{first}</span>
                            <span className="font-bold text-primary">
                                {middle}
                            </span>
                            <span>{last}</span>
                        </span>
                    )
                },
                enableHiding: false,
                meta: {
                    // enableMenu: false,
                    enableColumnOrdering: false,
                },
                size: 110,
            }),
            columnHelper.accessor('produto_abreviatura', {
                id: 'produto_abreviatura',
                header: 'Produto',
                enableHiding: false,
                meta: {
                    cell: {
                        className:
                            'whitespace-nowrap text-ellipsis overflow-hidden',
                    },
                    enableColumnOrdering: false,
                },
                size: 120,
            }),
            columnHelper.accessor(
                ({ rastreabilidade_ascendente }) =>
                    rastreabilidade_ascendente
                        ?.split('/')
                        .map((value) => value.split('-')[0]),
                {
                    id: 'rastreabilidade_ascendente',
                    header: 'Trasfega',
                    enableHiding: false,
                    meta: {
                        cell: {
                            className:
                                'whitespace-nowrap text-ellipsis overflow-hidden',
                        },
                        enableColumnOrdering: false,
                    },
                    size: 120,
                }
            ),
            columnHelper.group({
                id: 'operacoes',
                header: 'OPERAÇÕES',
                columns: operacoes
                    .filter((operacao) =>
                        checkState[operacao.header]
                            ? Object.values(checkState[operacao.header]).filter(
                                  Boolean
                              ).length > 0
                            : true
                    )
                    .map((operacao) => {
                        return columnHelper.group({
                            id: `${operacao.header}`,
                            header: operacao.header,
                            columns: operacao.subheaders
                                .filter((ensaio) =>
                                    checkState[operacao.header]
                                        ? checkState[operacao.header][
                                              ensaio.cod_ensaio
                                          ]
                                        : true
                                )
                                .map((resultado) => {
                                    return columnHelper.display({
                                        id: `${operacao.header}${resultado.cod_ensaio}`,
                                        header: resultado.subheader,
                                        cell: ({ row }) => {
                                            const { cod_fabrico } = row.original

                                            const found = data.find(
                                                (value) =>
                                                    value.cod_fabrico ===
                                                    cod_fabrico
                                            )

                                            const result =
                                                found?.resultados.filter(
                                                    (res) =>
                                                        res.ensaio ===
                                                            resultado.subheader &&
                                                        res.operacao ===
                                                            operacao.header
                                                )

                                            if (result?.length === 0)
                                                return null

                                            return result?.map((res) => {
                                                return (
                                                    <CellPopover data={res} />
                                                )
                                            })
                                        },
                                        meta: {
                                            enableMenu: false,
                                            enableColumnOrdering: false,
                                            header: {
                                                className:
                                                    'px-4 flex justify-center overflow-hidden break-words',
                                            },
                                            cell: {
                                                className: 'p-0 flex',
                                            },
                                        },
                                        size:
                                            operacao.header === 'COR'
                                                ? 150
                                                : 120,
                                    })
                                }),
                            meta: {
                                enableMenu: false,
                                enableColumnOrdering: false,
                                header: {
                                    className:
                                        'p-4 flex justify-center text-primary',
                                },
                            },
                        })
                    }),
                meta: {
                    enableMenu: false,
                    enableColumnOrdering: false,
                    header: {
                        className: 'p-4 flex justify-center',
                    },
                },
            }),
        ],
        [data, operacoes, checkState]
    )

    return (
        <Table<BrassagemTableData>
            data={tableData}
            columns={columns}
            meta={{ layout: 'stretch' }}
        />
    )
}

export default BrassagemTable
