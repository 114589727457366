import { ImmerStateCreator } from '../type'
import {
    MonitorEstabilidadeBiologicaSlice,
    MonitorEstabilidadeBiologicaState,
} from './type'
import { getStorageState, persistState } from '@/utils/storage'

const initialState: MonitorEstabilidadeBiologicaState = {
    selectedCompany: undefined,
    selectedLab: undefined,
    selectedLaudos: {},
    selectedLinhas: {},
    date: {
        initial: new Date().toISOString(),
        final: new Date().toISOString(),
    },
}

const key = 'monitor-estabilidade-biologica'

const persist = (state: MonitorEstabilidadeBiologicaState) => {
    persistState({ key, state })
}

export const useMonitorEstabilidadeBiologicaSlice: ImmerStateCreator<
    MonitorEstabilidadeBiologicaSlice
> = (set) => ({
    state: getStorageState({
        key,
        state: initialState,
    }),
    actions: {
        setCompany: (company) => {
            set((state) => {
                state.monitorEstabilidadeBiologicaSlice.state.selectedCompany =
                    company

                persist(state.monitorEstabilidadeBiologicaSlice.state)
            })
        },
        setDateInterval: ({ final, initial }) => {
            set((state) => {
                state.monitorEstabilidadeBiologicaSlice.state.date.initial =
                    initial
                state.monitorEstabilidadeBiologicaSlice.state.date.final = final

                persist(state.monitorEstabilidadeBiologicaSlice.state)
            })
        },
        setLab: (lab) => {
            set((state) => {
                state.monitorEstabilidadeBiologicaSlice.state.selectedLab = lab

                persist(state.monitorEstabilidadeBiologicaSlice.state)
            })
        },
        onSelectLaudo: (value) => {
            set((state) => {
                state.monitorEstabilidadeBiologicaSlice.state.selectedLaudos[
                    value
                ] =
                    !state.monitorEstabilidadeBiologicaSlice.state
                        .selectedLaudos[value]

                persist(state.monitorEstabilidadeBiologicaSlice.state)
            })
        },
        onClearLaudo: () => {
            set((state) => {
                state.monitorEstabilidadeBiologicaSlice.state.selectedLaudos =
                    {}

                persist(state.monitorEstabilidadeBiologicaSlice.state)
            })
        },
        onSelectLinha: (value) => {
            set((state) => {
                state.monitorEstabilidadeBiologicaSlice.state.selectedLinhas[
                    value
                ] =
                    !state.monitorEstabilidadeBiologicaSlice.state
                        .selectedLinhas[value]

                persist(state.monitorEstabilidadeBiologicaSlice.state)
            })
        },
        onClearLinha: () => {
            set((state) => {
                state.monitorEstabilidadeBiologicaSlice.state.selectedLinhas =
                    {}

                persist(state.monitorEstabilidadeBiologicaSlice.state)
            })
        },
        resetDate: () => {
            set((state) => {
                state.monitorEstabilidadeBiologicaSlice.state.date = {
                    final: new Date().toString(),
                    initial: new Date().toString(),
                }
            })
        },
        onReset: () => {
            set((state) => {
                state.monitorEstabilidadeBiologicaSlice.state = initialState
            })
        },
    },
})
