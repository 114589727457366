import instance from '..'
import {
    FetchMonitorCipEnvasamentoFiltrosProps,
    FetchMonitorCipEnvasamentoProps,
    MonitorCipEnvasamento,
    MonitorCipEnvasamentoFiltros,
} from './type'

export const fetchMonitorCipEnvasamento = async (
    props: FetchMonitorCipEnvasamentoProps
) => {
    const response = await instance.post<MonitorCipEnvasamento[]>(
        '/monitor/cip/envasamento',
        {
            ...props,
        }
    )

    return response.data
}

export const fetchMonitorCipEnvasamentoFiltros = async (
    props: FetchMonitorCipEnvasamentoFiltrosProps
) => {
    const response = await instance.post<MonitorCipEnvasamentoFiltros[]>(
        '/monitor/recursos/cip/envasamento',
        {
            ...props,
        }
    )

    return response.data
}
