import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { ColumnPinningState, createColumnHelper } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { MonitorRecravacaoFlat } from '@/api/business/recravacao/type'
import CellPopover from './components/CellPopover'
import { sort } from '@/utils/sort'

type RecravacaoTableProps = {
    turno: string
    data: MonitorRecravacaoFlat[]
    order: Record<number, number>
}

const RecravacaoTable = ({ turno, data, order }: RecravacaoTableProps) => {
    const [pinningState, setPinningState] = useState<ColumnPinningState>({
        left: ['ensaio'],
    })

    const tableData = useMemo(() => {
        const ensaios: Record<
            number,
            {
                cod_ensaio: number
                ensaio: string
                data: Record<string, MonitorRecravacaoFlat>
            }
        > = {}

        for (const row of data) {
            const id = `${row.turno}${row.abreviatura_rolete}${row.cod_amostra}`

            if (!ensaios[row.ensaio]) {
                ensaios[row.ensaio] = {
                    cod_ensaio: row.cod_ensaio,
                    ensaio: row.ensaio,
                    data: {},
                }
            }

            if (!ensaios[row.ensaio].data[id]) {
                ensaios[row.ensaio].data = {
                    ...ensaios[row.ensaio].data,
                    [id]: row,
                }
            }
        }

        return sort(Object.values(ensaios), 'cod_ensaio', order).map(
            (ensaio) => ({
                ...ensaio,
                data: Object.values(ensaio.data),
            })
        )
    }, [data])

    const getRolete = (rolete: string) => {
        const firstLetter = rolete.slice(0, 1)
        const lastLetter = rolete.slice(-2)

        return `${firstLetter}${lastLetter}`
    }

    const tableColumnHeaders = useMemo(() => {
        const headers: Record<string, string> = {}

        for (const row of data) {
            headers[getRolete(row.abreviatura_rolete)] = getRolete(
                row.abreviatura_rolete
            )
        }

        return Object.values(headers).sort((a, b) => a.localeCompare(b))
    }, [data])

    type RecravacaoTableData = TableData<(typeof tableData)[0]>

    const columnHelper = createColumnHelper<RecravacaoTableData>()

    const columns = useMemo(
        () => [
            columnHelper.accessor('ensaio', {
                id: 'ensaio',
                header: 'Ensaio',
                size: 220,
                enableHiding: false,
                meta: {
                    cell: { className: 'p-3' },
                },
            }),
            columnHelper.group({
                id: 'data',
                header: () => {
                    return <div className="text-primary">Turno {turno}</div>
                },
                columns: tableColumnHeaders.map((header) => {
                    return columnHelper.display({
                        id: `${turno}.${header}`,
                        header: header,
                        size: 100,
                        enableHiding: false,
                        meta: {
                            cell: {
                                className: 'p-0',
                            },
                        },
                        cell: ({ row }) => {
                            const { data, ensaio } = row.original

                            const resultados = data.filter((value) => {
                                return (
                                    value.ensaio === ensaio &&
                                    getRolete(value.abreviatura_rolete) ===
                                        header
                                )
                            })

                            return resultados.map((result) => (
                                <CellPopover
                                    key={result.cod_amostra}
                                    data={result}
                                />
                            ))
                        },
                    })
                }),
            }),
        ],
        [tableColumnHeaders]
    )

    return (
        <Table<RecravacaoTableData>
            data={tableData}
            columns={columns}
            tableState={{
                columnPinning: pinningState,
            }}
            onColumnPinningStateChange={setPinningState}
            className="h-auto overflow-visible"
        />
    )
}

export default RecravacaoTable
