import {
    useMonitorEstabilidadeBiologicaFiltrosQuery,
    useMonitorEstabilidadeBiologicaQuery,
} from '@/queries/useMonitorEstabilidadeBiologicaQuery'
import { format, isBefore } from 'date-fns'
import { useRef } from 'react'
import CompanySelect from '../../components/CompanySelect'
import { Maximize, Minimize, RefreshCcw } from 'lucide-react'
import TopMessage from '@/components/TopMessage'
import PageContainer from '@/components/PageContainer'
import EstabilidadeBiologicaTable from './components/EstabilidadeBiologicaTable'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
import { isBetween } from '@/utils/date'
import ButtonWithTooltip from '@/components/ButtonWithTooltip'
import { AnimatePresence } from 'framer-motion'
import { useEmpresaQuery } from '@/queries/useEmpresaQuery'

import { useFullscreen } from '@/hooks/useFullscreen'
import { toast } from 'sonner'

import DateFilter, { OnSearchProps } from '@/components/DateFilter'
import LabsSelect from './components/LabsSelect'

const stateSelector = (state: Store) => ({
    selectedCompany:
        state.monitorEstabilidadeBiologicaSlice.state.selectedCompany,
    selectedLab: state.monitorEstabilidadeBiologicaSlice.state.selectedLab,
    date: state.monitorEstabilidadeBiologicaSlice.state.date,
    setLab: state.monitorEstabilidadeBiologicaSlice.actions.setLab,
    setCompany: state.monitorEstabilidadeBiologicaSlice.actions.setCompany,
    setDateInterval:
        state.monitorEstabilidadeBiologicaSlice.actions.setDateInterval,
    resetDate: state.monitorEstabilidadeBiologicaSlice.actions.resetDate,
})

const MonitorEstabilidadeBiologica = () => {
    const mainContainerRef = useRef<HTMLDivElement | null>(null)

    const {
        selectedCompany,
        date: { initial, final },
        selectedLab,
        setDateInterval,
        setCompany,
        setLab,
    } = useBaseStore(stateSelector)

    const { data: empresas = [], isSuccess: isEmpresasSuccess } =
        useEmpresaQuery()

    const { data: filtros = [], isSuccess: isFiltrosSuccess } =
        useMonitorEstabilidadeBiologicaFiltrosQuery({
            cod_filial: selectedCompany!,
            enabled: !!selectedCompany,
        })

    const { data, isPending, isFetching, isError, dataUpdatedAt, refetch } =
        useMonitorEstabilidadeBiologicaQuery({
            data_inicial: Number(format(new Date(initial), 'yyyyMMdd')),
            data_final: Number(format(new Date(final), 'yyyyMMdd')),
            cod_laboratorio: selectedLab!,
            enabled:
                empresas.length > 0 &&
                isEmpresasSuccess &&
                !!selectedLab &&
                isFiltrosSuccess,
        })

    const { isFullscreen, onToggleFullScreen } = useFullscreen({
        element: mainContainerRef.current,
    })

    const onSearch = ({ from, to }: OnSearchProps) => {
        if (isBefore(to, from)) {
            return toast.error(
                'A data final não pode ser anterior à data inicial'
            )
        }

        if (!isBetween(new Date(to), new Date(from), 60)) {
            return toast.warning(
                'Intervalo de datas deve ser de no máximo 60 dias'
            )
        }

        setDateInterval({
            initial: from.toDateString(),
            final: to.toDateString(),
        })
    }

    return (
        <PageContainer ref={mainContainerRef} className="p-4">
            <div className="flex justify-between pb-2">
                <div className="flex items-center gap-4">
                    <CompanySelect
                        companies={empresas}
                        value={selectedCompany?.toString()}
                        onChange={(value) => setCompany(Number(value))}
                    />
                    <LabsSelect
                        labs={filtros}
                        value={selectedLab}
                        onChange={(value) => setLab(Number(value))}
                    />
                </div>
                <div className="flex items-center gap-2">
                    <DateFilter
                        final={final}
                        initial={initial}
                        onSearch={onSearch}
                    />
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={() => refetch()}
                        tooltipContent={'Atualizar dados'}
                    >
                        <RefreshCcw size={14} />
                    </ButtonWithTooltip>
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={onToggleFullScreen}
                        tooltipContent={
                            isFullscreen ? 'Minimizar' : 'Maximizar'
                        }
                    >
                        {isFullscreen ? (
                            <Minimize size={14} />
                        ) : (
                            <Maximize size={14} />
                        )}
                    </ButtonWithTooltip>
                </div>
            </div>
            <AnimatePresence>
                {!data && isPending ? (
                    <TopMessage variant="loading" text="Carregando dados..." />
                ) : null}
                {!isPending && isFetching ? (
                    <TopMessage variant="success" text="Atualizando dados..." />
                ) : null}
                {isError && (
                    <TopMessage variant="error" text="Erro ao buscar dados" />
                )}
            </AnimatePresence>
            <EstabilidadeBiologicaTable
                data={data}
                dataUpdatedAt={dataUpdatedAt}
            />
        </PageContainer>
    )
}

export default MonitorEstabilidadeBiologica
