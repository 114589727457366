import { MonitorFermentacao } from '@/api/business/fermentacao/type'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { TypographyXS } from '@/components/ui/typography'

type ProdutosSelectProps = {
    data?: MonitorFermentacao[]
    selectedId?: string
    onChange: (value: string) => void
}

const ProdutosSelect = ({
    data,
    selectedId,
    onChange,
}: ProdutosSelectProps) => {
    return (
        <Select value={selectedId} onValueChange={onChange}>
            <SelectTrigger className="hover:bg-muted h-[56px] min-w-[200px]">
                <SelectValue
                    className="text-start"
                    placeholder="Selecione um produto"
                />
            </SelectTrigger>
            <SelectContent>
                {data?.length === 0 ? (
                    <SelectGroup>
                        <SelectItem value="sem_dados" disabled>
                            <TypographyXS className="text-start">
                                Não há produto disponível
                            </TypographyXS>
                        </SelectItem>
                    </SelectGroup>
                ) : (
                    <SelectGroup>
                        {data?.map((value) => {
                            return (
                                <SelectItem
                                    key={value.produto}
                                    value={value.produto}
                                >
                                    <TypographyXS className="text-start">
                                        {value.produto}
                                    </TypographyXS>
                                </SelectItem>
                            )
                        })}
                    </SelectGroup>
                )}
            </SelectContent>
        </Select>
    )
}

export default ProdutosSelect
