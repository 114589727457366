import { useMonitorFermentacaoDiarioQuery } from '@/queries/useMonitorFermentacaoQuery'
import { format } from 'date-fns'
import { useRef } from 'react'
import { TypographyMutedXs, TypographyP } from '@/components/ui/typography'
import CompanySelect from '../../components/CompanySelect'
import { Maximize, Minimize, RefreshCcw } from 'lucide-react'
import TopMessage from '@/components/TopMessage'
import PageContainer from '@/components/PageContainer'
import FermentacaoTable from './components/FermentacaoTable'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
import ButtonWithTooltip from '@/components/ButtonWithTooltip'
import { AnimatePresence } from 'framer-motion'
import { useEmpresaQuery } from '@/queries/useEmpresaQuery'
import { useFullscreen } from '@/hooks/useFullscreen'
import DateFilter, { OnSearchProps } from '@/components/DateFilter'

const stateSelector = (state: Store) => ({
    selectedCompany: state.monitorFermentacaoDiarioSlice.state.selectedCompany,
    date: state.monitorFermentacaoDiarioSlice.state.date,
    selectedSala: state.monitorFermentacaoDiarioSlice.state.selectedSala,
    selectedLote: state.monitorFermentacaoDiarioSlice.state.selectedLote,
    selectedProduto: state.monitorFermentacaoDiarioSlice.state.selectedProduto,
    setLote: state.monitorFermentacaoDiarioSlice.actions.setLote,
    setSala: state.monitorFermentacaoDiarioSlice.actions.setSala,
    setProduto: state.monitorFermentacaoDiarioSlice.actions.setProduto,
    setCompany: state.monitorFermentacaoDiarioSlice.actions.setCompany,
    setDateInterval:
        state.monitorFermentacaoDiarioSlice.actions.setDateInterval,
    resetDate: state.monitorFermentacaoDiarioSlice.actions.resetDate,
})

const MonitorFermentacaoDiario = () => {
    const mainContainerRef = useRef<HTMLDivElement | null>(null)

    const {
        selectedCompany,
        date: { initial },
        setDateInterval,
        setCompany,
    } = useBaseStore(stateSelector)
    const { data: empresas = [], isSuccess: isEmpresasSuccess } =
        useEmpresaQuery()

    const {
        data = [],
        isPending,
        isFetching,
        isError,
        dataUpdatedAt,
        refetch,
    } = useMonitorFermentacaoDiarioQuery({
        cod_filial: selectedCompany!,
        data_inicial: Number(format(new Date(initial), 'yyyyMMdd')),
        enabled: isEmpresasSuccess,
    })

    const { isFullscreen, onToggleFullScreen } = useFullscreen({
        element: mainContainerRef.current,
    })

    const onSearch = ({ from }: OnSearchProps) => {
        setDateInterval({
            initial: from.toDateString(),
        })
    }

    return (
        <PageContainer
            ref={mainContainerRef}
            className="flex flex-col p-4 overflow-auto"
        >
            <div className="flex justify-between py-4">
                <div className="flex items-center gap-2">
                    <CompanySelect
                        companies={empresas}
                        value={selectedCompany?.toString()}
                        onChange={(value) => setCompany(Number(value))}
                    />
                </div>
                <div className="flex items-center gap-2">
                    <DateFilter initial={initial} onSearch={onSearch} />
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={() => refetch()}
                        tooltipContent={'Atualizar dados'}
                    >
                        <RefreshCcw size={14} />
                    </ButtonWithTooltip>
                    <ButtonWithTooltip
                        variant="ghost"
                        size="icon"
                        onClick={onToggleFullScreen}
                        tooltipContent={
                            isFullscreen ? 'Minimizar' : 'Maximizar'
                        }
                    >
                        {isFullscreen ? (
                            <Minimize size={14} />
                        ) : (
                            <Maximize size={14} />
                        )}
                    </ButtonWithTooltip>
                </div>
            </div>
            <div className="ml-auto">
                {dataUpdatedAt !== 0 && (
                    <TypographyMutedXs>
                        Última atualização:{' '}
                        {format(
                            new Date(dataUpdatedAt),
                            'dd/MM/yyyy, HH:mm:ss'
                        )}
                    </TypographyMutedXs>
                )}
            </div>
            <div className="flex-1 py-2">
                <AnimatePresence>
                    {isPending ? (
                        <TopMessage
                            variant="loading"
                            text="Carregando dados..."
                        />
                    ) : null}
                    {!isPending && isFetching ? (
                        <TopMessage
                            variant="success"
                            text="Atualizando dados..."
                        />
                    ) : null}
                    {isError && (
                        <TopMessage
                            variant="error"
                            text="Erro ao buscar dados"
                        />
                    )}
                </AnimatePresence>
                <div className="space-y-4">
                    {data.map((value) => {
                        return (
                            <div key={value.turno} className="space-y-4">
                                <div>
                                    <TypographyP className="font-semibold text-primary text-md">
                                        {value.turno}
                                    </TypographyP>
                                </div>
                                <FermentacaoTable data={value.amostras} />
                            </div>
                        )
                    })}
                </div>
            </div>
        </PageContainer>
    )
}

export default MonitorFermentacaoDiario
