import { ImmerStateCreator } from '../type'
import {
    MonitorLubrificanteDeEsteiraSlice,
    MonitorLubrificanteDeEsteiraState,
} from './type'
import { getStorageState, persistState } from '@/utils/storage'

const initialState: MonitorLubrificanteDeEsteiraState = {
    selectedCompany: undefined,
    selectedSala: { bem: undefined, sala: undefined },
    date: {
        initial: new Date().toISOString(),
        final: new Date().toISOString(),
    },
}

const key = 'monitor-lubrificante-de-esteira'

const persist = (state: MonitorLubrificanteDeEsteiraState) => {
    persistState({ key, state })
}

export const useMonitorLubrificanteDeEsteiraSlice: ImmerStateCreator<
    MonitorLubrificanteDeEsteiraSlice
> = (set) => ({
    state: getStorageState({
        key,
        state: initialState,
    }),
    actions: {
        setCompany: (company) => {
            set((state) => {
                state.monitorLubrificanteDeEsteiraSlice.state.selectedCompany =
                    company

                persist(state.monitorLubrificanteDeEsteiraSlice.state)
            })
        },
        setDateInterval: ({ final, initial }) => {
            set((state) => {
                state.monitorLubrificanteDeEsteiraSlice.state.date.initial =
                    initial
                state.monitorLubrificanteDeEsteiraSlice.state.date.final = final

                persist(state.monitorLubrificanteDeEsteiraSlice.state)
            })
        },
        setSala: (sala) => {
            set((state) => {
                state.monitorLubrificanteDeEsteiraSlice.state.selectedSala =
                    sala

                persist(state.monitorLubrificanteDeEsteiraSlice.state)
            })
        },
        resetDate: () => {
            set((state) => {
                state.monitorLubrificanteDeEsteiraSlice.state.date = {
                    final: new Date().toString(),
                    initial: new Date().toString(),
                }

                persist(state.monitorLubrificanteDeEsteiraSlice.state)
            })
        },
        onReset: () => {
            set((state) => {
                state.monitorLubrificanteDeEsteiraSlice.state = initialState
            })
        },
    },
})
