import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { TypographyXS } from '@/components/ui/typography'

type ProdutosSelectProps = {
    produtos: { produto: string }[]
    value?: string
    onChange: (value: string) => void
}

const ProdutosSelect = ({ produtos, value, onChange }: ProdutosSelectProps) => {
    return (
        <Select onValueChange={onChange} value={value}>
            <SelectTrigger className="hover:bg-muted h-[56px] min-w-[200px]">
                <SelectValue placeholder="Selecione um produto" />
            </SelectTrigger>
            <SelectContent>
                {produtos?.length === 0 ? (
                    <SelectGroup>
                        <SelectItem value="sem_dados" disabled>
                            <TypographyXS className="text-start">
                                Não há produto disponível
                            </TypographyXS>
                        </SelectItem>
                    </SelectGroup>
                ) : (
                    <SelectGroup>
                        {produtos.map((produto) => {
                            return (
                                <SelectItem
                                    key={produto.produto}
                                    value={produto.produto}
                                >
                                    {produto.produto}
                                </SelectItem>
                            )
                        })}
                    </SelectGroup>
                )}
            </SelectContent>
        </Select>
    )
}

export default ProdutosSelect
