import { createWithEqualityFn } from 'zustand/traditional'
import { shallow } from 'zustand/shallow'
import { useAuthSlice } from './authSlice'
import { useResetSlice } from './resetSlice'
import { useAppConfigSlice } from './appConfigSlice'
import { immer } from 'zustand/middleware/immer'
import { Store } from './type'
import { useMonitorBrassagemSlice } from './monitorBrassagemSlice'
import { useMonitorProdutosAcabadosSlice } from './monitorProdutosAcabadosSlice'
import { useMonitorFiltracaoSlice } from './monitorFiltracaoSlice'
import { useMonitorProvaHorariaSlice } from './monitorProvaHorariaSlice'
import { useMonitorRastreabilidadeSlice } from './monitorRastreabilidadeSlice'
import { useMonitorRecravacaoSlice } from './monitorRecracavaoSlice'
import { useMonitorPasteurizacaoSlice } from './monitorPasteurizacaoSlice'
import { useMonitorArrolhamentoSlice } from './monitorArrolhamentoSlice'
import { useMonitorLubrificanteDeEsteiraSlice } from './monitorLubrificanteDeEsteiraSlice'
import { useMonitorMaturacaoSlice } from './monitorMaturacaoSlice'
import { useMonitorFermentacaoSlice } from './monitorFermentacaoSlice'
import { useMonitorFermentacaoDiarioSlice } from './monitorFermentacaoDiarioSlice'
import { useMonitorFermentacaoPropagacaoSlice } from './monitorFermentacaoPropagacaoSlice'
import { useMonitorFermentacaoPropagacaoDiarioSlice } from './monitorFermentacaoPropagacaoDiarioSlice'
import { useMonitorEstabilidadeBiologicaSlice } from './monitorEstabilidadeBiologicaSlice'
import { useMonitorCipEnvasamentoSlice } from './monitorCipEnvasamento'

export const useBaseStore = createWithEqualityFn<Store>()(
    immer((...a) => ({
        authSlice: useAuthSlice(...a),
        resetSlice: useResetSlice(...a),
        appConfigSlice: useAppConfigSlice(...a),
        monitorBrassagemSlice: useMonitorBrassagemSlice(...a),
        monitorProdutosAcabadosSlice: useMonitorProdutosAcabadosSlice(...a),
        monitorFiltracaoSlice: useMonitorFiltracaoSlice(...a),
        monitorProvaHorariaSlice: useMonitorProvaHorariaSlice(...a),
        monitorRastreabilidadeSlice: useMonitorRastreabilidadeSlice(...a),
        monitorRecravacaoSlice: useMonitorRecravacaoSlice(...a),
        monitorPasteurizacaoSlice: useMonitorPasteurizacaoSlice(...a),
        monitorArrolhamentoSlice: useMonitorArrolhamentoSlice(...a),
        monitorLubrificanteDeEsteiraSlice: useMonitorLubrificanteDeEsteiraSlice(
            ...a
        ),
        monitorMaturacaoSlice: useMonitorMaturacaoSlice(...a),
        monitorFermentacaoSlice: useMonitorFermentacaoSlice(...a),
        monitorFermentacaoDiarioSlice: useMonitorFermentacaoDiarioSlice(...a),
        monitorFermentacaoPropagacaoSlice: useMonitorFermentacaoPropagacaoSlice(
            ...a
        ),
        monitorFermentacaoPropagacaoDiarioSlice:
            useMonitorFermentacaoPropagacaoDiarioSlice(...a),
        monitorEstabilidadeBiologicaSlice: useMonitorEstabilidadeBiologicaSlice(
            ...a
        ),
        monitorCipEnvasamentoSlice: useMonitorCipEnvasamentoSlice(...a),
    })),
    shallow
)
