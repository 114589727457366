import instance from '..'
import {
    FetchMonitorEstabilidadeBiologicaFiltrosProps,
    FetchMonitorEstabilidadeBiologicaProps,
    MonitorEstabilidadeBiologica,
    MonitorEstabilidadeBiologicaFiltros,
} from './type'

export const fetchMonitorEstabilidadeBiologica = async (
    props: FetchMonitorEstabilidadeBiologicaProps
) => {
    const response = await instance.post<MonitorEstabilidadeBiologica[]>(
        '/monitor/microbiologia/estabilidade/liberacao',
        {
            ...props,
        }
    )

    return response.data
}

export const fetchMonitorEstabilidadeBiologicaFiltros = async (
    props: FetchMonitorEstabilidadeBiologicaFiltrosProps
) => {
    const response = await instance.post<MonitorEstabilidadeBiologicaFiltros[]>(
        '/monitor/recursos/microbiologia/estabilidade/liberacao',
        {
            ...props,
        }
    )

    return response.data
}
