import { ImmerStateCreator } from '../type'
import {
    MonitorFermentacaoPropagacaoSlice,
    MonitorFermentacaoPropagacaoState,
} from './type'
import { getStorageState, persistState } from '@/utils/storage'

const initialState: MonitorFermentacaoPropagacaoState = {
    selectedCompany: undefined,
    selectedLote: undefined,
    selectedProduto: undefined,
    selectedSala: undefined,
    date: {
        initial: new Date().toISOString(),
        final: new Date().toISOString(),
    },
}

const key = 'monitor-fermentacao-propagacao'

const persist = (state: MonitorFermentacaoPropagacaoState) => {
    persistState({
        key,
        state,
        omit: ['selectedLote'],
    })
}

export const useMonitorFermentacaoPropagacaoSlice: ImmerStateCreator<
    MonitorFermentacaoPropagacaoSlice
> = (set) => ({
    state: getStorageState({
        key,
        state: initialState,
    }),
    actions: {
        setCompany: (company) => {
            set((state) => {
                state.monitorFermentacaoPropagacaoSlice.state.selectedCompany =
                    company

                persist(state.monitorFermentacaoPropagacaoSlice.state)
            })
        },
        setDateInterval: ({ final, initial }) => {
            set((state) => {
                state.monitorFermentacaoPropagacaoSlice.state.date.initial =
                    initial
                state.monitorFermentacaoPropagacaoSlice.state.date.final = final

                persist(state.monitorFermentacaoPropagacaoSlice.state)
            })
        },
        setSala: (sala) => {
            set((state) => {
                state.monitorFermentacaoPropagacaoSlice.state.selectedSala =
                    sala

                persist(state.monitorFermentacaoPropagacaoSlice.state)
            })
        },
        setLote: (lote) => {
            set((state) => {
                state.monitorFermentacaoPropagacaoSlice.state.selectedLote =
                    lote
            })
        },
        setProduto: (produto) => {
            set((state) => {
                state.monitorFermentacaoPropagacaoSlice.state.selectedProduto =
                    produto
            })
        },
        resetDate: () => {
            set((state) => {
                state.monitorFermentacaoPropagacaoSlice.state.date = {
                    final: new Date().toString(),
                    initial: new Date().toString(),
                }

                persist(state.monitorFermentacaoPropagacaoSlice.state)
            })
        },
        onReset: () => {
            set((state) => {
                state.monitorFermentacaoPropagacaoSlice.state = initialState
            })
        },
    },
})
