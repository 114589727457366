export const setItem = (storage: Storage, key: string, value: any) =>
    storage.setItem(key, value)

export const getItem = (Storage: Storage, key: string) => Storage.getItem(key)

export type GetStorageStateProps<T> = {
    storage?: Storage
    state: T
    key: string
}

export type PersistStateProps<T> = {
    storage?: Storage
    state: T
    key: string
    omit?: (keyof T)[]
}

export const persistState = <T>({
    storage = localStorage,
    state,
    key,
    omit,
}: PersistStateProps<T>) => {
    const toPersist: any = {}

    for (const key in state) {
        if (omit?.includes(key)) {
            continue
        }

        toPersist[key] = state[key]
    }

    storage.setItem(key, JSON.stringify(toPersist))
}

export const getStorageState = <T extends object>({
    storage = localStorage,
    state,
    key,
}: GetStorageStateProps<T>): T => {
    const mergeState = (statePart: any, jsonPart: any): any => {
        const result = { ...statePart }

        for (const k of Object.keys(statePart)) {
            if (
                typeof statePart[k] === 'object' &&
                statePart[k] !== null &&
                !Array.isArray(statePart[k])
            ) {
                result[k] = mergeState(
                    statePart[k],
                    jsonPart?.[k] ?? statePart[k]
                )
            } else {
                result[k] = k in jsonPart ? jsonPart[k] : statePart[k]
            }
        }

        for (const k of Object.keys(jsonPart || {})) {
            if (!(k in result)) {
                result[k] = jsonPart[k]
            }
        }

        return result
    }

    try {
        const data = storage.getItem(key)

        if (data) {
            const json = JSON.parse(data)
            return mergeState(state, json) as T
        }

        return state
    } catch (error) {
        console.log(error)
        return state
    }
}
