import { ImmerStateCreator } from '../type'
import {
    MonitorProdutosAcabadosSlice,
    MonitorProdutosAcabadosState,
} from './type'
import { getStorageState, persistState } from '@/utils/storage'

const initialState: MonitorProdutosAcabadosState = {
    selectedCompany: undefined,
    selectedSala: undefined,
    date: {
        initial: new Date().toISOString(),
        final: new Date().toISOString(),
    },
    selectedLinha: undefined,
    selectedProduto: undefined,
}

const key = 'monitor-produtos-acabados'

const persist = (state: MonitorProdutosAcabadosState) => {
    persistState({ key, state })
}

export const useMonitorProdutosAcabadosSlice: ImmerStateCreator<
    MonitorProdutosAcabadosSlice
> = (set) => ({
    state: getStorageState({
        key,
        state: initialState,
    }),
    actions: {
        setCompany: (company) => {
            set((state) => {
                state.monitorProdutosAcabadosSlice.state.selectedCompany =
                    company

                persist(state.monitorProdutosAcabadosSlice.state)
            })
        },
        setDateInterval: ({ final, initial }) => {
            set((state) => {
                state.monitorProdutosAcabadosSlice.state.date.initial = initial
                state.monitorProdutosAcabadosSlice.state.date.final = final

                persist(state.monitorProdutosAcabadosSlice.state)
            })
        },
        setSala: (sala) => {
            set((state) => {
                state.monitorProdutosAcabadosSlice.state.selectedSala = sala

                persist(state.monitorProdutosAcabadosSlice.state)
            })
        },
        setLinha: (linha) => {
            set((state) => {
                state.monitorProdutosAcabadosSlice.state.selectedLinha = linha

                persist(state.monitorProdutosAcabadosSlice.state)
            })
        },
        setProduto: (produto) => {
            set((state) => {
                state.monitorProdutosAcabadosSlice.state.selectedProduto =
                    produto

                persist(state.monitorProdutosAcabadosSlice.state)
            })
        },
        resetDate: () => {
            set((state) => {
                state.monitorProdutosAcabadosSlice.state.date = {
                    final: new Date().toString(),
                    initial: new Date().toString(),
                }

                persist(state.monitorProdutosAcabadosSlice.state)
            })
        },
        onReset: () => {
            set((state) => {
                state.monitorProdutosAcabadosSlice.state = initialState
            })
        },
    },
})
