import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { ColumnPinningState, createColumnHelper } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import {
    TypographyMutedXs,
    TypographyP,
    TypographyXS,
} from '@/components/ui/typography'
import { MonitorPasteurizacao } from '@/api/business/pasteurizacao/type'
import { getDate } from '@/utils/date'
import CellPopover from './components/CellPopover'
import { format, isValid } from 'date-fns'

type PasteurizacaoTableProps = {
    data?: MonitorPasteurizacao[]
}

const PasteurizacaoTable = ({ data = [] }: PasteurizacaoTableProps) => {
    const [pinningState, setPinningState] = useState<ColumnPinningState>({
        left: ['ensaio'],
    })

    const tableData = useMemo(() => {
        const uniqueValues = [
            ...new Set(
                data.flatMap((value) => {
                    const date = getDate({
                        date: value.data_da_coleta,
                        hour: value.hora_da_coleta,
                    }).toISOString()

                    return date
                })
            ),
        ]

        const newData = uniqueValues.reduce((acc, curr) => {
            if (!acc[curr]) {
                acc[curr] = data.filter(
                    (value) =>
                        getDate({
                            date: value.data_da_coleta,
                            hour: value.hora_da_coleta,
                        }).toISOString() === curr
                )
            }

            return acc
        }, {} as Record<string, MonitorPasteurizacao[]>)

        return newData
    }, [data])

    const tableColumnHeaders = useMemo(() => {
        const headers = [
            ...new Set(
                data.flatMap((value) =>
                    value.resultados.map((result) => result.ensaio)
                )
            ),
        ].map((header) => {
            const subHeaders = [
                ...new Set(
                    data
                        .filter((value) =>
                            value.resultados.some(
                                (result) => result.ensaio === header
                            )
                        )
                        .map((result) => result.abreviatura_tanque)
                ),
            ]

            return {
                header,
                subHeaders: subHeaders.sort((a, b) => a.localeCompare(b)),
            }
        })

        return headers
    }, [data])

    type PasteurizacaoTableData = TableData<string>

    const columnHelper = createColumnHelper<PasteurizacaoTableData>()

    const columns = useMemo(
        () => [
            columnHelper.display({
                id: 'ensaio',
                cell: ({ row }) => {
                    const date = new Date(row.original)

                    if (!isValid(date)) return null

                    const dateString = format(date, 'dd/MM/yyyy')
                    const hourString = format(date, 'HH:mm:ss')

                    return (
                        <div>
                            <TypographyMutedXs className="text-xs">
                                {dateString}
                            </TypographyMutedXs>
                            <TypographyP className="text-xs">
                                {hourString}
                            </TypographyP>
                        </div>
                    )
                },
                size: 200,
                enableHiding: false,
            }),
            ...tableColumnHeaders.map((header) => {
                return columnHelper.group({
                    id: header.header,
                    header: () => {
                        return (
                            <div className="flex flex-col gap-2 overflow-hidden">
                                <TypographyXS className="overflow-hidden text-ellipsis">
                                    {header.header}
                                </TypographyXS>
                            </div>
                        )
                    },
                    columns: [
                        ...header.subHeaders.map((subHeader) =>
                            columnHelper.display({
                                id: `${header.header}-${subHeader}`,
                                header: subHeader,
                                cell: ({ row }) => {
                                    const date = row.original

                                    const data = tableData[date]

                                    const ensaioFound = data.find(
                                        (value) =>
                                            value.abreviatura_tanque ===
                                            subHeader
                                    )

                                    if (!ensaioFound) return null

                                    return ensaioFound.resultados
                                        .filter(
                                            (value) =>
                                                value.ensaio === header.header
                                        )
                                        .map((value) => (
                                            <CellPopover data={value} />
                                        ))
                                },
                                size: header.header.includes('CONCENTRAÇÃO')
                                    ? 240
                                    : 120,
                                meta: {
                                    header: {
                                        className: 'p-2',
                                    },
                                    cell: {
                                        className: 'p-0',
                                    },
                                },
                                enableHiding: false,
                            })
                        ),
                    ],
                })
            }),
        ],
        [tableColumnHeaders]
    )

    return (
        <Table<PasteurizacaoTableData>
            data={Object.keys(tableData)}
            columns={columns}
            tableState={{
                columnPinning: pinningState,
            }}
            onColumnPinningStateChange={setPinningState}
            rowStimateSize={48}
        />
    )
}

export default PasteurizacaoTable
